import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fr: {
    translation: {
      common: {
        bool: {
          true: "Oui",
          false: "Non",
        },
        undeterminable: "Examen impossible",
        separatorHelper: {
          comma: "Séparer les valeurs par une virgule",
        },
      },
      visit: {
        meet: {
          role: {
            breeder: "Éleveur à indemniser",
            representative: "Mandataire",
          },
        },
      },
      report: {
        alerts: {
          nbDaysToNotify:
            "Attention, le délai réglementaire de 72 heures pour le signalement est dépassé.",
          nbAnimalsToNotify:
            "Attention, le nombre d'animaux constatés est différent du nombre d'animaux déclarés",
        },
        status: {
          draft: "En attente",
          ready: "Constat reçu",
          current: "En cours d'instruction",
          send_to_asp: "Envoyé ASP",
          in_progress_asp: "Traitement comptable ASP",
          paid: "Payé",
          refused: "Refus indemnisation",
          additional_compensation: "Indemnisation complémentaire",
          additional_payment: "Paiement complémentaire",
        },
        damagePrecision: {
          unknown: "Date inconnue",
          presumed: "Présumée",
          sure: "Certaine",
        },
        damagePrecisionFull: {
          unknown: "Date inconnue",
          presumed: "Date présumée",
          sure: "Date certaine",
        },
        damageMoment: {
          day: "Jour",
          night: "Nuit",
          unknown: "Inconnu",
        },
        scavengers: {
          fox: "Renard",
          badger: "Blaireau",
          wild_boar: "Sanglier",
          corvidae: "Corvidé",
          vulture: "Vautour",
          other: "Autre",
        },
        predatorPresenceSigns: {
          visual: "Observation visuelle",
          hairs: "Poils (à récolter)",
          excrement: "Excrément (à récolter)",
          footPrint: "Empreintes - piste",
          other: "Autre",
        },
        dismissedPredators: {
          wolf: "Loup écarté",
          bear: "Ours écarté",
          lynx: "Lynx écarté",
        },
        decision: {
          null: " ",
          waiting: "En attente",
          refuse: "Refus",
          compensation: "Indemnisation",
        },
        reportDecision: {
          additionalAmountReason: {
            animal_disappear: "Animaux disparus",
            amount_scale_change: "Changement de barème",
            late_recourse: "Recours tardif",
          },
          additionalAmountTreasuryDecision: {
            refuse: "Refus",
            compensation: "Indemnisation",
          },
        },
        freshnessLabel: "État de fraîcheur des animaux",
        freshness: {
          no_maggot:
            "8. Peau souple, chairs rouge vif, sang coagulé, absence d'asticots.",
          maggot_lt_2mm: "9. Peau souple, chairs bordeaux, asticots < 2mm.",
          maggot_gt_2mm:
            "10. Peau fine voire cartonnée, chairs brunes, asticots > 2mm.",
          undeterminable: "11. Impossibilité d'évaluer l'état de fraîcheur.",
        },
        environmentAccess: {
          open: "Ouvert",
          semi_open: "Semi-ouvert",
          closed: "Fermé",
        },
        scareWays: {
          olfactory: "Olfactif",
          sound: "Sonore",
          non_lethal_shot: "Tir non létal",
          visual: "Visuel",
        },
        animalsLead: {
          outside_enclosure: "Hors parc ou filet",
          mobile_net: "En filet mobile",
          permanent_enclosure: "En parc clos permanent",
        },
      },
      declaration: {
        damageMoment: {
          day: "Jour",
          night: "Nuit",
          unknown: "Inconnu",
        },
        animalsLead: {
          move: "Déplacement",
          sleep: "Couchade",
        },
        enclosureStructure: {
          fixed: "Fixe",
          mobile: "Mobile",
        },
        enclosureType: {
          fully_electrified: "Intégralement électrifié",
          not_electrified: "Non électrifié",
        },
        humanPresenceNear: {
          breeder: "Eleveur",
          shepherd: "Berger",
          help_shepherd: "Aide-berger",
          none: "Aucune",
        },
        humanPresenceRate: {
          day: "Jour",
          night: "Nuit",
          daily: "Présence quotidienne",
          irregular: "Présence irrégulière",
        },
        weather: {
          sun: "Beau temps",
          cloud: "Couvert",
          fog: "Brouillard",
          rain: "Pluie",
          snow: "Neige",
        },
        environmentType: {
          pasture: "Prairie",
          heath: "Lande",
          wood: "Fôret",
          bocage: "Bocage",
        },
      },
      beneficiary: {
        herd: {
          ovine: "Ovin",
          caprine: "Caprin",
          bovine: "Bovin",
          equine: "Équin",
          other: "Autre",
          total: "Total",
        },
        materialDamages: {
          enclosure: "Parc",
          fence: "Clôture",
          other: "Autre",
        },
      },
      victim: {
        health: {
          dead: "Mort",
          euthanized: "Euthanasié",
          hurt: "Blessé",
          will_dead: "Pronostic vital engagé",
        },
        cause: {
          direct: "Victime directe",
          fall: "Dérochement",
          suffocated: "Étouffée",
          other: "Autre",
        },
        approxWeight: {
          lt10: "12. inf. à 10Kg",
          "10to90": "13. 10 à 90Kg",
          gt90: "14. sup. à 90Kg",
        },
        consummedParts: {
          none: "19. Aucune",
          all_flesh: "20. Consommation totale des chairs",
          neck: "21. Cou ou nuque",
          forelimb: "22. Membre(s) antérieur(s)",
          hind_limb: "23. Membres postérieurs",
          viscus_red: "24. Vicères rouges",
          viscus_white: "25. Vicères blancs",
          teat: "26. Pis",
          chest_flesh: "27. Chairs du poitrail",
          back: "28. Dos",
          side: "29. Flanc",
          around_orifice: "30. Tissus mous en périphérie des orifices naturels",
        },
        consumptionWays: {
          covered_by_scratching:
            "31. Proie recouverte par grattage (herbe, humus, feuillage, neige…)",
          long_broken_bone: "32. Os longs des membres brisés nets",
          open_chest_missing_sternum:
            "33. Cage thoracique ouverte ET sternum absent",
          scraggy_bone_anatomic_connection:
            "34. Os des membres décharnés ET en connexion anatomique",
          open_abdomen_intact_rumen_or_content_nearby:
            "35. Ouverture de la cavité abdominale ET panse intacte OU contenu de la panse à proximité de la carcasse",
        },
        perforation: {
          none: "36. Absence de perforation",
          bleeding:
            "37. Hémorragie ou hématome associé à au moins une perforation",
          no_bleeding:
            "38. Absence d'hémorragie ou d'hématome associé sur l'ensemble des perforations",
          undeterminable: "39. Examen impossible",
        },
        perforationNb: {
          eq1: "40. 1",
          "2to6": "41. 2 à 6",
          gt6: "42. sup. à 6",
          undeterminable: "43. Examen impossible",
        },
        perforationDepth: {
          lt10mm: "44. inf. à 10mm",
          gt10mm: "45. sup. à 10mm",
          undeterminable: "46. Examen impossible",
        },
        perforationDiameter: {
          lt3mm: "47. inf. à 3mm",
          gt3mm: "48. sup. à 3mm",
          undeterminable: "49. Examen impossible",
        },
        trachaeImpact: {
          none: "50. Aucun",
          pierced_crushed_cut: "51. Perforée, broyée, sectionnée",
          undeterminable: "52. Examen impossible",
        },
        laceration: {
          bleeding: "53. Avec hématome ou hémorragie associé",
          no_bleeding: "54. Sans hématome ou hémorragie associé",
        },
        noPiercedZoneVigilance: {
          bleeding: "55. Hématome ou hémorragie autour des chairs consommées",
          shining_red_span:
            "56. Présence de plage(s) rouge(s), luisante(s) et gélatineuse(s), sans imprégnation du tissu sous-cutané ET sans lésion externe associée",
          bruise_no_external_lesion:
            "57. Présence d'hématomes avec imprégnation du tissu sous-cutané ET sans lésion externe associée",
        },
      },
      validation: {
        common: {
          blank: "Cette valeur doit être renseignée.",
          tooLow: "Cette valeur doit être supérieure ou égale à {{value}}.",
          exists: "Cette valeur existe déjà.",
          positive: "Cette valeur doit-être supérieur à zéro.",
          passwordStrong:
            "Saisir au moins 6 caractères dont une minuscule, une majuscule et un chiffre.",
          passwordConfirm: "Les mots de passe doivent être identiques.",
        },
      },
      role: {
        ROLE_USER: "utilisateur",
        ROLE_ADMIN: "administrateur",
        admin: "admin",
        mobile: "mobile",
        expert: "expert",
      },
      import: {
        error: {
          nbcols: "La ligne doit contenir 5 colonnes",
          ref_format: "Format référence dossier",
          ref_unknown: "Référence dossier inconnue",
          rank_outside: "Numéro d'ordre supérieur aux nombre de bénéficiaires",
          order_not_number: "Numéro d'ordre doit être un nombre",
          amount_not_number: "Montant doit être un nombre",
          payment_date_format: "Format date de paiement",
        },
      },
      export: {
        regions: {
          inconnue: "Inconnue",
          iledefrance: "Ile-de-France",
          hautsdefrance: "Hauts-de-France",
          grandest: "Grand Est",
          occitanie: "Occitanie",
          normandie: "Normandie",
          paysdelaloire: "Pays de la Loire",
          auvergnerhonealpes: "Auvergne-Rhône-Alpes",
          bretagne: "Bretagne",
          provencealpescotedazur: "Provence-Alpes-Côte D'Azur",
          corse: "Corse",
          bourgognefranchecomte: "Bourgogne-Franche-Comté",
          nouvelleaquitaine: "Nouvelle-Aquitaine",
          centrevaldeloire: "Centre-Val de Loire",
        },
      },
      department: {
        metaData: {
          prefect: {
            gender: {
              male: "Homme",
              female: "Femme",
            },
            label_male: "PRÉFET {{departmentArticle}} {{departmentLabel}}",
            label_female: "PRÉFÈTE {{departmentArticle}} {{departmentLabel}}",
            delegation_male:
              "Par délégation du Préfet du département {{departmentLabel}}",
            delegation_female:
              "Par délégation de la Préfète du département {{departmentLabel}}",
            for_male: "Pour le Préfet du département {{departmentLabel}}",
            for_female: "Pour la Préfète du département {{departmentLabel}}",
          },
        },
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
