import axios, { AxiosInstance, AxiosResponse } from "axios";
import getConfig from "../config";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { useEffect } from "react";
import {
  IConstraintViolation,
  IConstraintViolationResponse,
  IReport,
} from "../interfaces";
import { useAppContext } from "../providers/AppContext";
import { useTranslation } from "react-i18next";
import qs from "qs";

export default function useApi() {
  const { tokenStorage, apiUrl, resApiUrl } = getConfig();
  const [accessToken] = useLocalStorage(tokenStorage);
  const { loader } = useAppContext();
  const { t } = useTranslation();
  const { errorDialog } = useAppContext();
  const { signInDialog } = useAppContext();

  //const allowedRoutes = ["/", "/signin", "/forgot", "/users/set_password"];

  useEffect(() => {
    if (
      !accessToken
      //&& !allowedRoutes.includes(router.route)
    ) {
      //localStorage.clear();
      //router.push("/signin");
    }
  }, [accessToken]);

  const deserialize = (response: AxiosResponse) => {
    try {
      response.data = JSON.parse(response.request.response, (key, value) => {
        try {
          // /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-\.](\d{2})\:(\d{2})/
          if (typeof value === "string") {
            if (
              value.match(
                /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})[+-.](.*)/
              )
            ) {
              return new Date(value);
            }
          }
          return value;
        } catch (error) {
          return value;
        }
      });
    } catch (error) {}

    if (response.data.hasOwnProperty("hydra:member")) {
      response.data = response.data["hydra:member"];
    }

    return response;
  };

  const pubApi = axios.create({
    baseURL: apiUrl,
    headers: {
      "Content-Type": "application/ld+json",
    },
  });

  const prvApi = axios.create({
    baseURL: apiUrl,
    headers: {
      "Content-Type": "application/json",
      accept: "application/ld+json",
      Authorization: `Bearer ${accessToken}`,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  const resApi = axios.create({
    baseURL: resApiUrl,
    headers: {
      "Content-Type": "application/json",
      accept: "application/ld+json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const reportsApi = axios.create({
    baseURL: `${apiUrl}/reports`,
    headers: {
      "Content-Type": "application/json",
      accept: "application/ld+json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  prvApi.interceptors.request.use((value) => {
    if (value.method.toLowerCase() === "patch") {
      value.headers["Content-Type"] = "application/merge-patch+json";
    }
    return value;
  });

  resApi.interceptors.request.use((value) => {
    if (value.method.toLowerCase() === "patch") {
      value.headers["Content-Type"] = "application/merge-patch+json";
    } else if (value.method.toLowerCase() === "put") {
      value.headers["Content-Type"] = "application/ld+json";
    }
    return value;
  });

  reportsApi.interceptors.response.use((response: AxiosResponse) => {
    const isArray: boolean = Array.isArray(response.data);
    if (!isArray) {
      response.data = [response.data];
    }

    response.data = response.data.map((report: IReport) => {
      report.visits = report.visits.map((visit, index) => {
        visit.index = index;
        return visit;
      });
      report.victims = report.beneficiaries
        ?.map((beneficiary) => {
          return beneficiary.victims;
        })
        .reduce((result, item) => {
          return result.concat(item);
        }, []);
      return report;
    });

    if (!isArray) {
      response.data = response.data[0];
    }

    return response;
  });

  // https://github.com/symfony/validator/tree/5.4/Constraints
  const constraints = {
    "c1051bb4-d103-4f74-8988-acbcafc7fdc3": "blank",
    "ea4e51d1-3342-48bd-87f1-9e672cd90cad": "tooLow",
    "23bd9dbf-6b9b-41cd-a99e-4844bcf3077f": "exists",
    "778b7ae0-84d3-481a-9dec-35fdb64b1d78": "positive",
  };

  const handleError = (error, formApi?): IConstraintViolation[] => {
    const resp: AxiosResponse = error.response;
    if (resp.status !== 422) {
      const xDebug: string = resp.headers["x-debug-exception"];
      errorDialog.show({
        title: resp.statusText,
        message:
          `CODE: ${resp.status} \n` +
          (xDebug
            ? decodeURI(xDebug)
            : resp.data["hydra:description"] || resp.data),
      });
      return;
    }
    //const name = resp.config.url.match(/(.*)\\/(\w*)(\/?)/g);
    const payload: IConstraintViolationResponse = resp.data;
    const requestBody = JSON.parse(resp.config.data);
    const violations: IConstraintViolation[] = (payload.violations || []).map(
      (violation) => {
        // Workaround because the server side password validation doesn't return any code
        if (!violation.code && violation.propertyPath === "password") {
          return {
            ...violation,
            message: t(`validation.common.passwordStrong`),
          };
        }
        const constraint = constraints[violation.code];
        const value = requestBody[violation.propertyPath];
        // TODO put this code in a whitelist of codes 6b3befbc-2f01-4ddf-be21-b57898905284
        const message = !constraint
          ? violation.message +
            (violation.code &&
            violation.code != "6b3befbc-2f01-4ddf-be21-b57898905284"
              ? ` CODE: ${violation.code}`
              : "")
          : t(`validation.common.${constraint}`, {
              value: value,
            });
        return {
          ...violation,
          message,
        };
      }
    );

    if (!formApi) {
      errorDialog.show({
        message: violations
          .map((v) => `${v.propertyPath}: ${v.message}`)
          .join("\n"),
      });
      return violations;
    }

    violations.forEach((violation) => {
      formApi.setError(violation.propertyPath, {
        message: violation.message,
      });
    });

    errorDialog.show({
      message: "Vérifier le formulaire.",
    });
  };

  const axiosInstances: {
    [x: string]: AxiosInstance;
  } = { pubApi, prvApi, resApi, reportsApi };

  for (const key in axiosInstances) {
    const api = axiosInstances[key];
    api.interceptors.request.use((config) => {
      loader.show();
      if (key !== "pubApi") {
        const token = localStorage.getItem(tokenStorage);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    });
    api.interceptors.response.use(
      (response) => {
        loader.hide();
        return response;
      },
      async (error) => {
        loader.hide();
        const config = error.config;
        if (
          config.url != "/login_check" &&
          error.response &&
          error.response.status === 401
        ) {
          try {
            const result = await signInDialog.show();
            config.headers.Authorization = `Bearer ${result.token}`;
          } catch (error) {}

          return api(config);
        }
        return Promise.reject(error);
      }
    );
    api.interceptors.response.use(deserialize);
  }

  return { pubApi, prvApi, resApi, reportsApi, handleError };
}
