import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AppTextField from "../components/form-input/AppTextField";
import AppAutocomplete from "../components/form-input/AppAutocomplete";
import FabSubmit from "../components/form-input/FabSubmit";
import { IDepartment, IReportDto } from "../interfaces";
import useApi from "../lib/useApi";
import { useAppContext } from "../providers/AppContext";
import { useHistory } from "react-router-dom";
import { autocompleteCities, autocompleteHamlets } from "../lib/apiUtils";

export default function ReportCreationPage() {
  const { prvApi, handleError } = useApi();
  const formApi = useForm<IReportDto>();
  const { user, isSuperAdmin } = useAppContext();
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [department, setDepartment] = useState<IDepartment>(null);
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!isSuperAdmin()) {
      const userDpts =
        user.groups?.map((group) => {
          return group.department;
        }) || [];
      setDepartments(userDpts);
      if (userDpts.length === 1) {
        setDepartment(userDpts[0]);
      }
      return;
    }
    (async () => {
      const { data } = await prvApi.get("/departments");
      setDepartments(data);
    })();
  }, [user]);

  /* const getCities = async (input: string) => {
    const params: any = {
      label: input,
    };
    if (department) {
      params.department = department;
    } else if (!isAdmin()) {
      user.groups.forEach((gp, index) => {
        params[`department[${index}]`] = gp.department["@id"];
      });
    }
    const { data } = await prvApi.get("/cities", { params });
    return data;
  };

  const getHamlets = async (input: string) => {
    const params: any = {
      hamlet: input,
    };
    const { data } = await prvApi.get("/reports", { params });
    return data.map((report) => {
      return report.hamlet;
    });
  }; */

  const onSubmit = async () => {
    const data = formApi.getValues() as IReportDto;
    data.city = data.city?.["@id"];
    try {
      const resp = await prvApi.post("/reports", data);
      if (resp.status === 201) {
        history.push(`/reports/${resp.data.id}`);
      }
    } catch (error) {
      handleError(error, formApi);
    }
  };

  return (
    <>
      <form onSubmit={formApi.handleSubmit(onSubmit)} className="pb-5">
        <div className="container">
          <div className="row">
            <div className="form-item col-6">
              <AppTextField
                type="date"
                name="damageAt"
                label="Date du dommage"
                formApi={formApi}
              />
            </div>
            <div className="form-item col-6">
              <AppTextField
                type="date"
                name="reportedAt"
                label="Date du signalement"
                formApi={formApi}
              />
            </div>
            <div className="form-item col-6">
              <AppTextField
                type="number"
                name="nbVictimExpected"
                label="Nombre de victimes"
                formApi={formApi}
              />
            </div>
            <div className="form-item col-6">
              <FormControl fullWidth={true} variant="outlined" className="mb-2">
                <InputLabel id="label-dpt">Département</InputLabel>
                <Select
                  labelId="label-dpt"
                  label="Département"
                  value={department ? department["@id"] : ""}
                  onChange={(e) => {
                    setDepartment(
                      departments.find(
                        (department) => department["@id"] === e.target.value
                      )
                    );
                  }}
                >
                  {departments.map((department) => (
                    <MenuItem key={department.id} value={department["@id"]}>
                      {department.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="form-item col-6">
              <AppAutocomplete
                formApi={formApi}
                name="city"
                label="Commune"
                getOptions={async (input) => {
                  return await autocompleteCities(
                    input,
                    prvApi,
                    user,
                    isSuperAdmin(),
                    department
                  );
                }}
              />
            </div>
            <div className="form-item col-6">
              <AppAutocomplete
                formApi={formApi}
                name="hamlet"
                label="Lieu-dit"
                getOptions={async (input) => {
                  return await autocompleteHamlets(input, prvApi);
                }}
                freeSolo={true}
              />
            </div>
          </div>
        </div>
        <FabSubmit />
      </form>
    </>
  );
}
