import { FormControl, FormLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StringMap, TOptions } from "i18next";
import { FC, useState, useEffect, ReactNode } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getQuestionLabel } from "../../lib/utils";
import FormItemError from "./FormItemError";

type AppChexboxesProps = {
  name: string;
  label?: string;
  formApi;
  items: readonly any[];
  i18n?: string;
  i18nAbsPath?: string;
  disabled?: boolean;
};

const AppChexboxes: FC<AppChexboxesProps> = ({
  name,
  label,
  formApi,
  items,
  i18n,
  i18nAbsPath,
  disabled,
}) => {
  const control = formApi.control;
  const { t } = useTranslation();
  const [forceRender, setForceRender] = useState(0);

  useEffect(() => {});

  const getLabel = (value): ReactNode => {
    const options: TOptions<StringMap> = {};
    if (value === "undeterminable") {
      options.defaultValue = t("common.undeterminable");
    }
    if (i18nAbsPath) {
      return t(`${i18nAbsPath}.${value}`, options);
    }

    return getQuestionLabel(
      !i18n ? value : t(`${i18n}.${name}.${value}`, options)
    );
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          value = value || [];
          const itemSelectedIndex = (itemValue) => {
            return value.findIndex((selectedValue) => {
              return itemValue === selectedValue;
            });
          };
          return (
            <FormControl key={forceRender} component="fieldset">
              <FormLabel component="legend">
                {getQuestionLabel(label)}
              </FormLabel>
              <div>
                {items.map((item, index) => {
                  const selectedIndex = itemSelectedIndex(item);
                  const isSelected = selectedIndex > -1;
                  return (
                    <FormControlLabel
                      key={index}
                      disabled={Boolean(disabled)}
                      control={
                        <Checkbox
                          color="primary"
                          checked={isSelected}
                          name={item}
                          onChange={(e) => {
                            if (e.target.checked) value.push(e.target.name);
                            else {
                              const index = value.indexOf(e.target.name);
                              if (index > -1) {
                                value.splice(index, 1);
                              }
                            }
                            onChange(value);
                            setForceRender(forceRender + 1);
                          }}
                        />
                      }
                      label={getLabel(item)}
                    />
                  );
                })}
              </div>
            </FormControl>
          );
        }}
      />
      <FormItemError name={name} formApi={formApi} />
    </>
  );
};
export default AppChexboxes;
