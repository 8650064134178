import { useAppContext } from "../../providers/AppContext";
import useApi from "../useApi";

export const useUser = () => {
  const { prvApi } = useApi();
  const { setUser } = useAppContext();
  const refetchUserData = async () => {
    try {
      const { data, status } = await prvApi.get("/users/me");
      if (status === 200) {
        if (data) {
          setUser({
            ...data,
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return { refetchUserData };
};
