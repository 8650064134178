import { useParams } from "react-router-dom";
import useApi from "../lib/useApi";
import { useAppContext } from "../providers/AppContext";
import getConfig from "../config";
import { useEffect } from "react";

export default function VerifyCodePage() {
  const { code } = useParams<{ code: string }>();
  const { tokenStorage } = getConfig();
  const { pubApi } = useApi();
  const { setUser } = useAppContext();
  localStorage.removeItem("state");

  useEffect(() => {
    (async () => {
      try {
        const respToken = await pubApi.post("/phpcas/access_token", {
          code,
        });
        const token: string = respToken.data.access_token;
        const { data: newUser } = await pubApi.get("/users/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(newUser);
        localStorage.setItem(tokenStorage, token);
        window.location.href = window.location.origin;
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="d-flex h-100 align-items-center justify-content-center">
      Check
    </div>
  );
}
