import { useContext, createContext, useState, useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { IUser } from "../interfaces";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { isUserSuperAdmin, isUserAdminSomewhere } from "../lib/userUtils";
import AppLoader from "../components/dialog/AppLoader";
import ErrorDialog, {
  ErrorDialogProps,
} from "../components/dialog/ErrorDialog";
import SignInDialog from "../components/dialog/SigninDialog";
import {
  manuallyIncrementPromiseCounter,
  manuallyDecrementPromiseCounter,
  usePromiseTracker,
} from "react-promise-tracker";
import { TSignInResult } from "../components/form/SigninForm";

const StoreContext = createContext({} as any);

export const Provider = ({ children }) => {
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  const state = url.searchParams.get("state");
  const localState = localStorage.getItem("state");
  const mustGetToken: boolean = code && state && state === localState;
  let location = useLocation();

  const [user, setUser] = useLocalStorage<IUser | undefined>(
    "geopredateur.user"
  );
  const history = useHistory();

  const validRoutes = [
    useRouteMatch("/signin"),
    useRouteMatch("/verify/:code"),
  ];

  const { promiseInProgress } = usePromiseTracker({
    area: "loader",
  });
  const loader = {
    show() {
      manuallyIncrementPromiseCounter("loader");
    },
    hide() {
      setTimeout(() => {
        manuallyDecrementPromiseCounter("loader");
      }, 100);
    },
  };

  const [errorDialogProps, setErrorDialogProps] =
    useState<ErrorDialogProps>(null);
  const errorDialog = {
    show(props: ErrorDialogProps) {
      setErrorDialogProps(props);
    },
    hide() {
      setErrorDialogProps(null);
    },
  };

  const [isDialOpen, setIsDialOpen] = useState(false);
  const [signInPromises, setSignInPromises] = useState<
    {
      resolve: (result: TSignInResult) => void;
      reject: () => void;
    }[]
  >([]);
  const signInDialog = {
    show() {
      setIsDialOpen(true);
      return new Promise((resolve, reject) => {
        signInPromises.push({ resolve, reject });
        setSignInPromises(signInPromises);
      });
    },
    hide() {
      setIsDialOpen(false);
    },
  };

  const isValidRoute: boolean = Boolean(
    validRoutes.find((val) => {
      return Boolean(val);
    })
  );

  const onSignInClose = (result: TSignInResult) => {
    signInPromises.forEach((promise) => {
      result ? promise.resolve(result) : promise.reject();
    });
    setIsDialOpen(false);
    if (location.pathname === "/signin") {
      history.push("/");
    }
  };

  const isSuperAdmin = () => {
    return isUserSuperAdmin(user);
  };

  const isAdminSomewhere = () => {
    return isUserAdminSomewhere(user);
  };

  useEffect(() => {
    /* if (!validRoutes.includes(router.route) && !user) {
      router.push("/");
    } */
    if (mustGetToken) {
      history.push(`/verify/${code}`);
    } else if (!user && !isValidRoute) {
      history.push("/signin");
    }
  }, [user]);

  return (
    <StoreContext.Provider
      value={{
        user,
        setUser,
        isSuperAdmin,
        isAdminSomewhere,
        loader,
        errorDialog,
        signInDialog,
      }}
    >
      {children}
      <AppLoader isOpen={promiseInProgress} />
      <ErrorDialog
        isOpen={Boolean(errorDialogProps)}
        handleClose={() => setErrorDialogProps(null)}
        {...errorDialogProps}
      />
      <SignInDialog open={isDialOpen} onClose={onSignInClose} />
    </StoreContext.Provider>
  );
};

export const useAppContext = (): {
  user: IUser;
  setUser: (value?) => void;
  isSuperAdmin: () => boolean;
  isAdminSomewhere: () => boolean;
  loader: {
    show: () => void;
    hide: () => void;
  };
  errorDialog: {
    show: (ErrorDialogProps) => void;
    hide: () => void;
  };
  signInDialog: {
    show: () => Promise<TSignInResult>;
    hide: () => void;
  };
} => useContext(StoreContext);
