import { Backdrop, CircularProgress } from "@material-ui/core";

export default function AppLoader(props: { isOpen: boolean }) {
  const { isOpen } = props;

  return (
    <Backdrop className="backdrop-loading" open={isOpen}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
