import { useParams } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import {
  IBeneficiary,
  ICity,
  IDepartment,
  IReport,
  IReportBeneficiary,
} from "../interfaces";
import { useAppContext } from "../providers/AppContext";
import { printDate, printDateMediumFormat } from "../lib/utils";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@material-ui/core";
import { getBeneficiaryLabel, getDDTLabel } from "../lib/reportUtils";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";

export default function DismissLetterPage() {
  const { user } = useAppContext();
  const { prvApi } = useApi();
  const { t } = useTranslation();
  const [data, setData] = useState<IReportBeneficiary>(null);

  const beneficiary: IBeneficiary = data?.beneficiary;
  const report: IReport = data?.report as IReport;
  const city: ICity = report?.city as ICity;
  const department: IDepartment = city?.department as IDepartment;

  let { id } = useParams<{
    id: string;
  }>();

  const form = useForm();
  const [mode, setMode] = useState<"display" | "copy">("display");
  const [predation, setPredation] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [predatorEliminated, setPredatorEliminated] = useState<boolean>(false);
  const [wolfNotProtected, setWolfNotProtected] = useState<boolean>(false);
  const [bearNotProtected, setBearNotProtected] = useState<boolean>(false);
  const [lynxNotProtected, setLynxNotProtected] = useState<boolean>(false);
  const [wolfAttacks, setWolfAttacks] = useState([]);
  const [bearAttacks, setBearAttacks] = useState([]);
  const [over72h, setOver72h] = useState<boolean>(false);
  const [animalsUnidentified, setAnimalsUnidentified] =
    useState<boolean>(false);
  const [mailTrackingNum, setMailTrackingNum] = useState("LRAR n° ");

  useEffect(() => {
    (async () => {
      const { data } = await prvApi.get(`/report_beneficiaries/${id}`);
      setData(data);
      console.log(data);
      setWolfAttacks(
        data.reportDecision.penalizingAttacks?.filter(
          (attack) => attack.isPenalizingWolf === true
        )
      );
      setBearAttacks(
        data.reportDecision.penalizingAttacks?.filter(
          (attack) => attack.isPenalizingBear === true
        )
      );
    })();
  }, [id]);

  const copy = () => {
    setMode("copy");
  };

  useEffect(() => {
    if (mode === "copy") {
      const range = new Range();
      const selection = window.getSelection();
      selection.removeAllRanges();
      const div = document.getElementById("main");
      if (div) {
        range.setStart(div, 2);
        range.setEnd(div, 8);
        selection.addRange(range);
        document.execCommand("copy");
        setMode("display");
      }
    }
  }, [mode]);

  const print = () => {
    window.print();
  };

  if (!data) {
    return <div />;
  }

  return (
    <div className="mainStyle" id="main">
      <Button
        variant="contained"
        color="primary"
        onClick={copy}
        className="hidden-print"
      >
        Copier
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={print}
        className="hidden-print ms-2"
      >
        Imprimer
      </Button>
      <table className="tableStyle">
        <tbody>
          <tr>
            <td align="left" colSpan={2} className="itemTable">
              <img src="/logo_marianne.png" alt="edition" />
              <br />
              {t(
                `department.metaData.prefect.label_${
                  department.metaData?.prefect?.gender || "male"
                }`,
                {
                  departmentArticle: department.metaData?.article,
                  departmentLabel: department.label,
                }
              )}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              <img src="/bloc.png" alt="edition" className="blocStyle" />
            </td>
          </tr>
          <tr>
            <td colSpan={2} height="20px"></td>
          </tr>
          <tr>
            <td width="50%" className="itemTable">
              {`${getDDTLabel(department?.metaData?.ddaf?.designation)} ${
                department.metaData?.article
              } ${department.label}`}
            </td>
          </tr>
          <tr>
            <td className="itemTable">{department.metaData?.service}</td>
          </tr>
          <tr>
            <td className="itemTable">Dossier suivi par : {user.fullname}</td>
          </tr>
          <tr>
            <td className="itemTable">
              Mèl : {department.metaData?.ddaf.email}
              <br />
              Tèl : {department.metaData?.ddaf.phone}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right" width="50%" className="itemTable">
              {department.metaData?.ddaf.city}, le{" "}
              {printDateMediumFormat(new Date())}
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <br />
        {mode === "copy" ? (
          <div>
            <br />
            {mailTrackingNum}
          </div>
        ) : (
          <TextField
            className="hidden-print"
            variant="outlined"
            size="small"
            style={{
              width: 500,
            }}
            value={mailTrackingNum}
            onChange={(e) => setMailTrackingNum(e.currentTarget.value)}
          />
        )}
        <span className="show-print">{mailTrackingNum}</span>
      </div>

      <div>
        <p>Objet : Dommages aux troupeaux - Dossier n° {report.ref}</p>
        <div>
          <br />
          Madame, Monsieur
          <br />
          <br />
          Un constat de dommage n° {report.ref} a été établi le{" "}
          {printDateMediumFormat(report.visits[0]?.visitedAt, "XX/XX/XXXX")} sur
          la commune de {city.label}.
          <br />
          <br />
          <Checkbox
            checked={predation}
            className="hidden-print"
            onChange={(e) => {
              setPredation(!predation);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si cause de la mort non liée à une prédation:
            <br />
          </span>
          <span className={!predation ? "d-none" : ""}>
            L’analyse des éléments techniques recueillis lors du constat mène à
            la conclusion que le dommage constaté n’est pas lié à une prédation.
            En effet, [préciser les éléments d’instruction permettant d’exclure
            la prédation] Or, le décret n° 2019-722 du 9 juillet 2019 relatif à
            l’indemnisation des dommages causés aux troupeaux domestiques par le
            loup, l’ours et le lynx, prévoit que les mortalités non liées à une
            prédation ne donnent pas lieu à indemnisation. En application de
            l’article 3 du décret n° 2019-722 du 9 juillet 2019, l’instruction
            du dossier cité en objet ne peut donc pas conduire à une
            indemnisation.
            <br />
            <br />
          </span>
          <Checkbox
            checked={indeterminate}
            className="hidden-print"
            onChange={(e) => {
              setIndeterminate(!indeterminate);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si cause de la mort indéterminée:
            <br />
          </span>
          <span className={!indeterminate ? "d-none" : ""}>
            L’analyse des éléments techniques recueillis lors du constat mène à
            la conclusion que le dommage constaté est d’origine indéterminée ;
            il n’est pas possible de conclure à une prédation. En effet,
            [préciser les éléments observés Or, le décret n° 2019-722 du 9
            juillet 2019 relatif à l’indemnisation des dommages causés aux
            troupeaux domestiques par le loup, l’ours et le lynx, prévoit que
            les mortalités dont la cause est indéterminée ne donnent pas lieu à
            indemnisation. En application de l’article 3 du décret n° 2019-722
            du 9 juillet 2019, l’instruction du dossier cité en objet ne peut
            donc pas conduire à une indemnisation.
            <br />
            <br />
          </span>
          <Checkbox
            checked={predatorEliminated}
            className="hidden-print"
            onChange={(e) => {
              setPredatorEliminated(!predatorEliminated);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si prédation avérée mais 3 prédateurs écartés:
            <br />
          </span>
          <span className={!predatorEliminated ? "d-none" : ""}>
            L’analyse des éléments techniques recueillis lors du constat mène à
            la conclusion que le dommage constaté est lié à une prédation, mais
            dont la responsabilité du loup, de l’ours et du lynx est exclue. En
            effet, [préciser les éléments observés] Or, le décret n° 2019-722 du
            9 juillet 2019 relatif à l’indemnisation des dommages causés aux
            troupeaux domestiques par le loup, l’ours et le lynx, prévoit que
            les mortalités liées à une prédation ne donnent lieu à indemnisation
            que si la responsabilité du loup, de l’ours ou du lynx n’est pas
            écartée. En application de l’article 3 du décret n° 2019-722 du 9
            juillet 2019, l’instruction du dossier cité en objet ne peut donc
            pas conduire à une indemnisation.
            <br />
            <br />
          </span>
          <Checkbox
            checked={wolfNotProtected}
            className="hidden-print"
            onChange={(e) => {
              setWolfNotProtected(!wolfNotProtected);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si défaut de protection loup pour les ovins et les caprins:
            <br />
          </span>
          <span className={!wolfNotProtected ? "d-none" : ""}>
            Votre troupeau a subi {wolfAttacks ? wolfAttacks.length : "X"}{" "}
            attaques :
            <ul>
              {wolfAttacks &&
                wolfAttacks.map((attack) => (
                  <li key={attack.id}>
                    le {printDate(attack.date.date, "XX/XX/XXXX")} (constat n°
                    {attack.ref})
                  </li>
                ))}
            </ul>
            Le dommage que vous avez signalé le{" "}
            {printDate(report.damageAt, "XX/XX/XXXX")} dernier constitue donc la
            troisième attaque ou plus subie par votre élevage au cours des douze
            derniers mois. De plus, le troupeau ayant subi un dommage ne peut
            pas être considéré comme protégé au moment de l’attaque (pas de
            contrat de protection, système en place non équivalent à celui de
            l’OPEDER, mesures du contrat non mises en place …) Or, le décret no
            2019-722 du 9 juillet 2019 relatif à l’indemnisation des dommages
            causés aux troupeaux domestiques par le loup, l’ours et le lynx,
            prévoit que dans les cercle 0 et cercle 1 définis par arrêté du
            ministre chargé de l’agriculture, l’indemnisation des dommages dus
            aux loups concernant les ovins et caprins est subordonnée à la mise
            en place de mesures préventives de protection des troupeaux dès lors
            que le troupeau a subi au moins 2 attaques pour lesquels la
            responsabilité du loup n’est pas écartée au cours des 12 derniers
            mois. En application de l’article 5 du décret n° 2019-722 du 9
            juillet 2019, le dommage constaté, pour lequel la responsabilité du
            loup n’est pas écartée, ne peut donc pas être indemnisé.
            <br />
            <br />
          </span>
          <Checkbox
            checked={bearNotProtected}
            className="hidden-print"
            onChange={(e) => {
              setBearNotProtected(!bearNotProtected);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si défaut de protection ours pour les ovins et les caprins:
            <br />
          </span>
          <span className={!bearNotProtected ? "d-none" : ""}>
            Votre troupeau a subi {bearAttacks ? bearAttacks.length : "X"}{" "}
            attaques :
            <ul>
              {bearAttacks &&
                bearAttacks.map((attack) => (
                  <li key={attack.id}>
                    le {printDate(attack.date.date, "XX/XX/XXXX")} (constat n°
                    {attack.ref})
                  </li>
                ))}
            </ul>{" "}
            Le dommage que vous avez signalé le{" "}
            {printDate(report.damageAt, "XX/XX/XXXX")} dernier constitue donc la
            troisième attaque ou plus subie par votre élevage au cours des douze
            derniers mois. De plus, le troupeau ayant subi un dommage ne peut
            pas être considéré comme protégé au moment de l’attaque (pas de
            contrat de protection, système en place non équivalent à celui de
            l’OPEDER, mesures du contrat non mises en place …) Or, le décret no
            2019-722 du 9 juillet 2019 relatif à l’indemnisation des dommages
            causés aux troupeaux domestiques par le loup, l’ours et le lynx,
            modifié par le décret n° 2021-299 du 19 mars 2021, prévoit que dans
            les cercle 0 et cercle 1 définis par arrêté du préfet de massif,
            l’indemnisation des dommages dus aux ours concernant les ovins et
            caprins est subordonnée à la mise en place de mesures préventives de
            protection des troupeaux dès lors que le troupeau a subi au moins 2
            attaques pour lesquels la reponsabilité de l’ours n’est pas écartée
            au cours des 12 derniers mois. En application de l’article 5 du
            décret n° 2019-722 du 9 juillet 2019, le dommage constaté, pour
            lequel la responsabilité de l’ours n’est pas écartée, ne peut donc
            pas être indemnisé.
            <br />
            <br />
          </span>
          <Checkbox
            checked={lynxNotProtected}
            className="hidden-print"
            onChange={(e) => {
              setLynxNotProtected(!lynxNotProtected);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si défaut de protection lynx:
            <br />
          </span>
          <span className={!lynxNotProtected ? "d-none" : ""}>
            Votre troupeau a subi, au cours des deux années précédant le dommage
            du JJ/MM/AAAA, quatre prédations attribuée au lynx : le JJ/MM/AAAA
            (constat n° 0000 00 000), le ... De plus, le troupeau ayant subi un
            dommage ne peut pas être considéré comme protégé au moment de
            l’attaque (pas de contrat de protection, système en place non
            équivalent à celui de l’OPEDER, mesures du contrat non mises en
            place …) Or, le décret n° 2019-722 du 9 juillet 2019 relatif à
            l’indemnisation des dommages causés aux troupeaux domestiques par le
            loup, l’ours et le lynx, prévoit qu’à compter de la cinquième
            attaque pour laquelle la responsabilité du lynx n’est pas écartée
            sur une période de deux ans, le versement de l’indemnisation est
            subordonné à la mise en place d’une mesure de protection pour
            percevoir une indemnisation. En application de l’article 6 du décret
            n° 2019-722 du 9 juillet 2019, le dommage constaté, pour lequel la
            responsabilité du lynx n’est pas écartée, ne peut donc pas être
            indemnisé.
            <br />
            <br />
          </span>
          <Checkbox
            checked={over72h}
            className="hidden-print"
            onChange={(e) => {
              setOver72h(!over72h);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si déclaration à plus de 72 heures:
            <br />
          </span>
          <span className={!over72h ? "d-none" : ""}>
            La déclaration du dommage a eu lieu le{" "}
            {printDateMediumFormat(report.visits[0]?.notifyAt, "XX/XX/XXXX")},
            soit plus de 72 heures après la date de l’attaque supposée, qui au
            vu des éléments relevés sur le terrain [préciser] est antérieure au{" "}
            {printDateMediumFormat(report.damageAt, "XX/XX/XXXX")}. Or,
            l’article 2 du décret n° 2019-772 du 9 juillet 2019 relatif à
            l’indemnisation des dommages causés aux troupeaux domestiques par le
            loup, l’ours et le lynx, indique que la demande d’indemnisation doit
            être formulée dans un délai de 72 heures à compter de la date de
            survenance de l’attaque supposée. En application de l’article 2 du
            décret n° 2019-772 du 9 juillet 2019, l’instruction du dossier cité
            en objet ne peut donc pas conduire à une indemnisation.
            <br />
            <br />
          </span>
          <Checkbox
            checked={animalsUnidentified}
            className="hidden-print"
            onChange={(e) => {
              setAnimalsUnidentified(!animalsUnidentified);
            }}
          />
          <span className={mode === "copy" ? "d-none" : "hidden-print"}>
            Cocher si animaux non identifiés:
            <br />
          </span>
          <span className={!animalsUnidentified ? "d-none" : ""}>
            Après vérification auprès de l’Établissement de l’élevage (EdE) en
            charge de l’identification du cheptel domestique, vous n’êtes pas
            enregistré auprès de cet établissement pour l’élevage ovin, et les
            animaux ayant subi le dommage ne sont donc pas identifiés. La
            réglementation sur la détention de cheptel domestique rend pourtant
            obligatoire cet enregistrement ainsi que l’identification
            individuelle des ovins,et ce dès le premier animal. Par ailleurs, le
            décret n° 2019-722 du 9 juillet 2019 relatif à l’indemnisation des
            dommages causés aux troupeaux domestiques par le loup, l’ours et le
            lynx, prévoit que les demandeurs ne puissent être indemnisés que
            pour les animaux dont ils sont propriétaires ou détenteurs au moment
            de l’attaque, ce qui nécessite une identification des animaux. En
            application du décret n° 2019-772 du 9 juillet 2019, l’instruction
            du dossier cité en objet ne peut donc pas conduire à une
            indemnisation.
          </span>
        </div>
        <p>
          Toutefois dans le cas où vous auriez des éléments complémentaires à
          formuler, vous avez la possibilité de demander un réexamen de votre
          dossier dans un délai de 15 jours à compter de la réception de la
          présente. Votre dossier fera alors l'objet d'une présentation lors de
          la prochaine réunion d'un groupe de travail auxquels participent des
          représentants de la profession agricole.
        </p>
        <p>
          Sans remarque de votre part et à l’issue de ces quinze jours, vous
          avez également la possibilité d’effectuer, dans un délai de deux mois,
          un recours gracieux auprès du Directeur Départemental des Territoires,
          ou d’un recours administratif auprès du Tribunal Administratif de{" "}
          {department.metaData?.ddaf.city}.
        </p>
        <p>
          Restant à votre disposition pour toute information supplémentaire, je
          vous prie d'agréer, Madame, Monsieur, l'expression de ma considération
          distinguée.
        </p>
      </div>
      <div className="signatureStyle">
        {department.metaData?.ddaf.title}
        <br />
        <br />
        <br />
        <br />
        {department.metaData?.ddaf.fullname}
        <br />
        <br />
      </div>

      <div>
        <br />
        {getBeneficiaryLabel(beneficiary)} <br />
        {beneficiary.address}
        <br />
        {beneficiary.zipCode} {beneficiary.city}
        <br />
      </div>
      <div>
        <br />
        {department.metaData?.ddaf.address}
        <br />
        {department.metaData?.ddaf.city}
        <br />
        <br />
      </div>
    </div>
  );
}
