import { FC, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@material-ui/core";

const ConfirmDialog: FC<{
  isOpen: boolean;
  title?: string;
  message?: string;
  expectedInputValue?: string;
  confirmLabel?: string;
  abortLabel?: string;
  onConfirm?: () => void;
  onAbort?: () => void;
}> = (props) => {
  const { expectedInputValue } = props;
  const [inputValue, setInputValue] = useState("");
  const [hasInputError, setHasInputError] = useState(false);

  const confirm = () => {
    setHasInputError(false);
    if (Boolean(expectedInputValue) && expectedInputValue != inputValue) {
      return setTimeout(() => {
        setHasInputError(true);
      }, 100);
    }

    props.onConfirm?.();
  };

  return (
    <Dialog
      onClose={props.onAbort}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle id="simple-dialog-title">
        {props.title || "Êtes-vous sûr ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.message || "Cette action est irréversible."}
        </DialogContentText>
        {Boolean(props.expectedInputValue) && (
          <DialogContentText component="div">
            <p className="mb-0">
              Pour confirmer veuillez saisir : {expectedInputValue}
            </p>
            <TextField
              fullWidth={true}
              variant="outlined"
              onChange={(e) => setInputValue(e.target.value)}
            />
            {hasInputError && (
              <p className="form-item-error">
                Vous n'avez pas saisi : {expectedInputValue}
              </p>
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color="secondary">
          {props.confirmLabel || "Continuer"}
        </Button>
        <Button onClick={props.onAbort}>
          {" "}
          {props.abortLabel || "Abandonner"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
