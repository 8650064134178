import {
  Button,
  Card,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BENEFICIARY_HERD_COLS,
  BENEFICIARY_MATERIAL_DAMAGES,
} from "../../constants";
import { IReportBeneficiary, IVisitBeneficiary } from "../../interfaces";
import {
  getBeneficiaryHerdTotal,
  getBeneficiaryLabel,
  getHerdTotal,
} from "../../lib/reportUtils";
import useApi from "../../lib/useApi";
import ConfirmDialog from "../dialog/ConfirmDialog";
import AppCheckbox from "../form-input/AppCheckbox";
import AppCheckboxes from "../form-input/AppCheckboxes";
import AppSelect from "../form-input/AppSelect";
import AppTextField from "../form-input/AppTextField";
import VisitValues from "../visit-values/VisitValues";

const ReportBeneficiaryForm: FC<{
  formApi: any;
  beneficiary?: IReportBeneficiary;
  isDisabled?: boolean;
}> = ({ formApi, beneficiary, isDisabled }) => {
  const { prvApi, handleError } = useApi();
  const { t } = useTranslation();
  const [resolvedVisitBeneficiaries, setResolvedVisitBeneficiaries] = useState<
    IVisitBeneficiary[]
  >([]);
  const [reportBeneficiaries, setReportBeneficiaries] = useState<
    IReportBeneficiary[]
  >([]);

  const visitValuesSources = beneficiary?.visitBeneficiaries;
  const hasVisits = Boolean(visitValuesSources?.length);
  const visitValuesDatePath: string = "visit.visitedAt";
  const [isAutoHerdTotalAllowed, setIsAutoHerdTotalAllowed] = useState<
    boolean | null
  >(null);
  const [showConfirmAutoHerdtotal, setShowConfirmAutoHerdtotal] =
    useState(false);

  const isInputDisabled: boolean = hasVisits || isDisabled;

  const watchHerd = { ...formApi.watch("herd") };
  const watchNbAnimals = formApi.watch("nbAnimals");

  useEffect(() => {
    if (!beneficiary) {
      return;
    }
    (async () => {
      const resps = await Promise.all([
        prvApi.get("/visit_beneficiaries", {
          params: {
            "visit.report": beneficiary.report.id,
          },
        }),
        prvApi.get("/report_beneficiaries", {
          params: {
            report: beneficiary.report.id,
          },
        }),
      ]);
      setResolvedVisitBeneficiaries(
        (resps[0].data as IVisitBeneficiary[]).filter(
          (vBen) => vBen.reportBeneficiary
        )
      );
      setReportBeneficiaries(resps[1].data);
    })();
  }, [beneficiary]);

  if (!beneficiary) {
    return <></>;
  }
  const isGpInGp = beneficiary.beneficiary?.isCorporate && beneficiary?.parent;

  let herdTotal = -1;
  if (beneficiary.children?.length) {
    herdTotal = getBeneficiaryHerdTotal(beneficiary);
  } else if (watchHerd) {
    herdTotal = getHerdTotal(watchHerd);
  }

  return (
    <>
      <div className="row mb-4">
        {(!beneficiary.beneficiary?.isCorporate || beneficiary.parent) && (
          <div className="col-6 mb-4">
            <AppSelect
              formApi={formApi}
              name={"parent"}
              label="Groupement"
              disabled={isDisabled}
            >
              <MenuItem value={null}>Pas de groupement</MenuItem>
              {reportBeneficiaries
                .filter((ben) => {
                  return ben["@id"] != beneficiary["@id"];
                })
                .map((ben) => (
                  <MenuItem key={ben["@id"]} value={ben["@id"]}>
                    {getBeneficiaryLabel(ben.beneficiary)}
                  </MenuItem>
                ))}
            </AppSelect>
            {hasVisits && (
              <VisitValues
                disabled={isDisabled}
                label="Groupement"
                visits={beneficiary.report?.["visits"]}
                visitPath="visit"
                sources={visitValuesSources}
                path="parent"
                datePath={visitValuesDatePath}
                valueLabelGetter={(id: number) => {
                  return (
                    getBeneficiaryLabel(
                      (
                        resolvedVisitBeneficiaries.find(
                          (vBen) => vBen.id === id
                        )?.reportBeneficiary as IReportBeneficiary
                      )?.beneficiary
                    ) || id.toString()
                  );
                }}
                editor="select"
                editorOptions={{
                  children: resolvedVisitBeneficiaries.map((vBen) => [
                    <MenuItem value=""></MenuItem>,
                    <MenuItem key={vBen.id} value={vBen.id}>
                      {getBeneficiaryLabel(vBen.reportBeneficiary.beneficiary)}
                    </MenuItem>,
                  ]),
                }}
                onValueSelected={(id: number) => {
                  const vBen = resolvedVisitBeneficiaries.find(
                    (vBen) => vBen.id == id
                  );
                  formApi.setValue(
                    "parent",
                    vBen?.reportBeneficiary?.["@id"] || null
                  );
                }}
              />
            )}
          </div>
        )}
        {isGpInGp && (
          <div className="col-6 mb-4 pt-2">
            <Alert severity="error">
              Un groupement ne doit pas être dans un groupement.
            </Alert>
          </div>
        )}
      </div>

      {Boolean(beneficiary.children?.length) && (
        <div className="row mb-4">
          <div className="col-6">
            <AppTextField
              type="number"
              formApi={formApi}
              name={"nbAnimals"}
              label="Effectif attaqué"
              disabled={isInputDisabled}
            />
            {hasVisits && (
              <VisitValues
                disabled={isDisabled}
                label="Effectif attaqué"
                visits={beneficiary?.report?.["visits"]}
                visitPath="visit"
                sources={visitValuesSources}
                path="nbAnimals"
                datePath={visitValuesDatePath}
                formApi={formApi}
                editor="textfield"
                editorOptions={{ type: "number" }}
              />
            )}
            {herdTotal > -1 && herdTotal != watchNbAnimals && (
              <Alert
                severity="error"
                action={
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      formApi.setValue("nbAnimals", herdTotal);
                    }}
                  >
                    Appliquer la valeur
                  </Button>
                }
              >
                L'effectif attaqué devrait correspondre à la somme des troupeaux
                : <b>{herdTotal}</b>
              </Alert>
            )}
            {herdTotal > -1 && herdTotal == watchNbAnimals && (
              <Alert severity="success">
                L'effectif attaqué correspond à la somme des troupeaux :{" "}
                <b>{herdTotal}</b>
              </Alert>
            )}
          </div>
          <div className="col-6">
            <TableContainer component={Paper} className="p-3">
              <h4 className="my-0">Effectif des troupeaux</h4>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Éleveur</TableCell>
                    <TableCell>Effectif</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiary.children.map((child) => (
                    <TableRow key={child["@id"]}>
                      <TableCell>
                        {getBeneficiaryLabel(child.beneficiary)}
                      </TableCell>
                      <TableCell>{getHerdTotal(child.herd)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
      {!Boolean(beneficiary.children?.length) && (
        <div className="row mb-4 pb-2">
          <div className="col-12 pb-3">
            <Alert severity="warning">
              <b>Attention :</b>{" "}
              {Boolean(beneficiary.parent) ? (
                `Il est impératif de renseigner la composition du troupeau, elle sera reporté dans l'onglet PDF.`
              ) : (
                <span>
                  <br />
                  &bull; Il est impératif de renseigner la composition du
                  troupeau, elle sera reporté dans l'onglet PDF.
                  <br />
                  &bull; le "total effectif attaqué" doit correspondre à la
                  somme des effectifs "ovin", "caprin", "bovin", "équin" et
                  "autre".
                </span>
              )}
            </Alert>
          </div>
          <div className={`col-${beneficiary.parent ? "12" : "9"}`}>
            <TableContainer component={Paper} className="p-3">
              <h4 className="my-0">Composition du troupeau</h4>
              <Table>
                <TableHead>
                  <TableRow>
                    {BENEFICIARY_HERD_COLS.map((name) => (
                      <TableCell
                        key={name}
                        className="text-center px-1"
                        width={`${Math.floor(100 / 6)}%`}
                      >
                        {t(`beneficiary.herd.${name}`)}
                      </TableCell>
                    ))}
                    <TableCell
                      className="text-center px-1"
                      width={`${Math.floor(100 / 6)}%`}
                    >
                      <b>Total effectif attaqué</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {BENEFICIARY_HERD_COLS.map((name) => (
                      <TableCell key={name} className="p-1 text-center">
                        <AppTextField
                          type="number"
                          formApi={formApi}
                          name={`herd.${name}`}
                          hasClearButton={false}
                          size="small"
                          inputProps={{
                            className: "px-0 text-center",
                          }}
                          disabled={isInputDisabled}
                          onChange={(value) => {
                            if (beneficiary.parent) {
                              return;
                            }
                            if (isAutoHerdTotalAllowed) {
                              formApi.setValue(
                                "nbAnimals",
                                getHerdTotal(formApi.getValues().herd)
                              );
                            } else if (isAutoHerdTotalAllowed === null) {
                              setShowConfirmAutoHerdtotal(true);
                            }
                          }}
                        />
                        {hasVisits && (
                          <VisitValues
                            disabled={isDisabled}
                            direction="column"
                            label={t(`beneficiary.herd.${name}`)}
                            visits={beneficiary?.report?.["visits"]}
                            visitPath="visit"
                            sources={visitValuesSources}
                            name={`herd.${name}`}
                            path={`herd.${name}`}
                            datePath={visitValuesDatePath}
                            formApi={formApi}
                            editor="textfield"
                            editorOptions={{ type: "number" }}
                          />
                        )}
                      </TableCell>
                    ))}
                    {Boolean(beneficiary.parent) ? (
                      <TableCell className="text-center align-top">
                        {getHerdTotal(watchHerd)}
                      </TableCell>
                    ) : (
                      <TableCell className="p-1 text-center">
                        <AppTextField
                          type="number"
                          formApi={formApi}
                          name={"nbAnimals"}
                          hasClearButton={false}
                          size="small"
                          inputProps={{
                            className: "px-0 text-center",
                          }}
                          disabled={isInputDisabled}
                        />
                        {hasVisits && (
                          <VisitValues
                            disabled={isDisabled}
                            label="Effectif attaqué"
                            visits={beneficiary?.report?.["visits"]}
                            visitPath="visit"
                            sources={visitValuesSources}
                            path="nbAnimals"
                            datePath={visitValuesDatePath}
                            formApi={formApi}
                            editor="textfield"
                            editorOptions={{ type: "number" }}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {!Boolean(beneficiary.parent) && (
            <div className="col-3">
              {herdTotal > -1 && herdTotal != watchNbAnimals && (
                <>
                  <Alert severity="error">
                    Le "total effectif attaqué" devrait être <b>{herdTotal}</b>
                  </Alert>
                  <Button
                    className="mt-2"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      formApi.setValue("nbAnimals", herdTotal);
                    }}
                  >
                    Appliquer la valeur
                  </Button>
                </>
              )}
              {herdTotal > -1 && herdTotal == watchNbAnimals && (
                <Alert severity="success">
                  L'effectif attaqué est bien renseigné
                </Alert>
              )}
            </div>
          )}
        </div>
      )}

      {!beneficiary.parent && (
        <>
          <div className="row mb-4">
            <div className="col-12">
              <AppCheckbox
                disabled={isInputDisabled}
                name="isLightCareAsk"
                label="L'éleveur souhaite bénéficier du forfait annuel soins légers pour la réalisation des soins aux animaux blessés"
                formApi={formApi}
              />

              {hasVisits && (
                <VisitValues
                  disabled={isDisabled}
                  label="L'éleveur souhaite bénéficier du forfait annuel soins légers"
                  visits={beneficiary.report?.["visits"]}
                  visitPath="visit"
                  sources={visitValuesSources}
                  path="isLightCareAsk"
                  datePath={visitValuesDatePath}
                  formApi={formApi}
                  editor="checkbox"
                />
              )}
            </div>
          </div>
          <Card variant="outlined" className="p-3">
            <h4 className="mt-0">Dégâts matériels</h4>
            <div className="row">
              <div className="col-6">
                <AppCheckboxes
                  disabled={isInputDisabled}
                  name="materialDamages"
                  label="L'éleveur déclare avoir subi des dommages matériels sur :"
                  formApi={formApi}
                  items={BENEFICIARY_MATERIAL_DAMAGES}
                  i18n="beneficiary"
                />
                {hasVisits && (
                  <VisitValues
                    disabled={isDisabled}
                    label="L'éleveur déclare avoir subi des dommages matériels sur"
                    visits={beneficiary?.report?.["visits"]}
                    visitPath="visit"
                    sources={visitValuesSources}
                    path="materialDamages"
                    datePath={visitValuesDatePath}
                    formApi={formApi}
                    editor="checkboxes"
                    i18nPrefix="beneficiary"
                    values={BENEFICIARY_MATERIAL_DAMAGES}
                  />
                )}
              </div>
              <div className="col-6">
                <AppTextField
                  formApi={formApi}
                  name={"materialDamagesDetail"}
                  label="Détails"
                  disabled={isInputDisabled}
                />
                {hasVisits && (
                  <VisitValues
                    disabled={isDisabled}
                    label="Détails des dommages matériels"
                    visits={beneficiary?.report?.["visits"]}
                    visitPath="visit"
                    sources={visitValuesSources}
                    path="materialDamagesDetail"
                    datePath={visitValuesDatePath}
                    formApi={formApi}
                    editor="textfield"
                  />
                )}
              </div>
            </div>
          </Card>
        </>
      )}
      <ConfirmDialog
        isOpen={showConfirmAutoHerdtotal}
        title="Calcul du total effectif attaqué"
        message={`Voulez-vous appliquer automatiquement la somme des effectifs "ovin", "caprin", "bovin", "équin" et "autre" ?`}
        confirmLabel="Oui"
        abortLabel="Non"
        onConfirm={() => {
          formApi.setValue("nbAnimals", getHerdTotal(formApi.getValues().herd));
          setIsAutoHerdTotalAllowed(true);
          setShowConfirmAutoHerdtotal(false);
        }}
        onAbort={() => {
          setIsAutoHerdTotalAllowed(false);
          setShowConfirmAutoHerdtotal(false);
        }}
      ></ConfirmDialog>
    </>
  );
};

export default ReportBeneficiaryForm;
