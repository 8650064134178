import { MenuItem } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { IVisit, IVisitBeneficiary } from "../../interfaces";
import { getBeneficiaryLabel } from "../../lib/reportUtils";
import useApi from "../../lib/useApi";
import AppCheckbox from "../form-input/AppCheckbox";
import AppSelect from "../form-input/AppSelect";
import AppTextField from "../form-input/AppTextField";

const VisitBeneficiaryForm: FC<{
  formApi: UseFormReturn;
  fieldPrefix?: string;
  isDisabled?: boolean;
}> = (props) => {
  const { prvApi } = useApi();
  const [visitBeneficiaries, setVisitBeneficiaries] = useState<
    IVisitBeneficiary[]
  >([]);

  const visitBeneficiary: IVisitBeneficiary = props.formApi.getValues();

  useEffect(() => {
    if (!visitBeneficiary) {
      return;
    }
    (async () => {
      const resp = await prvApi.get("/visit_beneficiaries", {
        params: {
          visit: (visitBeneficiary.visit as IVisit)["@id"],
        },
      });
      setVisitBeneficiaries(resp.data);
    })();
  }, []);

  const getInputName = (name): string => {
    return props.fieldPrefix ? `${props.fieldPrefix}.${name}` : name;
  };

  const { isDisabled } = props;

  return (
    <>
      <div className="row">
        <div className="form-item col-6 mt-0">
          <AppCheckbox
            formApi={props.formApi}
            name={getInputName("isCorporate")}
            label="Groupement"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6 mt-0">
          <AppSelect
            formApi={props.formApi}
            name={"parentId"}
            label="Dans le groupement"
            disabled={isDisabled}
          >
            <MenuItem value={null}>Pas de groupement</MenuItem>
            {visitBeneficiaries.map((ben) => (
              <MenuItem key={ben.id} value={ben.id}>
                {getBeneficiaryLabel(ben)}
              </MenuItem>
            ))}
          </AppSelect>
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("corporateName")}
            label="Raison sociale"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("lastName")}
            label="Nom"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("firstName")}
            label="Prénom"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("siret")}
            label="n° Siret"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("pacage")}
            label="n° Pacage"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("email")}
            label="Email"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppCheckbox
            formApi={props.formApi}
            name={getInputName("isAgreeForEmail")}
            label="Consent à échanger par email"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("phone")}
            label="Téléphone"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("address")}
            label="Adresse"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("zipCode")}
            label="Code postal"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("city")}
            label="Ville"
            disabled={isDisabled}
          />
        </div>
        <div className="form-item col-6">
          <AppTextField
            formApi={props.formApi}
            name={getInputName("hamlet")}
            label="Lieu-dit"
            disabled={isDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default VisitBeneficiaryForm;
