import { useParams, Prompt, Link as RouterLink } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IReport, IReportBeneficiary } from "../interfaces";
import { useForm } from "react-hook-form";
import ReportBeneficiaryForm from "../components/form/ReportBeneficiaryForm";
import SaveIcon from "@material-ui/icons/Save";
import { Breadcrumbs, Fab, Link, Typography } from "@material-ui/core";
import pick from "lodash/pick";
import BeneficiaryForm from "../components/form/BeneficiaryForm";
import {
  fixReportBeneficiary,
  getBeneficiaryLabel,
  isReportDisabled,
} from "../lib/reportUtils";
import { Alert } from "@material-ui/lab";
import isPlainObject from "lodash/isPlainObject";

export default function ReportBeneficiaryPage() {
  const { prvApi, handleError } = useApi();
  const [beneficiary, setBeneficiary] = useState<IReportBeneficiary>(null);
  const formApi = useForm();
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const { isDirty: hasInteracted } = formApi.formState;

  useEffect(() => {
    if (!id) {
      return;
    }
    (async () => {
      const { data } = await prvApi.get(`/report_beneficiaries/${id}`);
      fixReportBeneficiary(data);
      formApi.reset({
        ...data,
      });
      setBeneficiary(data);
    })();
  }, [id]);

  const onSubmit = async () => {
    const report = beneficiary.report;
    const data = formApi.getValues() as IReportBeneficiary;
    const dto = {
      ...pick(data, [
        "parent",
        "nbAnimals",
        "herd",
        "isLightCareAsk",
        "materialDamages",
        "materialDamagesDetail",
      ]),
      beneficiary: {
        ...(pick(data.beneficiary, [
          "@id",
          "pacage",
          "siret",
          "protectionContract",
          "isCorporate",
          "corporateName",
          "firstName",
          "lastName",
          "email",
          "isAgreeForEmail",
          "phone",
          "address",
          "zipCode",
          "city",
          "hamlet",
        ]) as any),
      },
    } as IReportBeneficiary;
    if (isPlainObject(dto.parent)) {
      dto.parent = dto.parent["@id"] as any;
    }
    try {
      await prvApi.patch(`/report_beneficiaries/${data.id}`, dto);
      formApi.reset(data);
      history.push(`/reports/${report.id}/beneficiaries`);
    } catch (error) {
      handleError(error, formApi);
    }
  };

  if (!beneficiary) {
    return <div />;
  }

  const report: IReport = beneficiary.report as IReport;
  const isDisabled = isReportDisabled(report);

  return (
    <div>
      <Prompt
        when={hasInteracted}
        message={(location) => {
          return "Quitter la page sans enregistrer ?";
        }}
      />
      <form onSubmit={formApi.handleSubmit(onSubmit)} className="pb-5">
        <div className="container">
          <Breadcrumbs separator="›" className="my-4">
            <Link component={RouterLink} to={`/reports/${report.id}`}>
              Dossier {report.ref}
            </Link>
            <Link
              component={RouterLink}
              to={`/reports/${report.id}/beneficiaries`}
            >
              Bénéficiaires
            </Link>
            <Typography>
              {getBeneficiaryLabel(beneficiary?.beneficiary)}
            </Typography>
          </Breadcrumbs>
          <ReportBeneficiaryForm
            formApi={formApi}
            beneficiary={beneficiary}
            isDisabled={isDisabled}
          ></ReportBeneficiaryForm>
          <div className="p-4">
            <Alert severity="warning">
              <b>Attention :</b> Toutes modifications sur les champs ci-dessous
              sont répercutées sur l'ensemble des dossiers pour lesquels ce
              bénéficiaire figure.
              <br />
              Si vous avez appelez un bénéficiaire temporaire comme "INCONNU"
              par exemple, veillez à ne modifier aucun champs.
            </Alert>
          </div>
          <BeneficiaryForm
            formApi={formApi}
            fieldPrefix="beneficiary"
            reportBeneficiary={beneficiary}
            isDisabled={isDisabled}
          />
        </div>
        {!isDisabled && (
          <Fab className="fab-bottom-right" color="primary" type="submit">
            <SaveIcon />
          </Fab>
        )}
      </form>
    </div>
  );
}
