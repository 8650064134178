import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select, { SelectProps } from "@material-ui/core/Select";
import { FC } from "react";
import { Controller } from "react-hook-form";
import FormItemError from "./FormItemError";
import isPlainObject from "lodash/isPlainObject";

type AppSelectProps = {
  name;
  label?;
  formApi;
  defaultValue?;
  children;
  valueToNumber?: boolean;
  selectProps?: SelectProps;
  nullIfEmpty?: boolean;
  fullWidth?: boolean;
  variant?;
  valueKey?: string;
  disabled?: boolean;
  onSelect?: (value: string) => void;
};

const AppSelect: FC<AppSelectProps> = ({
  name,
  label,
  formApi,
  defaultValue,
  children,
  valueToNumber,
  selectProps,
  nullIfEmpty,
  fullWidth,
  variant,
  valueKey = "@id",
  disabled,
  onSelect,
  ...props
}) => {
  const control = formApi.control;
  const labelId = `${name}-label`;
  selectProps = selectProps || {};
  if (selectProps.multiple) {
    selectProps.native = false;
  }
  fullWidth = fullWidth === false ? false : true;
  variant = variant || "outlined";
  if (isPlainObject(defaultValue)) {
    defaultValue = defaultValue[valueKey];
  }

  return (
    <>
      <FormControl
        {...props}
        fullWidth={fullWidth}
        variant={variant}
        className="mb-2"
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value } }) => {
            if (typeof value === "undefined" || value === null) {
              value = "";
            }
            if (isPlainObject(value)) {
              value = value[valueKey];
            }
            if (selectProps.multiple && !Array.isArray(value)) {
              value = [];
            }
            return (
              <Select
                {...selectProps}
                value={value}
                labelId={labelId}
                label={label}
                disabled={disabled}
                onChange={(e) => {
                  if (selectProps.multiple || !selectProps.native) {
                    onChange(e.target.value);
                    onSelect && onSelect(e.target.value as string);
                  } else {
                    const value: string = String(e.target.value);
                    !value
                      ? onChange(nullIfEmpty ? null : undefined)
                      : onChange(valueToNumber ? Number(value) : value);
                  }
                }}
              >
                {children}
              </Select>
            );
          }}
        />
      </FormControl>
      <FormItemError name={name} formApi={formApi} />
    </>
  );
};
export default AppSelect;
