import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Controller } from "react-hook-form";
import FormItemError from "./FormItemError";

export interface DateRangePeriod {
  startDate?: Date;
  endDate?: Date;
}

interface DatePickerProps {
  formApi: any;
  label?: string;
  name: string;
  format?: string;
  disabled?: boolean;
}

const AppDatePicker: React.FC<DatePickerProps> = (props) => {
  const control = props.formApi.control;
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          control={control}
          name={props.name}
          defaultValue={new Date()}
          render={({ field: { onChange, value } }) => (
            <KeyboardDatePicker
              disabled={props.disabled}
              disableToolbar
              fullWidth
              variant="dialog"
              inputVariant="outlined"
              className="mb-2"
              format={props.format || "dd/MM/yyyy"}
              margin="none"
              label={props.label}
              value={value}
              onChange={onChange}
              invalidDateMessage={() => <></>}
            />
          )}
        ></Controller>
      </MuiPickersUtilsProvider>
      <FormItemError name={props.name} formApi={props.formApi} />
    </>
  );
};

export default AppDatePicker;
