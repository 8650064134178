import { FC } from "react";
import get from "lodash/get";

const FormItemError: FC<{
  name: string;
  formApi: any;
}> = (props) => {
  const error = get(props.formApi.formState.errors, props.name);
  if (!error?.message) {
    return null;
  }
  return <p className="form-item-error">{error.message}</p>;
};
export default FormItemError;
