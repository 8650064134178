import React, { FC, Fragment, ReactNode, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import isBoolean from "lodash/isBoolean";
import isPlainObject from "lodash/isPlainObject";
import trim from "lodash/trim";
import { Chip, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {
  getQuestionLabel,
  printDate,
  printDateTime,
  printTime,
} from "../../lib/utils";
import { StringMap, TOptions } from "i18next";
import VisitValueEditor, { VisitValueEditorProps } from "./VisitValueEditor";

export type VisitValueProps = {
  name?: string;
  label?: string;
  i18nPrefix?: string;
  i18nAbsPath?: string;
  source: any;
  datePath?: string;
  formApi?: any;
  values?: readonly any[];
  numOnly?: boolean;
  itemLabel?: string | ((value: any) => string);
  itemValue?: string;
  br?: boolean;
  dateFormat?: "date" | "dateTime" | "time";
  editor?: VisitValueEditorProps["editor"];
  editorOptions?: VisitValueEditorProps["editorOptions"];
  disabled?: boolean;
  printable?: boolean;
  labelGetter?: (value) => string | ReactNode;
  rawLabel?: boolean;
  renderer?: (date: string, element: ReactNode, setIsEditorOpen) => ReactNode;
  onSelected?: (value: any, rawValue: any) => void;
};

const dateFormaters = {
  date: printDate,
  dateTime: printDateTime,
  time: printTime,
};

const VisitValue: FC<
  VisitValueProps & {
    path: string;
  }
> = ({
  name,
  label,
  path,
  i18nPrefix,
  i18nAbsPath,
  source,
  datePath = "visitedAt",
  formApi,
  values,
  numOnly,
  itemLabel,
  itemValue,
  br,
  dateFormat,
  editor,
  editorOptions,
  disabled,
  printable,
  labelGetter,
  renderer,
  onSelected,
}) => {
  name = name || path.split(".").pop();
  const { t } = useTranslation();
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  let value: any = !path ? source : get(source, path);
  if (typeof value === "undefined") {
    value = null;
  }

  const resolveObjectLabel = (value) => {
    if (typeof itemLabel === "function") {
      return itemLabel(value);
    }

    return get(value, itemLabel || "label");
  };

  const getLabel = (value) => {
    /* if (typeof value === "undefined") {
      return "";
    } */
    if (typeof value === "undefined" || value === null) {
      return printable ? "" : Boolean(source["@id"]) ? "Vide" : "XX";
    }

    if (labelGetter) {
      return labelGetter(value);
    }

    if (isBoolean(value)) {
      return t(`common.bool.${value.toString()}`);
    }

    if (dateFormat || value instanceof Date) {
      return dateFormaters[dateFormat || "dateTime"](value, value);
    }

    if (typeof value === "string") {
      if (numOnly) {
        return values.indexOf(value) + 1;
      }
      const options: TOptions<StringMap> = {};
      if (value === "undeterminable") {
        options.defaultValue = t("common.undeterminable");
      }
      if (i18nAbsPath) {
        const translated = t(`${i18nAbsPath}.${value}`, options);
        return printable ? translated : getQuestionLabel(translated);
      }
      const label = !i18nPrefix
        ? value
        : t(`${i18nPrefix}.${name}.${value}`, options);

      return printable ? label : getQuestionLabel(label);
    }

    if (isPlainObject(value)) {
      return getQuestionLabel(resolveObjectLabel(value));
    }

    if (Array.isArray(value)) {
      return value
        .map((item) => {
          if (numOnly) {
            return values.indexOf(item) + 1;
          }
          if (isPlainObject(item)) {
            return resolveObjectLabel(item);
          }
          if (i18nAbsPath) {
            return t(`${i18nAbsPath}.${item}`);
          }
          return !i18nPrefix ? item : t(`${i18nPrefix}.${name}.${item}`);
        })
        .map((str, index) => {
          return (
            <Fragment key={index}>
              {br ? "• " : ""}
              {getQuestionLabel(str)}
              {index >= value.length - 1 ? null : br ? <br /> : " | "}
            </Fragment>
          );
        });
    }

    return value.toString();
  };

  const getValue = (value) => {
    if (isPlainObject(value)) {
      // TODO support return the whole object
      value = get(value, itemValue || "value");
    }
    if (isPlainObject(value)) {
      value = { ...value };
    }
    if (Array.isArray(value)) {
      return [...value];
    }
    return value;
  };

  const hasEditor = !disabled && Boolean(editor) && Boolean(source["@id"]);

  const renderPrintable = () => {
    const initialValue = !path
      ? source.initialData
      : get(source, `initialData.${path}`);
    const curLabel: string = getLabel(value);
    const initLabel: string = getLabel(initialValue);
    const curLabelStr = React.isValidElement(curLabel)
      ? renderToStaticMarkup(curLabel)
      : curLabel;
    const initLabelStr = React.isValidElement(initLabel)
      ? renderToStaticMarkup(initLabel)
      : initLabel;
    const wasEdited: boolean = trim(curLabelStr) !== trim(initLabelStr);
    if (!curLabel && !initLabel && !hasEditor) {
      return <span className="visit-value-printable-empty"></span>;
    }
    return (
      <span
        className={hasEditor ? "bg-light px-2 py-1 d-inline-block" : ""}
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          hasEditor && setIsEditorOpen(true);
        }}
      >
        {wasEdited ? initLabel : curLabel}{" "}
        {wasEdited && <span className="text-danger">{curLabel}</span>}
      </span>
    );
  };

  const renderChip = () => {
    const chipProps = {};
    if (hasEditor) {
      Object.assign(chipProps, {
        onDelete: () => setIsEditorOpen(true),
        deleteIcon: <EditIcon />,
      });
    }
    if ((formApi || onSelected) && !disabled) {
      Object.assign(chipProps, {
        onClick: () => {
          const val = getValue(value);
          if (formApi) {
            formApi.setValue(name, val, {
              shouldDirty: true,
            });
          }
          onSelected?.(val, value);
        },
      });
    }
    return (
      <Chip
        label={getLabel(value)}
        className="me-2 mb-2 chip-visit-value"
        variant={formApi ? "default" : "outlined"}
        {...chipProps}
      />
    );
  };

  return (
    <>
      {!Boolean(renderer) &&
        (printable ? (
          renderPrintable()
        ) : (
          <Tooltip title={printDate(get(source, datePath))} placement="bottom">
            {renderChip()}
          </Tooltip>
        ))}
      {Boolean(renderer) && (
        <>
          {renderer(
            printDate(get(source, datePath)),
            printable ? renderPrintable() : renderChip(),
            setIsEditorOpen
          )}
        </>
      )}

      {hasEditor && isEditorOpen && (
        <VisitValueEditor
          {...{
            isOpen: isEditorOpen,
            onClose: () => setIsEditorOpen(false),
            getLabel,
            path,
            i18nAbsPath: i18nAbsPath || `${i18nPrefix}.${name}`,
            source,
            value,
            values,
            editor,
            editorOptions,
            datePath,
            label,
          }}
        />
      )}
    </>
  );
};

export default VisitValue;
