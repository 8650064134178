import { FC } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

export type ErrorDialogProps = {
  isOpen: boolean;
  title?: string;
  message: string;
  handleClose?: () => void;
};

const ErrorDialog: FC<ErrorDialogProps> = (props) => {
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle id="simple-dialog-title">
        {props.title || "Erreur de saisie"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
