import { FC, useEffect } from "react";
import get from "lodash/get";
import set from "lodash/set";
import isNil from "lodash/isNil";
import { printDate, printDateTime, printTime } from "../../lib/utils";
import VisitValue, { VisitValueProps } from "./VisitValue";
import { VisitValueEditorProps } from "./VisitValueEditor";
import { IVisit } from "../../interfaces";

export type VisitValuesProps = {
  name?: string;
  label?: string;
  i18nPrefix?: string;
  i18nAbsPath?: string;
  visits?: IVisit[];
  sources: any[];
  datePath: string;
  visitPath?: string;
  formApi?: any;
  values?: readonly any[];
  numOnly?: boolean;
  itemLabel?: string | ((value: any) => string);
  itemValue?: string;
  br?: boolean;
  dateFormat?: VisitValueProps["dateFormat"];
  editor?: VisitValueEditorProps["editor"];
  editorOptions?: VisitValueEditorProps["editorOptions"];
  disabled?: boolean;
  direction?: "row" | "column";
  valueRenderer?: VisitValueProps["renderer"];
  valueLabelGetter?: (value: any) => string;
  onValueSelected?: (value: any, rawValue: any) => void;
  disableAutoMerge?: boolean;
};

const dateFormaters = {
  date: printDate,
  dateTime: printDateTime,
  time: printTime,
};

const VisitValues: FC<
  VisitValuesProps & {
    path: string;
  }
> = ({
  name,
  label,
  i18nPrefix,
  i18nAbsPath,
  visits,
  sources,
  path,
  datePath,
  visitPath,
  formApi,
  values,
  numOnly,
  itemLabel,
  itemValue,
  br,
  dateFormat,
  editor,
  editorOptions,
  disabled,
  direction = "row",
  valueRenderer,
  valueLabelGetter,
  onValueSelected,
  disableAutoMerge,
}) => {
  name = name || path.split(".").pop();

  useEffect(() => {
    if (!disableAutoMerge && formApi && isNil(formApi.getValues(name))) {
      let value: any = get(sources?.[0], path);
      if (!isNil(value)) {
        formApi.setValue(name, value);
      }
    }
  }, []);

  if (!sources) {
    return <div />;
  }

  const visitValues: any[] = !visits
    ? sources
    : visits.map((visit) => {
        const source = sources.find((source) => {
          return get(source, visitPath)?.["@id"] === visit["@id"];
        });
        if (source) {
          return source;
        }
        return set({}, datePath, visit.visitedAt);
      });

  return (
    <div className={`visit-values visit-values-${direction}`}>
      {visitValues.map((source, index) => {
        return (
          <div
            key={index}
            className={direction === "row" ? "d-inline-block" : "d-block"}
          >
            <VisitValue
              {...{
                path,
                name,
                i18nPrefix,
                i18nAbsPath,
                source,
                datePath,
                formApi,
                values,
                numOnly,
                itemLabel,
                itemValue,
                br,
                dateFormat,
                editor,
                editorOptions,
                label,
                disabled,
                renderer: valueRenderer,
                labelGetter: valueLabelGetter,
                onSelected: onValueSelected,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default VisitValues;
