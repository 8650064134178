export const REPORT_ALERTS = ["nbDaysToNotify", "nbAnimalsToNotify"] as const;
export const REPORT_STATUS = [
  "draft",
  "ready",
  "current",
  "send_to_asp",
  "in_progress_asp",
  "paid",
  "refused",
  "additional_compensation",
  "additional_payment",
] as const;
export const REPORT_DAMAGE_PRECISION = ["sure", "presumed", "unknown"] as const;
export const REPORT_DAMAGE_MOMENT = ["day", "night", "unknown"] as const;
export const REPORT_ENVIRONMENT_ACCESS = [
  "open",
  "semi_open",
  "closed",
] as const;
export const REPORT_SCARE_WAYS = [
  "olfactory",
  "sound",
  "non_lethal_shot",
  "visual",
] as const;

export const USER_ROLES = ["admin", "mobile", "expert"] as const;

export const REPORT_SCAVENGERS = [
  "fox",
  "badger",
  "wild_boar",
  "corvidae",
  "vulture",
  "other",
] as const;

export const REPORT_FRESHNESS = [
  "no_maggot",
  "maggot_lt_2mm",
  "maggot_gt_2mm",
  "undeterminable",
] as const;

export const REPORT_PREDATORS = ["wolf", "bear", "lynx"] as const;

export const REPORT_EXPERTISE_CAUSE = [
  "unknown",
  "predation",
  "not_predation",
] as const;

export const REPORT_DECISION_LIGHT_CARE = 100;

export const REPORT_DECISION_INITIAL = [
  "waiting",
  "refuse",
  "compensation",
] as const;

export const REPORT_DECISION_AFTER_RECOURSE = [
  null,
  "refuse",
  "compensation",
] as const;

export const REPORT_ANIMALS_LEAD = [
  "outside_enclosure",
  "mobile_net",
  "permanent_enclosure",
] as const;

export const VISIT_MEET_ROLE = ["breeder", "representative"] as const;

export const BENEFICIARY_HERD_COLS = [
  "ovine",
  "caprine",
  "bovine",
  "equine",
  "other",
] as const;

export const BENEFICIARY_MATERIAL_DAMAGES = [
  "enclosure",
  "fence",
  "other",
] as const;

export const OBS_APPROX_WEIGHT = ["lt10", "10to90", "gt90"] as const;

export const OBS_HEALTH = ["dead", "hurt", "will_dead", "euthanized"] as const;

export const PREFECT_GENDER_VALUES = ["male", "female"] as const;

export const OBS_COMPENSABLE_HEALTHES = ["dead", "will_dead", "euthanized"];

export const OBS_CONSUMMED_PARTS = [
  "none",
  "all_flesh",
  "neck",
  "forelimb",
  "hind_limb",
  "viscus_red",
  "viscus_white",
  "teat",
  "chest_flesh",
  "back",
  "side",
  "around_orifice",
] as const;

export const OBS_CONSUMPTION_WAYS = [
  "covered_by_scratching",
  "long_broken_bone",
  "open_chest_missing_sternum",
  "scraggy_bone_anatomic_connection",
  "open_abdomen_intact_rumen_or_content_nearby",
] as const;

export const OBS_PERFORATION = [
  "none",
  "bleeding",
  "no_bleeding",
  "undeterminable",
] as const;

export const OBS_PERFORATION_NB = [
  "eq1",
  "2to6",
  "gt6",
  "undeterminable",
] as const;

export const OBS_PERFORATION_DEPTH = [
  "lt10mm",
  "gt10mm",
  "undeterminable",
] as const;

export const OBS_PERFORATION_DIAMETER = [
  "lt3mm",
  "gt3mm",
  "undeterminable",
] as const;

export const OBS_TRACHAE_IMPACT = [
  "none",
  "pierced_crushed_cut",
  "undeterminable",
] as const;

export const OBS_LACERATION = ["bleeding", "no_bleeding"] as const;

export const OBS_NO_PIERCED_ZONE_VIGILANCE = [
  "bleeding",
  "shining_red_span",
  "bruise_no_external_lesion",
] as const;

export const VICTIM_CAUSE = ["direct", "fall", "suffocated", "other"] as const;

export const ANIMAL_GROUPS = [
  { name: "bovine", pattern: "^B.+" },
  { name: "canine", pattern: "^CA.+" },
  { name: "goat", pattern: "^C[1-9].*" },
  { name: "equine", pattern: "^E.+" },
  { name: "ovine", pattern: "^O.+" },
  { name: "apiary", pattern: "^R.+" },
  { name: "other", pattern: ".*" },
] as const;

export const DECLARATION_MOMENT = ["day", "night"] as const;

export const DECLARATION_ANIMALS_LEAD = ["move", "sleep"] as const;

export const REPORT_PREDATOR_SIGN = [
  "visual",
  "hairs",
  "excrement",
  "footPrint",
  "other",
] as const;

export const DECLARATION_ENCLOSURE_STRUCTURE = ["fixed", "mobile"] as const;

export const DECLARATION_ENCLOSURE_TYPE = [
  "fully_electrified",
  "not_electrified",
] as const;

export const DECLARATION_HUMAN_PRESENCE_NEAR = [
  "breeder",
  "shepherd",
  "help_shepherd",
  "none",
] as const;

export const DECLARATION_HUMAN_PRESENCE_RATE = [
  "day",
  "night",
  "daily",
  "irregular",
] as const;

export const DECLARATION_WEATHER = [
  "sun",
  "cloud",
  "fog",
  "rain",
  "snow",
] as const;

export const DECLARATION_ENVIRONMENT_TYPE = [
  "pasture",
  "heath",
  "wood",
  "bocage",
] as const;

export const ADDITIONAL_AMOUNT_REASON = [
  "animal_disappear",
  "amount_scale_change",
  "late_recourse",
] as const;

export const ADDITIONAL_AMOUNT_TREASURY_DECISION = [
  "compensation",
  "refuse",
] as const;
