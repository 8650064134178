import Dialog from "@material-ui/core/Dialog";
import { SignInForm } from "../form/SigninForm";

export default function SignInDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
    >
      <SignInForm
        onComplete={(result) => {
          if (result) {
            onClose(result);
          }
        }}
      />
    </Dialog>
  );
}
