import {
  IconButton,
  InputAdornment,
  TextareaAutosize,
} from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { FC } from "react";
import { Controller } from "react-hook-form";
import FormItemError from "./FormItemError";

type AppTextareaProps = {
  formApi;
} & TextFieldProps;

const AppTextarea: FC<AppTextareaProps> = (props) => {
  const control = props.formApi.control;
  const inputProps = props.inputProps || {};
  let InputLabelProps = props.InputLabelProps || {};
  InputLabelProps = { shrink: true, ...InputLabelProps };

  return (
    <>
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value } }) => {
          if (value == null) {
            value = "";
          }

          return (
            <TextField
              value={value}
              disabled={props.disabled}
              label={props.label}
              name={props.name}
              className="mb-2"
              InputLabelProps={InputLabelProps}
              inputProps={inputProps}
              variant={props.variant || "outlined"}
              fullWidth={props.fullWidth === false ? false : true}
              required={props.required}
              multiline
              rows={props.rows}
              onChange={(e) => {
                const value = e.target.value;
                onChange(value);
              }}
            />
          );
        }}
      ></Controller>
      <FormItemError name={props.name} formApi={props.formApi} />
    </>
  );
};
export default AppTextarea;
