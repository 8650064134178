import { useParams } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import { Button, Checkbox } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import AppCheckbox from "../components/form-input/AppCheckbox";
import {
  getBeneficiaryIndex,
  getBeneficiaryLabel,
  getVictimGroupName,
  getVictimIndex,
  getVictimTotalAmount,
} from "../lib/reportUtils";
import {
  IAnimalCategory,
  IBeneficiary,
  ICity,
  IDepartment,
  IReport,
  IReportBeneficiary,
  IReportVictim,
} from "../interfaces";
import { REPORT_DECISION_LIGHT_CARE } from "../constants";
import { printDateMediumFormat, currencyFormatter } from "../lib/utils";
import i18n from "../i18n";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

export default function InstructionLetterPage() {
  const { prvApi } = useApi();
  const { t } = useTranslation();
  const [data, setData] = useState<IReportBeneficiary>(null);
  const [beneficiaryNum, setBeneficiaryNum] = useState(0);
  let { id } = useParams<{
    id: string;
  }>();

  const formApi = useForm();
  const [mode, setMode] = useState<"display" | "copy">("display");

  const beneficiary: IBeneficiary = data?.beneficiary;
  const report: IReport = data?.report as IReport;
  const city: ICity = report?.city as ICity;
  const department: IDepartment = city?.department as IDepartment;
  const [reportState, setReportState] = useState({
    circlePredators: {
      wolf: false,
      bear: false,
    },
    victims: [],
    victimsCategory: [],
    unprotectability: "",
    victimsHealth: [],
    daysBetweenReportedAtDamageAt: 0,
  });
  const [increaseYesChecked, setIncreaseYesChecked] = useState(false);
  const [increaseNeedProofChecked, setIncreaseNeedProofChecked] =
    useState(false);
  const [increaseNoChecked, setIncreaseNoChecked] = useState(false);

  const checkboxStyle = {
    padding: "9px 9px 9px 0",
  };

  const float2int = (value) => {
    return value | 0;
  };

  const isInt = (n) => {
    return Boolean(n % 1 === 0);
  };

  const isSecondVisitDelayRespected = () => {
    const difference =
      data.report?.visits[1]?.visitedAt.getTime() -
      data.report?.visits[0]?.visitedAt.getTime();
    const daysBetween = Math.ceil(difference / (1000 * 3600 * 24));
    if (data.report.visits[1] && daysBetween <= 7) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      const { data }: { data: IReportBeneficiary } = await prvApi.get(
        `/report_beneficiaries/${id}`
      );
      const { data: beneficiaries } = await prvApi.get(
        `/report_beneficiaries`,
        {
          params: {
            report: data.report.id,
          },
        }
      );
      const formData = {
        compensation: data.report.expertise?.isCompensableAttack,
        nocompensation: !data.report.expertise?.isCompensableAttack,
        cpi: data.report.expertise?.isCompensableAttack,
        nocpi: !data.report.expertise?.isCompensableAttack,
        protectionContract: data.report?.hasProtectionContract,
        protectionEq:
          (data.report.isProtectDog && data.report.isElectrifiedFence) ||
          (data.report.isProtectDog && data.report.isReinforcedGuarding) ||
          (data.report.isReinforcedGuarding && data.report.isElectrifiedFence)
            ? true
            : false,
        protectionExemption: false,
      };
      if (!formData.protectionContract && !formData.protectionEq) {
        formData.protectionExemption = !data.reportDecision?.isAttackRefused;
      }
      formApi.reset(formData);
      setData(data);
      setBeneficiaryNum(getBeneficiaryIndex(data, beneficiaries) + 1);
      const reportedDate = new Date(data.report?.reportedAt);

      let victims = [...data.victims];
      if (!data.reportDecision?.isCompensationIndividualised) {
        data.children?.forEach((child) => {
          victims = [...victims, ...child.victims];
        });
      }

      setReportState({
        circlePredators: {
          wolf: data.report?.circlePredators?.includes("wolf"),
          bear: data.report?.circlePredators?.includes("bear"),
        },
        victims,
        victimsCategory: victims?.map((victim) => {
          return (victim.category as IAnimalCategory)?.id;
        }),
        unprotectability: data.report?.isNotProtectable
          ? "notProtectable"
          : data.report.victims.some((victim) =>
              ["ovine", "goat"].includes(getVictimGroupName(victim))
            )
          ? "notConcerned"
          : "",
        victimsHealth: victims?.map((victim) => {
          return victim.health;
        }),
        daysBetweenReportedAtDamageAt: isInt(
          (reportedDate.getTime() - new Date(data.report?.damageAt).getTime()) /
            (24 * 60 * 60 * 1000)
        )
          ? (reportedDate.getTime() -
              new Date(data.report?.damageAt).getTime()) /
            (24 * 60 * 60 * 1000)
          : float2int(
              (reportedDate.getTime() -
                new Date(data.report?.damageAt).getTime()) /
                (24 * 60 * 60 * 1000)
            ),
      });
    })();
  }, [id]);

  const onUnprotectabilityChange = (value: string) => {
    setReportState({ ...reportState, unprotectability: value });
  };

  useEffect(() => {
    const fmt = /.+(a|b|c|d)$/g;
    setIncreaseNoChecked(
      !Boolean(
        reportState?.victims
          ?.filter((victim) => victim.isApproved)
          .find((victim) => {
            return Boolean(
              (victim.category as IAnimalCategory)?.id?.match(fmt)
            );
          })
      )
    );
  }, [reportState]);

  useEffect(() => {
    (async () => {})();
  }, [id]);

  const copy = () => {
    setMode("copy");
  };

  useEffect(() => {
    if (mode === "copy") {
      const range = new Range();
      const selection = window.getSelection();
      selection.removeAllRanges();
      const div = document.getElementById("main");
      if (div) {
        range.setStart(div, 2);
        range.setEnd(div, 10);
        selection.addRange(range);
        document.execCommand("copy");
        setMode("display");
      }
    }
  }, [mode]);

  const print = () => {
    window.print();
  };

  const values = formApi.getValues();

  if (!data) {
    return <div />;
  }

  const flatRateDisappear: number = data.reportDecision?.isFlatRateDisappear
    ? data.reportDecision?.flatRateDisappear
    : 0;

  return (
    <div className="mainStyle" id="main">
      <Button
        variant="contained"
        color="primary"
        onClick={copy}
        className="hidden-print"
      >
        Copier
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={print}
        className="hidden-print ms-2"
      >
        Imprimer
      </Button>
      <table className="tableStyle">
        <tbody>
          <tr>
            <td align="left" colSpan={2} className="itemTable">
              <img src="/logo_marianne.png" alt="edition" />
              <br />
              {t(
                `department.metaData.prefect.label_${
                  department.metaData?.prefect?.gender || "male"
                }`,
                {
                  departmentArticle: department.metaData?.article,
                  departmentLabel: department.label,
                }
              )}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              <img src="/bloc.png" alt="edition" className="blocStyle" />
            </td>
          </tr>
          <tr>
            <td colSpan={2} height="10px"></td>
          </tr>
        </tbody>
      </table>
      <table width="100%">
        <tbody>
          <tr>
            <td className="titleLetter">
              Fiche d'instruction <br /> Compensation des attaques
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <table className="tableInstruction">
        <tbody>
          <tr>
            <td style={{ border: "1px solid #EE7F01", padding: "3px" }}>
              <u>Constat n°:</u> {report.ref}-{beneficiaryNum}
              <br />
              <br />
              <u>du:</u>
              {` ${printDateMediumFormat(
                report.visits[0]?.visitedAt,
                "XX/XX/XXXX"
              )}`}
            </td>
            <td style={{ border: "1px solid #EE7F01", padding: "3px" }}>
              <u>Nom de l'éleveur</u>
              <br />
              <br />
              {getBeneficiaryLabel(beneficiary)}
            </td>
            <td style={{ border: "1px solid #EE7F01", padding: "3px" }}>
              <u>Commune</u>
              <br />
              <br />
              {city.label}
              <br />{" "}
            </td>
          </tr>
          <tr>
            <td
              className="t_fiche_bg"
              colSpan={3}
              style={{
                border: "1px solid #EE7F01",
                padding: "3px",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Données administratives
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <table className="itemInstruction">
                <tbody>
                  <tr>
                    <td>
                      <b>Commune en cercle 1 ou cercle 0 : </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="itemInstruction">
                <tbody>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={Boolean(reportState.circlePredators?.wolf)}
                            name="circlePredators.wolf"
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [{reportState.circlePredators?.wolf ? "x" : " "}]
                        </span>
                      )}
                      Loup
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={Boolean(reportState.circlePredators?.bear)}
                            name="circlePredators.bear"
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [{reportState.circlePredators.bear ? "x" : " "}]
                        </span>
                      )}
                      Ours
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="itemInstruction">
                <tbody>
                  <tr>
                    <td>
                      <b>Si prédation par le lynx pressentie :</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Les attaques sont répétées (5 sur 2 ans)</td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="repeat_true" />
                        </>
                      ) : (
                        <span>[{values.repeat_true ? "x" : " "}]</span>
                      )}
                      Oui
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="repeat_false" />
                        </>
                      ) : (
                        <span>[{values.repeat_false ? "x" : " "}]</span>
                      )}
                      Non
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td>
                      <b>Victimes :</b>(plusieurs réponses possibles)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="ovins"
                            checked={
                              !isNil(
                                reportState.victimsCategory?.find((cat) =>
                                  cat?.startsWith("O")
                                )
                              )
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsCategory?.find((cat) =>
                              cat?.startsWith("O")
                            )
                          )
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      ovins
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="caprins"
                            checked={
                              !isNil(
                                reportState.victimsCategory?.find(
                                  (cat) =>
                                    cat?.startsWith("C") &&
                                    !cat?.startsWith("CA")
                                )
                              )
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsCategory?.find((cat) =>
                              cat?.startsWith("C")
                            )
                          )
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      caprins
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="bovins"
                            checked={
                              !isNil(
                                reportState.victimsCategory?.find((cat) =>
                                  cat?.startsWith("B")
                                )
                              )
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsCategory?.find((cat) =>
                              cat?.startsWith("B")
                            )
                          )
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      bovins
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="equins"
                            checked={
                              !isNil(
                                reportState.victimsCategory?.find((cat) =>
                                  cat?.startsWith("E")
                                )
                              )
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsCategory?.find((cat) =>
                              cat?.startsWith("E")
                            )
                          )
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      équins
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="ruches"
                            checked={
                              !isNil(
                                reportState.victimsCategory?.find((cat) =>
                                  cat?.startsWith("R")
                                )
                              )
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsCategory?.find((cat) =>
                              cat?.startsWith("R")
                            )
                          )
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      ruches
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="autres" />
                        </>
                      ) : (
                        <span>[{values.autres ? "x" : " "}]</span>
                      )}
                      autres :
                      .............................................................................................................
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td>
                      <b>Protection des troupeaux : </b>(une seule réponse
                      possible)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <AppCheckbox
                          formApi={formApi}
                          name="protectionContract"
                        />
                      ) : (
                        <span>[{values.protectionContract ? "x" : " "}]</span>
                      )}
                      contrat(s) FEADER souscrit(s){" "}
                      {(data.report.isProtectDog &&
                        data.report.isElectrifiedFence) ||
                      (data.report.isProtectDog &&
                        data.report.isReinforcedGuarding) ||
                      (data.report.isReinforcedGuarding &&
                        data.report.isElectrifiedFence)
                        ? ""
                        : "dans les 5 années précédentes"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b style={{ color: "#FFFFFF" }}>-</b>n° OSIRIS :{" "}
                      {beneficiary.protectionContract}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <AppCheckbox formApi={formApi} name="credit" />
                      ) : (
                        <span>[{values.credit ? "x" : " "}]</span>
                      )}
                      crédits d'urgence (mise en place attestée par la DDT)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <AppCheckbox formApi={formApi} name="protectionEq" />
                      ) : (
                        <span>[{values.protectionEq ? "x" : " "}]</span>
                      )}
                      protection équivalente non financée (mise en place
                      attestée par la DDT)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <AppCheckbox
                          formApi={formApi}
                          name="protectionExemption"
                        />
                      ) : (
                        <span>[{values.protectionExemption ? "x" : " "}]</span>
                      )}
                      dérogation : hors cercle 1 ou moins de 3 attaques sur les
                      12 derniers mois
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>
                        Non-protégeabilité / Zone Difficilement Protégeable :{" "}
                      </b>
                      (une seule réponse possible)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              reportState.unprotectability === "notProtectable"
                            }
                            name="nonprotegeable"
                            style={checkboxStyle}
                            color="primary"
                            onChange={(e, checked) => {
                              onUnprotectabilityChange(
                                checked ? "notProtectable" : ""
                              );
                            }}
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {reportState.unprotectability === "notProtectable"
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      troupeau reconnu comme non-protégeable
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={reportState.unprotectability === "zdp"}
                            name="zdp"
                            style={checkboxStyle}
                            color="primary"
                            onChange={(e, checked) => {
                              onUnprotectabilityChange(checked ? "zdp" : "");
                            }}
                          />
                        </>
                      ) : (
                        <span>
                          [{reportState.unprotectability === "zdp" ? "x" : " "}]
                        </span>
                      )}
                      commune concernée par l'arrêté ZDP
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              reportState.unprotectability === "notConcerned"
                            }
                            style={checkboxStyle}
                            color="primary"
                            onChange={(e, checked) => {
                              onUnprotectabilityChange(
                                checked ? "notConcerned" : ""
                              );
                            }}
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {reportState.unprotectability === "notConcerned"
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      non concerné
                    </td>
                  </tr>
                  {/* <tr></tr>
              <tr><td><b>Protection des troupeaux : dérogation si moins de 3 attaques par an</b></td></tr>
              <tr><td><input type="checkbox"  />oui</td></tr>
              <tr><td><input type="checkbox"  />non</td></tr> */}
                  <tr></tr>
                  <tr>
                    <td>
                      <b>Majoration bio / label / circuit court... </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={increaseYesChecked}
                            style={checkboxStyle}
                            color="primary"
                            name="justif"
                            onChange={(e) => {
                              setIncreaseYesChecked(e.target.checked);
                            }}
                          />
                        </>
                      ) : (
                        <span>[{increaseYesChecked ? "x" : " "}] </span>
                      )}
                      oui (fournir le justificatif)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b style={{ color: "#FFFFFF" }}> -</b>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={increaseNeedProofChecked}
                            style={checkboxStyle}
                            color="primary"
                            name="justifcurrent"
                            onChange={(e) => {
                              setIncreaseNeedProofChecked(e.target.checked);
                            }}
                          />
                        </>
                      ) : (
                        <span>[{increaseNeedProofChecked ? "x" : " "}] </span>
                      )}
                      justificatifs déjà fournis dans l'année civile en cours
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={increaseNoChecked}
                            style={checkboxStyle}
                            color="primary"
                            name="nojustif"
                            onChange={(e) => {
                              setIncreaseNoChecked(e.target.checked);
                            }}
                          />
                        </>
                      ) : (
                        <span>[{increaseNoChecked ? "x" : " "}] </span>
                      )}
                      non
                    </td>
                  </tr>
                  <tr></tr>

                  {/* <tr><td><b>Majoration de 20% animaux disparus - dérogation pour animaux maintenus en parc clos :</b></td></tr>
              <tr><td><input type="checkbox"  />oui si parc n'a pas conservé son intégrité lors de l'attaque</td></tr>
              <tr><td><input type="checkbox"  />oui si parc conçu pour éviter l'étouffement</td></tr>
              <tr ><td><input type="checkbox"  />non</td></tr> */}

                  <tr>
                    <td>
                      <b>
                        Motif de versement du forfait animaux disparus (FAD) -
                        Les animaux sont-ils tenus en parc clos ? :
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="fad1" />
                        </>
                      ) : (
                        <span>[{values.fad1 ? "x" : " "}]</span>
                      )}
                      Oui mais le parc n'a pas conservé son intégrité lors de
                      l'attaque
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="fad2" />
                        </>
                      ) : (
                        <span>[{values.fad2 ? "x" : " "}]</span>
                      )}
                      Oui mais le parc est conçu pour éviter l'étouffement
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="nofad" />
                        </>
                      ) : (
                        <span>[{values.nofad ? "x" : " "}]</span>
                      )}
                      Non
                    </td>
                  </tr>
                  <tr></tr>
                  {/* <tr>
                <td>
                  <b>
                    Prime de dérangement (pour prédation ours
                    seulement)
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  {mode !== "copy" ? (
                    <>
                      <AppCheckbox formApi={formApi} name="prime" />
                    </>
                  ) : (
                    <span>[{values.prime ? "x" : " "}]<br/></span>
                  )}
                  oui (jusqu'au 31/12/2020)
                </td>
              </tr>
              <tr>
                <td>
                  {mode !== "copy" ? (
                    <>
                      <AppCheckbox formApi={formApi} name="noprime" />
                    </>
                  ) : (
                    <span>[{values.noprime ? "x" : " "}]<br/></span>
                  )}
                  non
                </td>
              </tr>
              <tr></tr> */}
                  <tr>
                    <td>
                      <b>
                        Pronostic vital engagé donnant lieu à indemnisation sur
                        la base du barème :
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              !isNil(
                                reportState.victimsHealth?.find(
                                  (cat) => cat === "will_dead"
                                )
                              )
                                ? true
                                : false
                            }
                            name="pronostic"
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsHealth?.find(
                              (cat) => cat === "will_dead"
                            )
                          )
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      oui (voir éléments de calcul de l'indemnisation) :{" "}
                      {reportState.victims?.map((victim: IReportVictim) => {
                        if (victim.health === "will_dead") {
                          return (
                            <span key={victim["@id"]}>
                              {!isNil(victim.num)
                                ? ` n° ${victim.num} `
                                : "Animal non identifié"}
                            </span>
                          );
                        }
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="nopronostic"
                            checked={
                              !isNil(
                                reportState.victimsHealth?.find(
                                  (cat) => cat === "will_dead"
                                )
                              )
                                ? false
                                : true
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!isNil(
                            reportState.victimsHealth?.find(
                              (cat) => cat === "will_dead"
                            )
                          )
                            ? " "
                            : "x"}
                          ]
                        </span>
                      )}
                      non
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className="subtitleInstruction" colSpan={3}>
              Délais : décret art 2 et note III 2) et 3)
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <table className="itemInstruction">
                <tbody>
                  <tr>
                    <td>
                      <b>Déclaration de dommage effectuée </b>(une seule réponse
                      possible)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              reportState.daysBetweenReportedAtDamageAt <= 2
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                            name="minus48"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {reportState.daysBetweenReportedAtDamageAt <= 2
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      moins de 48 heures après l'attaque
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              reportState.daysBetweenReportedAtDamageAt <= 3 &&
                              reportState.daysBetweenReportedAtDamageAt > 2
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                            name="minus72"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {reportState.daysBetweenReportedAtDamageAt <= 3 &&
                          reportState.daysBetweenReportedAtDamageAt > 2
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      moins de 72 heures après l'attaque
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              reportState.daysBetweenReportedAtDamageAt > 3
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                            color="primary"
                            name="overdelay"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {reportState.daysBetweenReportedAtDamageAt > 3
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                      en dehors des délais ci-dessus
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        Constat complémentaire réclamé dans un délai d'une
                        semaine
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              isSecondVisitDelayRespected() ? true : false
                            }
                            name="constat"
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [{isSecondVisitDelayRespected() ? "x" : " "}]
                        </span>
                      )}
                      oui
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            name="noconstat"
                            checked={
                              isSecondVisitDelayRespected() ? false : true
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [{isSecondVisitDelayRespected() ? " " : "x"}]
                        </span>
                      )}
                      non
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              className="subtitleInstruction"
              colSpan={3}
              style={{
                textAlign: "center",
                border: "1px solid #EE7F01",
                padding: "3px",
              }}
            >
              Observations
            </td>
          </tr>
          <tr style={{ height: "30px" }}>
            <td colSpan={3}></td>
          </tr>

          <tr className="subtitleInstruction">
            <td colSpan={1}></td>
            {/* <td className="t_fiche_bg">Constat établi par</td> */}
            <td className="t_fiche_bg">Avis de l'expert</td>
            <td className="t_fiche_bg">Décision D.D.T.</td>
          </tr>

          <tr>
            <td colSpan={1}></td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td align="left">mortalité non liée à une prédation</td>
                    <td align="left">
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              data.report?.expertise?.cause === "not_predation"
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {data.report?.expertise?.cause === "not_predation"
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">mortalité d'origine indéterminée</td>
                    <td align="left">
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              data.report?.expertise?.cause === "unknown"
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {data.report?.expertise?.cause === "unknown"
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">mortalité liée à une prédation</td>
                    <td align="left">
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              data.report?.expertise?.cause === "predation"
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {data.report?.expertise?.cause === "predation"
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style={{ marginTop: "1em" }}>
                <thead>
                  <tr>
                    <td></td>
                    <td>Oui</td>
                    <td>Non</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="left">responsabilité du loup écartée</td>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={Boolean(
                              data.report?.expertise?.dismissedPredators?.wolf
                            )}
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {data.report?.expertise?.dismissedPredators?.wolf
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              !Boolean(
                                data.report?.expertise?.dismissedPredators?.wolf
                              )
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!data.report?.expertise?.dismissedPredators?.wolf
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">responsabilité de l'ours écartée</td>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={Boolean(
                              data.report?.expertise?.dismissedPredators?.bear
                            )}
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {data.report?.expertise?.dismissedPredators?.bear
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              !Boolean(
                                data.report?.expertise?.dismissedPredators?.bear
                              )
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!data.report?.expertise?.dismissedPredators?.bear
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">responsabilité du lynx écartée</td>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={Boolean(
                              data.report?.expertise?.dismissedPredators?.lynx
                            )}
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {data.report?.expertise?.dismissedPredators?.lynx
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                    <td>
                      {mode !== "copy" ? (
                        <>
                          <Checkbox
                            checked={
                              !Boolean(
                                data.report?.expertise?.dismissedPredators?.lynx
                              )
                            }
                            style={checkboxStyle}
                            color="primary"
                          />
                        </>
                      ) : (
                        <span>
                          [
                          {!data.report?.expertise?.dismissedPredators?.lynx
                            ? "x"
                            : " "}
                          ]
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <u>Compensation animaux</u>
                      <br />
                      OUI
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="compensation" />
                        </>
                      ) : (
                        <span>[{values.compensation ? "x" : " "}]</span>
                      )}
                      NON
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox
                            formApi={formApi}
                            name="nocompensation"
                          />
                        </>
                      ) : (
                        <span>[{values.nocompensation ? "x" : " "}]</span>
                      )}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <u>Compensation pertes indirectes</u>
                      <br />
                      OUI
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="cpi" />
                        </>
                      ) : (
                        <span>[{values.cpi ? "x" : " "}]</span>
                      )}
                      NON
                      {mode !== "copy" ? (
                        <>
                          <AppCheckbox formApi={formApi} name="nocpi" />
                        </>
                      ) : (
                        <span>[{values.nocpi ? "x" : " "}]</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table width="100%">
        <tbody>
          <tr>
            <td>
              <h4>Avis de l'expert</h4>
              {mode !== "copy" ? (
                <Controller
                  name="avis"
                  control={formApi.control}
                  defaultValue={report.expertise?.comment || ""}
                  render={({ field: { onChange, value } }) => {
                    value = value || "";
                    return (
                      <textarea
                        rows={5}
                        cols={50}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        style={{
                          border: "1px solid #EE7F01",
                          padding: "3px",
                        }}
                      ></textarea>
                    );
                  }}
                />
              ) : (
                <p>{values.avis}</p>
              )}
            </td>
            <td align="right">
              <div className="chp_signature">
                Date, cachet et signature de l'instructeur
                <br />
                <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Éléments de calcul de l'indemnisation</h4>
      <div>
        <table className="tableDamages" width="100%">
          <tbody>
            <tr>
              <td
                className="tableDamages ps-2"
                style={{ width: 50, minWidth: 50 }}
              >
                <b>#</b>
              </td>
              <td className="tableDamages" align="center">
                <b>Détail des dommages</b>
              </td>
              <td className="tableDamages" align="center">
                <b className="d-block" style={{ minWidth: 110 }}>
                  N° d'identification
                </b>
              </td>
              <td className="tableDamages" align="center">
                <b className="d-block" style={{ minWidth: 150 }}>
                  Victime retenue pour l'indemnisation
                </b>
              </td>
              <td className="tableDamages" align="center">
                <b className="d-block" style={{ minWidth: 100 }}>
                  Montant attribué
                </b>
              </td>
            </tr>
            {reportState.victims?.map((victim: IReportVictim) => {
              const category: IAnimalCategory =
                victim?.category as IAnimalCategory;
              return (
                <tr key={victim["@id"]}>
                  <td className="tableDamages ps-2" style={{ width: 50 }}>
                    {getVictimIndex(victim, data.report.victims) + 1}
                  </td>
                  <td className="tableDamages ps-2">
                    {category ? category.id : null}{" "}
                    {typeof victim.health !== "undefined"
                      ? i18n.t(`victim.health.${victim.health}`)
                      : "État de l'animal non défini"}
                  </td>
                  <td className="tableDamages ps-2">
                    {victim.num || "Non retrouvé"}
                  </td>
                  <td className="tableDamages ps-2">
                    {victim.isApproved
                      ? "Victime retenue"
                      : "Victime non retenue"}
                  </td>
                  <td className="tableDamages ps-2">
                    {getVictimTotalAmount(victim)
                      ? currencyFormatter.format(getVictimTotalAmount(victim))
                      : "0"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <br />
      </div>

      <table width="100%" style={{ fontSize: "12px" }}>
        <tbody>
          {!Boolean(data.parent) && (
            <tr>
              <td></td>
              <td align="right">Effectif du lot attaqué</td>
              <td align="right">
                {data.nbAnimals}/{(data.report as IReport).nbAnimals}
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td align="right">Forfait disparus</td>
            <td align="right">
              {flatRateDisappear
                ? currencyFormatter.format(flatRateDisappear)
                : "0,00 €"}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right">Total montants animaux</td>
            {/* <td align="right">Coûts directs</td> */}
            <td align="right">
              {data.reportDecision.directLosses
                ? currencyFormatter.format(data.reportDecision.directLosses)
                : "0,00 €"}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right">Forfait soins légers</td>
            <td align="right">
              {data.reportDecision.isLightCare
                ? currencyFormatter.format(REPORT_DECISION_LIGHT_CARE)
                : "0,00 €"}{" "}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right">CPI</td>
            <td align="right">
              {data.reportDecision.cpi
                ? currencyFormatter.format(data.reportDecision.cpi)
                : "0,00 €"}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right">Frais vétérinaires (dont euthanasie)</td>
            <td align="right">
              {data.reportDecision.euthanasiaCosts +
              data.reportDecision.veterinaryCosts
                ? currencyFormatter.format(
                    data.reportDecision.euthanasiaCosts +
                      data.reportDecision.veterinaryCosts
                  )
                : "0,00 €"}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right">Frais réparations</td>
            <td align="right">
              {data.reportDecision.materialCosts
                ? currencyFormatter.format(data.reportDecision.materialCosts)
                : "0,00 €"}
            </td>
          </tr>
          {/* <tr>
          <td></td>
          <td align="right">Autres montants</td>
          <td align="right">0,00 €</td>
        </tr> */}
          <tr>
            <td style={{ height: "40px" }}></td>
            <td align="right">
              <u>Total du dossier </u>
            </td>

            <td align="right">
              {data.reportDecision.totalToPay === 0 &&
              data.reportDecision.isAttackRefused
                ? currencyFormatter.format(data.reportDecision.totalToPay) +
                  " (attaque refusée)"
                : currencyFormatter.format(data.reportDecision.totalToPay)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
