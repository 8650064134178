import { Button, ButtonProps } from "@material-ui/core";
import { FC } from "react";
import { useHistory } from "react-router-dom";

export const RouterButton: FC<ButtonProps> = (props) => {
  const history = useHistory();
  return (
    <Button
      {...props}
      onClick={(e) => {
        if (e.ctrlKey) {
          return;
        }
        e.preventDefault();
        history.push(props.href);
        props.onClick?.(e);
      }}
    ></Button>
  );
};
