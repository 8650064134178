import React from "react";
import Button from "@material-ui/core/Button";
import { IconButton, Paper } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { UseFieldArrayReturn } from "react-hook-form";
import AppAutocomplete from "./AppAutocomplete";
import { getBeneficiaryLabel } from "../../lib/reportUtils";
import useApi from "../../lib/useApi";

const AppSelectMultiBenChildren: React.FC<{
  formApi: any;
  label?: string;
  name: string;
  fieldArray: UseFieldArrayReturn<any, any, any>;
}> = (props) => {
  const { prvApi } = useApi();

  const defaultBen = null;

  return (
    <>
      <div className="mb-2">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.fieldArray.prepend({ ...defaultBen });
          }}
        >
          Ajouter un éleveur
        </Button>
      </div>

      {props.fieldArray.fields.map((item, index) => {
        return (
          <Paper
            key={item.id}
            className={`form-item col-12 mt-2 pb-0 d-flex justify-content-between align-items-center ${
              !item["@id"] ? "p-2 pb-0" : ""
            }`}
          >
            {item["@id"] && (
              <div className="p-3">{getBeneficiaryLabel(item as any)}</div>
            )}
            {!item["@id"] && (
              <div className="flex-fill">
                <AppAutocomplete
                  name={`children.${index}`}
                  formApi={props.formApi}
                  getOptions={async (input) => {
                    const { data } = await prvApi.get(
                      "/beneficiaries?page=1&isCorporate=0&isCorporate%5Bnull%5D",
                      {
                        params: {
                          orstartwith_quick_search: input,
                        },
                      }
                    );
                    return data.map((option) => {
                      return {
                        ...option,
                        label: getBeneficiaryLabel(option),
                      };
                    });
                  }}
                  renderOption={(option) => {
                    return (
                      <div className="row w-100">
                        <div className="col-2">{option.id}</div>
                        <div className="col-4">{option.label}</div>
                        <div className="col-3">{option.pacage}</div>
                        <div className="col-3">{option.siret}</div>
                      </div>
                    );
                  }}
                />
              </div>
            )}
            <IconButton
              color="primary"
              onClick={() => props.fieldArray.remove(index)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Paper>
        );
      })}
    </>
  );
};

export default AppSelectMultiBenChildren;
