import { useParams, Prompt, Link as RouterLink } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IReport, IVisit, IVisitBeneficiary } from "../interfaces";
import { useForm } from "react-hook-form";
import SaveIcon from "@material-ui/icons/Save";
import { Breadcrumbs, Fab, Link, Typography } from "@material-ui/core";
import pick from "lodash/pick";
import { getBeneficiaryLabel, isReportDisabled } from "../lib/reportUtils";
import VisitBeneficiaryForm from "../components/form/VisitBeneficiaryForm";

export default function VisitBeneficiaryPage() {
  const { prvApi, handleError } = useApi();
  const [beneficiary, setBeneficiary] = useState<IVisitBeneficiary>(null);
  const formApi = useForm();
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const { isDirty: hasInteracted } = formApi.formState;

  useEffect(() => {
    if (!id) {
      return;
    }
    (async () => {
      const { data } = await prvApi.get(`/visit_beneficiaries/${id}`);
      formApi.reset({
        ...data,
      });
      setBeneficiary(data);
    })();
  }, [id]);

  if (!beneficiary) {
    return <div />;
  }

  const report: IReport = (beneficiary.visit as IVisit).report as IReport;
  const isDisabled = isReportDisabled(report);

  const onSubmit = async () => {
    const data = formApi.getValues() as IVisitBeneficiary;
    const dto = {
      ...pick(data, [
        "parentId",
        "pacage",
        "siret",
        "protectionContract",
        "isCorporate",
        "corporateName",
        "firstName",
        "lastName",
        "email",
        "isAgreeForEmail",
        "phone",
        "address",
        "zipCode",
        "city",
        "hamlet",
      ]),
    } as IVisitBeneficiary;

    try {
      await prvApi.patch(`/visit_beneficiaries/${data.id}`, dto);
      formApi.reset(data);
      history.push(`/reports/${report.id}/beneficiaries`);
    } catch (error) {
      handleError(error, formApi);
    }
  };

  return (
    <div>
      <Prompt
        when={hasInteracted}
        message={(location) => {
          return "Quitter la page sans enregistrer ?";
        }}
      />
      <form onSubmit={formApi.handleSubmit(onSubmit)} className="pb-5">
        <div className="container">
          <Breadcrumbs separator="›" className="my-4">
            <Link component={RouterLink} to={`/reports/${report.id}`}>
              Dossier {report.ref}
            </Link>
            <Link
              component={RouterLink}
              to={`/reports/${report.id}/beneficiaries`}
            >
              Bénéficiaires
            </Link>
            <Typography>{getBeneficiaryLabel(beneficiary)}</Typography>
          </Breadcrumbs>
          <VisitBeneficiaryForm formApi={formApi} isDisabled={isDisabled} />
        </div>
        {!isDisabled && (
          <Fab className="fab-bottom-right" color="primary" type="submit">
            <SaveIcon />
          </Fab>
        )}
      </form>
    </div>
  );
}
