import { FC, ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import AppRadioGroup from "../form-input/AppRadioGroup";
import { useForm } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import useApi from "../../lib/useApi";
import { printDate } from "../../lib/utils";
import AppDatePicker from "../form-input/AppDatePicker";
import AppCheckbox from "../form-input/AppCheckbox";
import AppCheckboxes from "../form-input/AppCheckboxes";
import AppTextField from "../form-input/AppTextField";
import AppSelect from "../form-input/AppSelect";
import { isArray, isPlainObject, trim } from "lodash";
import AppTextarea from "../form-input/AppTextarea";
import { useTranslation } from "react-i18next";

export type VisitValueEditorProps = {
  label?: string;
  datePath: string;
  isOpen: boolean;
  i18nAbsPath?: string;
  source: any;
  value: any;
  values?: readonly any[];
  editor:
    | "date"
    | "daterange"
    | "radio"
    | "checkbox"
    | "checkboxes"
    | "textfield"
    | "textarea"
    | "strings"
    | "select"
    | "coords";
  editorOptions?: {
    children?: ReactNode[] | ((source: any) => ReactNode[]);
    type?: string;
    separator?: string;
    separatorHelper?: string;
  };
  onClose: () => void;
  getLabel: (value: any) => string;
};

const ARRAY_EDITOR_SEPARATOR = ",";
const ARRAY_EDITOR_SEPARATOR_HELPER = "comma";

const VisitValueEditor: FC<
  VisitValueEditorProps & {
    path: string;
  }
> = ({
  label,
  datePath,
  isOpen,
  path,
  i18nAbsPath,
  source,
  value,
  values,
  onClose,
  getLabel,
  editor,
  editorOptions,
}) => {
  const { resApi, handleError } = useApi();
  const { t } = useTranslation();
  const defaultValues = {};
  if (!value) {
    set(defaultValues, path, value);
  } else if (editor == "coords") {
    set(defaultValues, path, [value[0], value[1]]);
  } else if (editor == "daterange") {
    set(defaultValues, path, { ...value });
  } else if (editor == "strings") {
    set(
      defaultValues,
      path,
      value.join(editorOptions?.separator || ARRAY_EDITOR_SEPARATOR + " ")
    ); // TODO support separator ""
  } else {
    set(defaultValues, path, value);
  }
  const editorApi = useForm({
    defaultValues,
  });

  const renderEditor = (): ReactNode => {
    if (editor === "radio") {
      return (
        <AppRadioGroup
          name={path}
          formApi={editorApi}
          items={values}
          i18nAbsPath={i18nAbsPath}
        />
      );
    } else if (editor === "checkboxes") {
      return (
        <AppCheckboxes
          name={path}
          formApi={editorApi}
          items={values}
          i18nAbsPath={i18nAbsPath}
        />
      );
    } else if (editor === "select") {
      return (
        <AppSelect name={path} formApi={editorApi}>
          {Array.isArray(editorOptions.children)
            ? editorOptions.children
            : editorOptions.children(source)}
        </AppSelect>
      );
    } else if (editor === "date") {
      return <AppTextField type="date" name={path} formApi={editorApi} />;
    } else if (editor === "checkbox") {
      return <AppCheckbox name={path} formApi={editorApi} label={label} />;
    } else if (["textfield"].includes(editor)) {
      return (
        <AppTextField
          name={path}
          formApi={editorApi}
          type={editorOptions?.type}
        />
      );
    } else if (["strings"].includes(editor)) {
      return (
        <>
          <small>
            {t(
              `common.separatorHelper.${
                editorOptions?.separatorHelper || ARRAY_EDITOR_SEPARATOR_HELPER
              }`
            )}
          </small>
          <AppTextField
            name={path}
            formApi={editorApi}
            type={editorOptions?.type}
          />
        </>
      );
    } else if (editor == "textarea") {
      return (
        <div
          style={{
            width: 500,
          }}
        >
          <AppTextarea name={path} formApi={editorApi} rows={8} />
        </div>
      );
    } else if (editor === "coords") {
      return (
        <>
          <AppTextField
            label="X"
            name={`${path}.0`}
            formApi={editorApi}
            type="number"
          />
          <AppTextField
            label="Y"
            name={`${path}.1`}
            formApi={editorApi}
            type="number"
          />
        </>
      );
    } else if (editor === "daterange") {
      return (
        <>
          <AppDatePicker
            label="Entre le"
            name={`${path}.from`}
            formApi={editorApi}
          />
          <AppDatePicker
            label="Et le"
            name={`${path}.to`}
            formApi={editorApi}
          />
        </>
      );
    }
  };

  const onSubmit = async () => {
    const pathes = path.split(".");
    const field: string = pathes[0];
    const key: string = pathes.length > 1 ? pathes[pathes.length - 1] : "";
    const payload = cloneDeep(source);
    if (key && isArray(payload[field]) && isNaN(Number(key))) {
      payload[field] = {};
      source[field].forEach((item, index) => {
        payload[field][index] = item;
      });
    }
    set(payload, path, get(editorApi.getValues(), path));
    try {
      let fieldValue = cloneDeep(payload[field]);
      //hack !
      if (
        editor === "select" &&
        isPlainObject(fieldValue) &&
        fieldValue["@id"]
      ) {
        fieldValue = fieldValue["@id"];
      } else if (editor === "select" && !fieldValue) {
        fieldValue = null;
      } else if (editor == "strings") {
        fieldValue = fieldValue
          .split(editorOptions?.separator || ARRAY_EDITOR_SEPARATOR)
          .map((value) => {
            return trim(value);
          });
      }
      const { data } = await resApi.patch(source["@id"], {
        [field]: fieldValue,
      });
      set(source, path, get(data, path));
      onClose();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Visite du : {printDate(get(source, datePath))} <br />
        {label}
        <br />
        <small>
          valeur initiale : {getLabel(get(source, `initialData.${path}`))}
        </small>
      </DialogTitle>
      <DialogContent>
        <div className="">{renderEditor()}</div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisitValueEditor;
