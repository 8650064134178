import React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { IDepartment, IUser } from "../../interfaces";
import AppSelect from "./AppSelect";
import { IconButton } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { USER_ROLES } from "../../constants";
import { useTranslation } from "react-i18next";
import AppTextField from "./AppTextField";

const AppSelectDep: React.FC<{
  user: IUser;
  formApi: any;
  label?: string;
  name: string;
  arrayFields: any;
  onRemove: (index: number) => void;
  userDepartments: IDepartment[];
}> = (props) => {
  const { userDepartments } = props;

  const defaultdepartment = {
    "@id": null,
    department: null,
    role: "",
  };

  const { t } = useTranslation();

  if (!userDepartments) {
    return;
  }

  return (
    <>
      <div className="mb-4">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.arrayFields.prepend({ ...defaultdepartment });
          }}
        >
          Ajouter département
        </Button>
      </div>
      {props.arrayFields.groups.map((item, index) => {
        return (
          <div
            key={item.id}
            className="form-item col-12 mt-3 d-flex align-items-center"
          >
            <div className="flex-fill">
              <AppSelect
                name={`groups.${index}.department`}
                label="Département"
                formApi={props.formApi}
                defaultValue={item["department"]}
              >
                {userDepartments.map((e) => {
                  return (
                    <MenuItem value={e["@id"]} key={e["@id"]}>
                      {e.label}
                    </MenuItem>
                  );
                })}
              </AppSelect>
              <div className="mt-1">
                <AppSelect
                  name={`groups.${index}.role`}
                  label="Role"
                  formApi={props.formApi}
                >
                  {USER_ROLES.map((e) => {
                    return (
                      <MenuItem value={e} key={e}>
                        {t(`role.${e}`)}
                      </MenuItem>
                    );
                  })}
                </AppSelect>
              </div>
              <div className="d-none">
                <AppTextField
                  type="hidden"
                  formApi={props.formApi}
                  name={`groups.${index}.@id`}
                />
              </div>
            </div>
            <IconButton color="primary" onClick={() => props.onRemove(index)}>
              <DeleteForeverIcon />
            </IconButton>
          </div>
        );
      })}
    </>
  );
};

export default AppSelectDep;
