import { IconButton, InputAdornment } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { FC } from "react";
import { Controller } from "react-hook-form";
import FormItemError from "./FormItemError";
import DateFnsUtils from "@date-io/date-fns";

type AppTextFieldProps = {
  formApi;
  hasClearButton?: boolean;
} & TextFieldProps & {
    onChange?: (value: string | number) => void;
  };

const AppTextField: FC<AppTextFieldProps> = (props) => {
  const control = props.formApi.control;
  const type = props.type || "text";
  const inputProps = props.inputProps || {};
  let hasClearButton = props.hasClearButton;
  if (type === "number") {
    inputProps.step = "any";
    if (hasClearButton !== false && !props.disabled) {
      hasClearButton = true;
    }
  }
  let InputLabelProps = props.InputLabelProps || {};
  InputLabelProps = { shrink: true, ...InputLabelProps };
  const fns = new DateFnsUtils();

  return (
    <>
      <Controller
        control={control}
        name={props.name}
        defaultValue={
          type != "date" ? undefined : fns.format(new Date(), "yyyy-MM-dd")
        }
        render={({ field: { onChange, value } }) => {
          if (type === "date" && value instanceof Date) {
            value = fns.format(value, "yyyy-MM-dd");
          }
          if (value == null) {
            value = "";
          }
          const InputProps: any = props.InputProps || {};
          if (hasClearButton) {
            InputProps.endAdornment = (
              <InputAdornment position="end">
                <IconButton onClick={() => onChange(null)}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            );
          }
          return (
            <TextField
              value={value}
              disabled={props.disabled}
              label={props.label}
              name={props.name}
              type={type}
              size={props.size}
              className={`mb-2 ${props.className}`}
              InputLabelProps={InputLabelProps}
              inputProps={inputProps}
              InputProps={InputProps}
              variant={props.variant || "outlined"}
              fullWidth={props.fullWidth === false ? false : true}
              required={props.required}
              onChange={(e) => {
                let value: string | number = e.target.value;
                if (type === "number") {
                  if (typeof value === "string" && !value) {
                    value = null;
                  }
                  value = Number(value);
                }
                onChange(value);
                props.onChange?.(value);
              }}
            />
          );
        }}
      ></Controller>
      <FormItemError name={props.name} formApi={props.formApi} />
    </>
  );
};
export default AppTextField;
