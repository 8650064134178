import { useHistory } from "react-router-dom";
import { SignInForm } from "../components/form/SigninForm";

export default function SignInPage() {
  const history = useHistory();

  return (
    <div className="d-flex h-100 align-items-center justify-content-center flex-column">
      <SignInForm
        onComplete={(result) => {
          if (result) {
            history.push("/");
          }
        }}
      />
    </div>
  );
}
