import { IDepartment, IUser, IUserGroup } from "../interfaces";

export const isUserSuperAdmin = (user: IUser) => {
  return user?.roles?.indexOf("ROLE_ADMIN") > -1;
};

export const isUserAdminSomewhere = (user: IUser) => {
  if (isUserSuperAdmin(user)) {
    return true;
  }
  const adminInDpts = user?.groups
    ? user.groups.map((group) => group.role === "admin")
    : [];

  return adminInDpts.includes(true);
};

export const getUserDptRole = (
  user: IUser,
  department: IDepartment | string
): IUserGroup["role"] => {
  if (isUserSuperAdmin(user)) {
    return "admin";
  }
  const dptId = typeof department === "string" ? department : department["@id"];
  return user.groups?.find((gp) => {
    return gp.department["@id"] === dptId;
  })?.role;
};

export const isUserDptAdmin = (
  user: IUser,
  department: IDepartment | string
): boolean => {
  return getUserDptRole(user, department) === "admin";
};
