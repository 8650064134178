import {
  AppBar,
  Button,
  Dialog,
  Paper,
  TextField,
  Toolbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FC, useState } from "react";
import getConfig from "../../config";
import useApi from "../../lib/useApi";
import { useAppContext } from "../../providers/AppContext";

export type TSignInResult = {
  token: string;
};

interface IProps {
  mode?: "page" | "dialog";
  onComplete?: (result?: TSignInResult) => void;
}

export const SignInForm: FC<IProps> = (props) => {
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordResult, setResetPasswordResult] = useState<
    "" | "success" | "emailInvalid" | "error"
  >("");
  const { pubApi } = useApi();
  const { setUser } = useAppContext();
  const { tokenStorage, apiUrl } = getConfig();

  const onSubmitLogin = async (e: any) => {
    e.preventDefault();
    try {
      const respLogin = await pubApi.post("/login_check", {
        username,
        password,
      });
      const token: string = respLogin.data.token;
      const { data: user } = await pubApi.get("/users/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (props.mode === "dialog") {
        setUsername("");
        setPassword("");
        setError("");
      }

      if (!user) {
        return props.onComplete?.();
      }
      setUser(user);
      window.localStorage.setItem(tokenStorage, token);
      props.onComplete?.({
        token,
      });
    } catch (err) {
      setError(
        err.response.data?.message?.includes("Too many failed login attempts")
          ? "Trop de tentatives."
          : "Email ou mot de passe incorrect."
      );
    }
  };

  const onSubmitReset = async (e: any) => {
    e.preventDefault();
    try {
      const response = await pubApi.post("/reset-password/send", {
        email: resetPasswordEmail,
      });
      setShowResetPassword(false);
      return setResetPasswordResult("success");
    } catch (error) {
      setResetPasswordResult(
        error?.response?.status === 400 ? "emailInvalid" : "error"
      );
    }
  };
  return (
    <>
      <Paper className="mt-0">
        <AppBar position="static">
          <Toolbar variant="dense">
            <div className="col-auto">Accès Cerbère</div>
          </Toolbar>
        </AppBar>
        <div className="p-3">
          <Button
            variant="contained"
            onClick={() => {
              const state: string = String(
                Math.round(100000 + Math.random() * 900000)
              );
              window.localStorage.setItem("state", state);
              window.location.href = `${apiUrl}/phpcas/login?redirect_uri=${window.location.origin}&state=${state}`;
            }}
          >
            Connexion Cerbère
          </Button>
        </div>
        <AppBar position="static">
          <Toolbar variant="dense">
            <div className="col-auto">Accès partenaires</div>
          </Toolbar>
        </AppBar>
        <div className="p-3">
          <form
            onSubmit={onSubmitReset}
            className={showResetPassword ? "" : "d-none"}
            style={{
              width: "310px",
            }}
          >
            <div className="mt-0 pb-2">
              Saisissez votre adresse mail pour réinitialiser votre mot de
              passe.
            </div>
            <div className="mt-2">
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={resetPasswordEmail}
                onChange={(e) => {
                  setResetPasswordEmail(e.target.value);
                }}
              />
            </div>
            <div className="mt-4 d-flex justify-content-between">
              <Button type="submit" variant="contained" color="primary">
                Envoyer
              </Button>
              <Button onClick={() => setShowResetPassword(false)}>
                Annuler
              </Button>
            </div>
          </form>
          <form
            onSubmit={onSubmitLogin}
            className={showResetPassword ? "d-none" : ""}
            style={{
              width: "310px",
            }}
          >
            <div className="mt-2">
              <TextField
                label="Login"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => {
                  setUsername(e.target.value);
                  setResetPasswordEmail(e.target.value);
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                label="Mot de passe"
                variant="outlined"
                size="small"
                type="password"
                fullWidth
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            {Boolean(error) && (
              <div className="mt-4">
                <Alert severity="error">{error}</Alert>
              </div>
            )}
            <div className="mt-4 d-flex justify-content-between">
              <Button type="submit" variant="contained" color="primary">
                OK
              </Button>
              <Button onClick={() => setShowResetPassword(true)}>
                Mot de passe oublié ?
              </Button>
            </div>
          </form>
        </div>
      </Paper>
      <Dialog
        onClose={() => setResetPasswordResult("")}
        open={Boolean(resetPasswordResult)}
      >
        <Alert
          severity={resetPasswordResult === "success" ? "success" : "error"}
          action={
            <Button color="inherit" onClick={() => setResetPasswordResult("")}>
              OK
            </Button>
          }
        >
          {resetPasswordResult === "success" && (
            <span>
              Un nouveau mot de passe a été envoyé à cette adresse s'il s'agit
              bien d'un compte Géopred : {resetPasswordEmail}
            </span>
          )}
          {resetPasswordResult === "emailInvalid" && (
            <span>Saisir un email valide.</span>
          )}
          {resetPasswordResult === "error" && (
            <span>Erreur interne au serveur, veuillez recommencer.</span>
          )}
        </Alert>
      </Dialog>
    </>
  );
};
