import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import { Controller } from "react-hook-form";
import { getQuestionLabel } from "../../lib/utils";
import FormItemError from "./FormItemError";

const AppCheckbox: React.FC<{
  formApi: any;
  label?: string;
  name: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
}> = (props) => {
  const control = props.formApi.control;
  return (
    <>
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value } }) => {
          value = value || false;
          return (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value}
                  disabled={Boolean(props.disabled)}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    props.onChange?.(e);
                  }}
                />
              }
              label={getQuestionLabel(props.label)}
            />
          );
        }}
      ></Controller>
      <FormItemError name={props.name} formApi={props.formApi} />
    </>
  );
};

export default AppCheckbox;
