import { useParams } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import {
  IBeneficiary,
  IReportDecision,
  ICity,
  IDepartment,
  IReport,
  IReportBeneficiary,
} from "../interfaces";
import { useAppContext } from "../providers/AppContext";
import { printDate, printDateMediumFormat } from "../lib/utils";
import { Button, TextField } from "@material-ui/core";
import { getBeneficiaryLabel, getDDTLabel } from "../lib/reportUtils";
import { useTranslation } from "react-i18next";

export default function RefuseAfterRecourseLetterPage() {
  const { user } = useAppContext();
  const { prvApi } = useApi();
  const { t } = useTranslation();
  const [data, setData] = useState<IReportBeneficiary>(null);
  const [mailTrackingNum, setMailTrackingNum] = useState("LRAR n° ");
  const [reasonValue, setReasonValue] = useState(
    "En effet, [raisons techniques ou administratives]"
  );

  const beneficiary: IBeneficiary = data?.beneficiary;
  const report: IReport = data?.report as IReport;
  const city: ICity = report?.city as ICity;
  const department: IDepartment = city?.department as IDepartment;
  const reportDecision: IReportDecision =
    data?.reportDecision as IReportDecision;
  const parentDecision = data?.parent?.reportDecision;

  let { id } = useParams<{
    id: string;
  }>();

  const [mode, setMode] = useState<"display" | "copy">("display");

  useEffect(() => {
    (async () => {
      const { data } = await prvApi.get(`/report_beneficiaries/${id}`);
      setData(data);
      console.log(data);
    })();
  }, [id]);

  const copy = () => {
    setMode("copy");
  };

  useEffect(() => {
    if (mode === "copy") {
      const range = new Range();
      const selection = window.getSelection();
      selection.removeAllRanges();
      const div = document.getElementById("main");
      if (div) {
        range.setStart(div, 1);
        range.setEnd(div, 7);
        selection.addRange(range);
        document.execCommand("copy");
        setMode("display");
      }
    }
  }, [mode]);

  const print = () => {
    window.print();
  };

  if (!data) {
    return <div />;
  }

  return (
    <div className="mainStyle" id="main">
      <Button
        variant="contained"
        color="primary"
        onClick={copy}
        className="hidden-print"
      >
        Copier
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={print}
        className="hidden-print ms-2"
      >
        Imprimer
      </Button>

      <table className="tableStyle">
        <tbody>
          <tr>
            <td align="left" colSpan={2}>
              <img src="/logo_marianne.png" alt="edition" />
              <br />
              {t(
                `department.metaData.prefect.label_${
                  department.metaData?.prefect?.gender || "male"
                }`,
                {
                  departmentArticle: department.metaData?.article,
                  departmentLabel: department.label,
                }
              )}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              <img src="/bloc.png" alt="edition" className="blocStyle" />
            </td>
          </tr>
          <tr>
            <td width="50%" className="itemTable">
              {`${getDDTLabel(department?.metaData?.ddaf?.designation)} ${
                department.metaData?.article
              } ${department.label}`}
            </td>
          </tr>
          <tr>
            <td className="itemTable">{department.metaData?.service}</td>
          </tr>
          <tr>
            <td className="itemTable">Dossier suivi par : {user.fullname}</td>
          </tr>
          <tr>
            <td className="itemTable">
              Mèl : {department.metaData?.ddaf.email}
              <br />
              Tèl : {department.metaData?.ddaf.phone}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right" width="40%">
              {department.metaData?.ddaf.city}, le{" "}
              {printDateMediumFormat(new Date())}
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <br />
        {mode === "copy" ? (
          <div>
            <br />
            {mailTrackingNum}
          </div>
        ) : (
          <TextField
            className="hidden-print"
            variant="outlined"
            size="small"
            style={{
              width: 500,
            }}
            value={mailTrackingNum}
            onChange={(e) => setMailTrackingNum(e.currentTarget.value)}
          />
        )}
        <span className="show-print">{mailTrackingNum}</span>
      </div>

      <div>
        <p>Objet : Dommages aux troupeaux - Dossier n° {report.ref}</p>
        <br />
        Madame, Monsieur
        <br />
        <br />
        Vous avez présenté une demande de recours gracieux suite à la décision
        du{" "}
        {printDate(
          (parentDecision || reportDecision).initialDate,
          "XX/XX/XXXX"
        )}{" "}
        relative au dossier n° {report.ref}.<br />
        Dans le cadre imposé par le décret n° 2019-722 du 9 juillet 2019 relatif
        à l’indemnisation des dommages causés aux troupeaux domestiques par le
        loup, l’ours et le lynx, modifié par le décret n° 2021-299 du 19 mars
        2021, l’examen de votre dossier ne permet pas de revenir sur la décision
        initiale du{" "}
        {printDate(
          (parentDecision || reportDecision).initialDate,
          "XX/XX/XXXX"
        )}
        .
        <div>
          <br />
          {mode === "copy" ? (
            <div>{reasonValue}</div>
          ) : (
            <TextField
              className="hidden-print"
              variant="outlined"
              size="small"
              style={{
                width: 500,
              }}
              value={reasonValue}
              onChange={(e) => setReasonValue(e.currentTarget.value)}
            />
          )}
          <span className="show-print">{reasonValue}</span>
        </div>
        <div>
          <br />
          Restant à votre disposition pour toute information supplémentaire, je
          vous prie d'agréer, Madame, Monsieur, l'expression de ma considération
          distinguée.
        </div>
      </div>

      <div className="signatureStyle">
        <br />
        {t(
          `department.metaData.prefect.delegation_${
            department.metaData?.prefect?.gender || "male"
          }`,
          {
            departmentLabel: department.label,
          }
        )}
        <br />
        {department.metaData?.ddaf.title}
        <br />
        <br />
        <br />
        <br />
        {department.metaData?.ddaf.fullname}
        <br />
        <br />
      </div>
      <div>
        <br />
        {getBeneficiaryLabel(beneficiary)} <br />
        {beneficiary.address}
        <br />
        {beneficiary.zipCode} {beneficiary.city}
      </div>
    </div>
  );
}
