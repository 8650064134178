import { AxiosInstance } from "axios";
import { IDepartment, IUser } from "../interfaces";

export const autocompleteCities = async (
  input: string,
  prvApi: AxiosInstance,
  user: IUser,
  isSuperAdmin: boolean,
  department?: IDepartment
) => {
  const params: any = {
    label: input,
  };
  if (department) {
    params.department = department["@id"];
  }
  // TODO rm ?
  else if (!isSuperAdmin) {
    user.groups.forEach((gp, index) => {
      params[`department[${index}]`] = gp.department["@id"];
    });
  }
  const { data } = await prvApi.get("/cities", { params });
  return data;
};

export const autocompleteHamlets = async (
  input: string,
  prvApi: AxiosInstance
) => {
  const params: any = {
    hamlet: input,
  };
  const { data } = await prvApi.get("/reports", { params });
  return data.map((report) => {
    return report.hamlet;
  });
};

export const userDepartments = async (
  prvApi: AxiosInstance,
  user: IUser,
  isSuperAdmin: boolean
) => {
  const params: any = {};
  if (!isSuperAdmin) {
    user.groups.forEach((gp, index) => {
      params[`department[${index}]`] = gp.department["@id"];
    });
  }

  return await prvApi.get("/departments", { params });
};
