import { useParams } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import {
  IBeneficiary,
  ICity,
  IDepartment,
  IReport,
  IReportBeneficiary,
  IReportExpertise,
} from "../interfaces";
import { useAppContext } from "../providers/AppContext";
import { printDateMediumFormat } from "../lib/utils";
import { useForm, Controller } from "react-hook-form";
import { Button } from "@material-ui/core";
import { getBeneficiaryLabel, getDDTLabel } from "../lib/reportUtils";
import { useTranslation } from "react-i18next";

export default function BreederLetterPage() {
  const { user } = useAppContext();
  const { prvApi } = useApi();
  const { t } = useTranslation();
  const [data, setData] = useState<IReportBeneficiary>(null);

  const beneficiary: IBeneficiary = data?.beneficiary;
  const report: IReport = data?.report as IReport;
  const city: ICity = report?.city as ICity;
  const department: IDepartment = city?.department as IDepartment;
  const expertise: IReportExpertise = data?.report
    ?.expertise as IReportExpertise;

  let { id } = useParams<{
    id: string;
  }>();

  const form = useForm();
  const [mode, setMode] = useState<"display" | "copy">("display");

  useEffect(() => {
    (async () => {
      const { data } = await prvApi.get(`/report_beneficiaries/${id}`);
      setData(data);
      console.log(data);
    })();
  }, [id]);

  const copy = () => {
    setMode("copy");
  };

  useEffect(() => {
    if (mode === "copy") {
      const range = new Range();
      const selection = window.getSelection();
      selection.removeAllRanges();
      const div = document.getElementById("main");
      if (div) {
        range.setStart(div, 2);
        range.setEnd(div, 8);
        selection.addRange(range);
        document.execCommand("copy");
        setMode("display");
      }
    }
  }, [mode]);

  const print = () => {
    window.print();
  };

  const values = form.getValues();

  const dico_responsability = {
    responsabilite_loup: "du loup",
    responsabilite_ours: "de l'ours",
    responsabilite_lynx: "du lynx",
  };

  if (!data) {
    return <div />;
  }

  return (
    <div className="mainStyle" id="main">
      <Button
        variant="contained"
        color="primary"
        onClick={copy}
        className="hidden-print"
      >
        Copier
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={print}
        className="hidden-print ms-2"
      >
        Imprimer
      </Button>
      <table className="tableStyle">
        <tbody>
          <tr>
            <td align="left" colSpan={2} className="itemTable">
              <img src="/logo_marianne.png" alt="edition" />
              <br />
              {t(
                `department.metaData.prefect.label_${
                  department.metaData?.prefect?.gender || "male"
                }`,
                {
                  departmentArticle: department.metaData?.article,
                  departmentLabel: department.label,
                }
              )}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              <img src="/bloc.png" alt="edition" className="blocStyle" />
            </td>
          </tr>
          <tr>
            <td width="50%" className="itemTable">
              {`${getDDTLabel(department?.metaData?.ddaf?.designation)} ${
                department.metaData?.article
              } ${department.label}`}
            </td>
          </tr>
          <tr>
            <td className="itemTable">{department.metaData?.service}</td>
          </tr>
          <tr>
            <td className="itemTable">Dossier suivi par : {user.fullname}</td>
          </tr>
          <tr>
            <td className="itemTable">
              Mèl : {department.metaData?.ddaf.email}
              <br />
              Tèl : {department.metaData?.ddaf.phone}
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right" width="50%" className="itemTable">
              {department.metaData?.ddaf.city}, le{" "}
              {printDateMediumFormat(new Date())}
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <p>Objet : Dommages aux troupeaux - Dossier n° {report.ref}</p>
        <p>
          <br />
          Madame, Monsieur
        </p>
        <p style={{ textAlign: "justify" }}>
          Un constat de dommage n° {report.ref} a été établi le{" "}
          {printDateMediumFormat(report.visits[0]?.visitedAt, "XX/XX/XXXX")} sur
          la commune de {city.label}.
          <br />
          {expertise?.cause === "predation" && (
            <>
              Au regard des éléments recueillis par les services compétents et
              de l'avis de l'expert qui conclu à un acte de prédation avéré
              n'excluant pas la responsabilité
            </>
          )}
          {expertise?.cause === "unknown" && (
            <>
              Au regard des éléments recueillis par les services compétents,
              l'avis de l'expert ne permet pas de conclure à une prédation.
              Toutefois, au regard du contexte local de prédation
            </>
          )}
          {(expertise?.cause === "predation" ||
            expertise?.cause === "unknown") &&
          mode !== "copy" ? (
            <Controller
              name="animals"
              control={form.control}
              defaultValue={"responsabilite_loup"}
              render={({ field: { onChange, value } }) => {
                value = value || "";
                return (
                  <select
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <option value="responsabilite_loup">du loup</option>
                    <option value="responsabilite_ours">de l'ours</option>
                    <option value="responsabilite_lynx">du lynx</option>
                  </select>
                );
              }}
            />
          ) : (
            <span> {dico_responsability[values.animals]} </span>
          )}
          {expertise?.cause === "unknown" && (
            <>à proximité du lieu de découverte de la victime</>
          )}
          , je propose à l'Agence de Services et de Paiement de procéder au
          versement d'une compensation, conformément à la décision
          d'indemnisation jointe.
        </p>
        <p style={{ textAlign: "justify" }}>
          Vous disposez d'un délai de 15 jours francs à compter de la présente
          décision pour formuler vos observations. Vous pouvez également
          formuler votre accord par écrit auprès du service instructeur, afin de
          mettre fin au délai contradictoire et valider la poursuite du
          traitement de votre dossier.
        </p>
        <p style={{ textAlign: "justify" }}>
          Restant à votre disposition pour toute information supplémentaire, je
          vous prie d'agréer, Madame, Monsieur, l'expression de ma considération
          distinguée.
        </p>
      </div>

      <div className="signatureStyle" style={{ textAlign: "right" }}>
        {department.metaData?.ddaf.title}
        <br />
        <br />
        <br />
        <br />
        {department.metaData?.ddaf.fullname}
        <br />
        <br />
      </div>

      <div>
        <br />
        {getBeneficiaryLabel(beneficiary)} <br />
        {beneficiary.address}
        <br />
        {beneficiary.zipCode} {beneficiary.city}
        <br />
      </div>

      <div style={{ fontSize: 9 }}>
        <br />
        Nous nous engageons à prendre les mesures techniques et
        organisationnelles appropriées afin de garantir la sécurité du
        traitement des données à caractère personnel de chacun, En application
        du règlement général sur la protection des données (RGPD) et de la loi
        informatique et aux libertés, vous disposez d’un droit d’accès, de
        rectification, de suppression et d’opposition.
        <br />
        Par mail :
        Agents.L.PME.EHN.DREAL-Auvergne-Rhone-Alpes@developpement-durable.gouv.fr
        <br />
        Par voie postale :<br />
        DREAL AuRA
        <br />
        SEHN - PPME - Unité Loup
        <br />
        5, place Jules Ferry 69006 LYON
        <br />
        Cette demande écrite est accompagnée d’une copie du titre d’identité
        avec signature du titulaire de la pièce, en précisant l’adresse à
        laquelle la réponse doit être envoyée. Le délégué à la protection des
        données du ministère de la transition écologique peut également être
        contacté à l’adresse suivante :
        dpd.daj.sg@developpement-durable.gouv.fr. Tout courrier électronique qui
        lui est adressé doit indiquer le nom du traitement en objet.
        <br />
        Conformément au RGPD, vous disposez du droit d’introduire une
        réclamation auprès de la CNIL (3 place de Fontenoy – TSA 80715 – 75334
        PARIS CEDEX 07). Les modalités de réclamation sont précisées sur le site
        de la CNIL : www.cnil.fr.
      </div>

      <div className="print-footer">
        <br />
        <br />
        {`${getDDTLabel(department?.metaData?.ddaf?.designation)} ${
          department.metaData?.article
        } ${department.label}`}
        <br />
        {department.metaData?.service}
        <br />
        {department.metaData?.ddaf.address}
        <br />
        {department.metaData?.ddaf.city}
        <br />
        <br />
      </div>
    </div>
  );
}
