import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { FC, useState, useEffect } from "react";
import useApi from "../../lib/useApi";
import {
  IDepartment,
  IOrganization,
  IUser,
  IUserEdit,
  IUserGroup,
  IUserGroupDto,
} from "../../interfaces";
import AppTextField from "../form-input/AppTextField";
import AppSelectDep from "../form-input/AppSelectDep";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import { Alert } from "@material-ui/lab";
import { cloneDeep, pick } from "lodash";
import { useUser } from "../../lib/hooks/useUser";
import AppSelect from "../form-input/AppSelect";
import { useAppContext } from "../../providers/AppContext";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "../../i18n";

const validationSchema = Yup.object().shape({
  passwordConfirm: Yup.string()
    .nullable()
    .when("password", (password: string, schema: any) => {
      if (!password) {
        return schema;
      }
      return schema.oneOf(
        [Yup.ref("password"), null],
        i18n.t("validation.common.passwordConfirm")
      );
    }),
});

const UserFormDialog: FC<{
  isOpen: boolean;
  user: IUser;

  onCancel: () => void;
  onComplete: () => void;
}> = (props) => {
  const { prvApi, resApi, handleError } = useApi();
  let createFormApi = useForm<IUser>({
    defaultValues: { groups: [] },
    resolver: yupResolver(validationSchema),
  });
  const [forceRender, setForceRender] = useState(0);
  const [isNew, setIsNew] = useState(!Boolean(props.user["@id"]));
  const { isSuperAdmin, user: appUser } = useAppContext();
  const isOwner = props.user?.["@id"] === appUser["@id"];
  const { refetchUserData } = useUser();
  const [isReady, setIsReady] = useState(false);

  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  const {
    fields: groups,
    prepend,
    remove,
    update,
  } = useFieldArray({
    control: createFormApi.control,
    name: "groups",
  });

  useEffect(() => {
    (async () => {
      await refetchUserData();
      setIsReady(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data: dpts } = await prvApi.get("/departments");
      setDepartments(dpts);
      const { data: allOrganizations } = await prvApi.get("/organismes");
      const notDeletedOrganizations = allOrganizations.filter(
        (o: IOrganization) => !o.isDeleted
      );
      setOrganizations(notDeletedOrganizations);

      const formData = cloneDeep(props.user);
      createFormApi.reset(formData);
    })();
  }, [isReady]);

  const onAddClick = async () => {
    createFormApi.handleSubmit(postUser)();
    return;
  };

  const postUser = async () => {
    const formData: IUser = createFormApi.getValues();
    const dto: IUserEdit = {
      ...pick(formData, ["fullname", "password"]),
    } as IUserEdit;

    if (isSuperAdmin()) {
      Object.assign(dto, {
        ...pick(formData, ["username", "roles"]),
        organization:
          formData.organization?.["@id"] || (formData.organization as string),
      });
    }

    if (isNew) {
      try {
        const { data }: { data: IUser } = await prvApi.post("/users", dto);
        formData["@id"] = data["@id"];
        createFormApi.setValue("@id", data["@id"]);

        setIsNew(false);
      } catch (error) {
        return handleError(error, createFormApi);
      }
    } else {
      try {
        await resApi.put(formData["@id"], dto);
      } catch (error) {
        return handleError(error, createFormApi);
      }
    }
    if (!isSuperAdmin()) {
      return onComplete();
    }
    let hasError = false;
    for (let index = 0; index < formData.groups?.length; index++) {
      const group = formData.groups[index];
      const dto: IUserGroupDto = {
        role: group.role,
        department:
          typeof group.department === "string"
            ? group.department
            : group.department?.["@id"],
      };

      if (!dto.role || !dto.department) {
        continue;
      }

      try {
        if (group["@id"]) {
          await resApi.put(group["@id"], dto);
        } else {
          dto.user = formData["@id"];
          const { data }: { data: IUserGroup } = await prvApi.post(
            "/user_groups",
            dto
          );
          update(index, {
            ...group,
            "@id": data["@id"],
          });
        }
      } catch (error) {
        handleError(error);
        hasError = true;
      }
    }

    if (!hasError) {
      onComplete();
    }
  };

  const removeGroup = async (index: number) => {
    const group = groups[index];
    if (!group["@id"]) {
      return remove(index);
    }

    try {
      await resApi.delete(group["@id"]);
      remove(index);
      onComplete();
    } catch (error) {
      return handleError(error);
    }
  };

  const onComplete = async () => {
    if (isOwner) {
      await refetchUserData();
    }
    props.onComplete();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        {isOwner
          ? "Mon profil"
          : `${isNew ? "Créer" : "Modifier"} un utilisateur`}
      </DialogTitle>
      <DialogContent>
        <div className="p-3 pt-1">
          <form key={forceRender}>
            <div className="row">
              <div className="form-item col-12 mt-2">
                <AppTextField
                  formApi={createFormApi}
                  name={"fullname"}
                  label="Nom complet"
                />
              </div>
              <div
                className={`form-item col-12 ${
                  isSuperAdmin() ? "mt-2" : "pt-1"
                }`}
              >
                <AppTextField
                  formApi={createFormApi}
                  name={"username"}
                  label="Email ou nom d'utilsateur (login)"
                  disabled={!isSuperAdmin()}
                  className={isSuperAdmin() ? "" : "readonly"}
                />
              </div>
              {isSuperAdmin() && (
                <div className="col-12 mt-2">
                  {props.user.hasPassword ? (
                    <Alert variant="filled" severity="error">
                      <b>Un mot de passe est déjà associé à ce compte</b>
                    </Alert>
                  ) : (
                    <Alert severity="info">
                      Aucun mot de passe n'est associé à ce compte
                    </Alert>
                  )}
                </div>
              )}
              {(isSuperAdmin() || props.user.hasPassword) && (
                <>
                  <div className="form-item col-12 mt-3">
                    <AppTextField
                      formApi={createFormApi}
                      name="password"
                      label={
                        props.user.hasPassword
                          ? "Modifier le mot de passe existant"
                          : "Créer un mot de passe"
                      }
                      inputProps={{
                        className: "password",
                        autoComplete: "off",
                      }}
                    />
                  </div>
                  <div className="form-item col-12 mt-3">
                    <AppTextField
                      formApi={createFormApi}
                      name="passwordConfirm"
                      label="Confirmer le mot de passe"
                      inputProps={{
                        className: "password",
                        autoComplete: "off",
                      }}
                    />
                  </div>
                </>
              )}
              {isSuperAdmin() && (
                <>
                  <div className="form-item col-12 mt-3">
                    <AppSelect
                      formApi={createFormApi}
                      name={"organization"}
                      label="Organisme"
                    >
                      {organizations.map((value: IOrganization) => {
                        return (
                          <MenuItem key={value.id} value={value["@id"]}>
                            {value.label}
                          </MenuItem>
                        );
                      })}
                    </AppSelect>
                  </div>
                  <div className="form-item col-12 mt-2">
                    <Controller
                      control={createFormApi.control}
                      name={"roles"}
                      render={({ field: { onChange, value } }) => {
                        let check =
                          (value && value.includes("ROLE_ADMIN")) || false;
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check}
                                onChange={(e) => {
                                  if (e.target.checked)
                                    onChange(["ROLE_ADMIN"]);
                                  else onChange([]);
                                  setForceRender(forceRender + 1);
                                }}
                              />
                            }
                            label={"Super admin"}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="form-item col-12 mt-2">
                    {(!createFormApi.getValues().roles ||
                      createFormApi.getValues().roles[0] !== "ROLE_ADMIN") && (
                      <AppSelectDep
                        user={props.user}
                        formApi={createFormApi}
                        name={"groups"}
                        label="droits départementaux"
                        arrayFields={{ groups, prepend, remove }}
                        onRemove={removeGroup}
                        userDepartments={departments}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        {!isNew ? (
          <Button onClick={onAddClick} color="primary">
            Modifier
          </Button>
        ) : (
          <Button onClick={onAddClick} color="primary">
            Ajouter
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserFormDialog;
