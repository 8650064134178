import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { StringMap, TOptions } from "i18next";
import { FC, Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getQuestionLabel } from "../../lib/utils";
import FormItemError from "./FormItemError";

const AppRadioGroup: FC<{
  formApi: any;
  label?: string;
  name: string;
  items: readonly any[];
  itemLabel?: string | Function;
  itemValue?: string;
  i18nPrefix?: string;
  i18nAbsPath?: string;
  direction?: "row" | "column";
  disabled?: boolean;
  getItemChildren?: (
    item: any,
    value: any,
    isSelected: boolean
  ) => JSX.Element | void;
  defaultValue?: string;
}> = (props) => {
  const control = props.formApi.control;
  const { t } = useTranslation();
  const [forceRender, setForceRender] = useState(0);

  const direction = props.direction || "column";

  const itemProps = {
    label: props.itemLabel || "label",
    value: props.itemValue || "id",
  };
  const getItemLabel = (item) => {
    if (typeof item === "string") {
      const options: TOptions<StringMap> = {};
      if (item === "undeterminable") {
        options.defaultValue = t("common.undeterminable");
      }
      if (props.i18nAbsPath) {
        return getQuestionLabel(t(`${props.i18nAbsPath}.${item}`, options));
      }
      return getQuestionLabel(
        t(`${props.i18nPrefix}.${props.name}.${item}`, options)
      );
    }
    const prop = itemProps.label;
    if (typeof prop === "string") {
      return getQuestionLabel(item[prop]);
    }
    return getQuestionLabel(prop(item));
  };
  const getItemValue = (item) => {
    if (typeof item === "string") {
      return item;
    }
    return item[itemProps.value];
  };
  return (
    <>
      <Controller
        control={control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ field: { onChange, value } }) => {
          value = value || null;
          return (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {getQuestionLabel(props.label)}
              </FormLabel>
              <RadioGroup
                style={{
                  flexDirection: direction,
                }}
              >
                {props.items.map((item, index) => {
                  const itemValue = getItemValue(item);
                  const isSelected = itemValue === value;
                  return (
                    <Fragment key={itemValue + index}>
                      <FormControlLabel
                        value={itemValue}
                        label={getItemLabel(item)}
                        disabled={Boolean(props.disabled)}
                        control={
                          <Radio
                            color="primary"
                            checked={isSelected}
                            onClick={(e) => {
                              onChange(itemValue);
                              setForceRender(forceRender + 1);
                            }}
                          />
                        }
                      />
                      {props.getItemChildren?.(item, itemValue, isSelected)}
                    </Fragment>
                  );
                })}
              </RadioGroup>
            </FormControl>
          );
        }}
      ></Controller>
      <FormItemError name={props.name} formApi={props.formApi} />
    </>
  );
};

export default AppRadioGroup;
