import i18n from "../i18n";
import {
  ANIMAL_GROUPS,
  BENEFICIARY_HERD_COLS,
  OBS_COMPENSABLE_HEALTHES,
  REPORT_PREDATOR_SIGN,
  REPORT_STATUS,
} from "../constants";
import {
  IAnimalCategory,
  IAnimalCategoryScaleRow,
  IBeneficiary,
  IReport,
  IReportBeneficiary,
  IReportVictim,
  IVisit,
  IVisitBeneficiary,
} from "../interfaces";
import proj4 from "proj4";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import trim from "lodash/trim";

export const getResolvedVisitBeneficiaries = (
  inputs: (IVisitBeneficiary | IReportBeneficiary)[]
) => {
  const outputs = [];
  inputs
    .filter((ben) => !(ben as IVisitBeneficiary).parentId && !ben.parent)
    .forEach((parent) => {
      outputs.push(parent);
      inputs
        .filter(
          (ben) =>
            ((ben as IVisitBeneficiary).parentId || ben.parent?.id) ===
            parent.id
        )
        .forEach((child) => {
          child.parent = parent;
          outputs.push(child);
        });
    });
  outputs.forEach((beneficiary) => {
    if (!beneficiary.parentId && !beneficiary.parent) {
      beneficiary.children = outputs.filter(
        (child) => (child.parentId || child.parent?.id) === beneficiary.id
      );
    }
  });

  return outputs;
};

export const getBeneficiaryLabel = (beneficiary?: IBeneficiary) => {
  if (!beneficiary) {
    return "";
  }
  return trim(
    beneficiary.corporateName ||
      `${beneficiary.lastName || ""} ${beneficiary.firstName || ""}`
  );
};

export const getDDTLabel = (metadataDesignation: string | undefined) => {
  return !metadataDesignation
    ? "Direction Départementale des Territoires"
    : metadataDesignation;
};

export const legagyCategoryToScaleRow = (
  category?: IAnimalCategory
): IAnimalCategoryScaleRow => {
  if (!category) {
    return;
  }
  const res = {
    ...category,
    amount: category.amountScale,
    animalCategory: {
      ...category,
    },
  };

  delete res.amountScale;
  delete res.isDeleted;

  return res;
};

export const getReportVictims = (report: IReport) => {
  return report.beneficiaries
    ?.map((beneficiary) => {
      return beneficiary.victims;
    })
    .reduce((result, item) => {
      return result.concat(item);
    }, []);
};

export const getReportStatuses = (): {
  value: (typeof REPORT_STATUS)[number];
  label: string;
}[] => {
  return REPORT_STATUS.map((status) => {
    return {
      value: status,
      label: i18n.t(`report.status.${status}`),
    };
  });
};

export const compareReportStatus = (
  report: IReport,
  valueToCompare: IReport["status"]
): "<" | "=" | ">" => {
  const reportStatusIndex = REPORT_STATUS.indexOf(report.status);
  const valueToCompareIndex = REPORT_STATUS.indexOf(valueToCompare);

  if (reportStatusIndex < valueToCompareIndex) {
    return "<";
  }
  if (reportStatusIndex > valueToCompareIndex) {
    return ">";
  }
  return "=";
};

const allowedStatuses: IReport["status"][] = [
  "draft",
  "ready",
  "current",
  "additional_compensation",
  "additional_payment",
  "refused",
];

export const isReportDisabled = (report: IReport) => {
  return !allowedStatuses.includes(report.status);
};

const lambert93 =
  "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
const convertCoords = proj4("WGS84", lambert93);

export const toLambert = (
  latitude: number,
  longitude: number
): { x: number; y: number } => {
  try {
    const [x, y] = convertCoords.forward([longitude, latitude]);
    return {
      x,
      y,
    };
  } catch (error) {}
};

export const fromLambert = (
  x: number,
  y: number
): { latitude: number; longitude: number } => {
  try {
    const [longitude, latitude] = convertCoords.inverse([x, y]);
    return {
      latitude,
      longitude,
    };
  } catch (error) {}
};

export const getBeneficiaryIndex = (
  beneficiary: IReportBeneficiary,
  beneficiaries: IReportBeneficiary[]
): number => {
  return beneficiaries.findIndex((item) => {
    return item["@id"] === beneficiary?.["@id"];
  });
};

export const getHerdTotal = (
  herd: IReportBeneficiary["herd"] | IVisitBeneficiary["herd"]
) => {
  if (!herd) {
    return 0;
  }
  let total = 0;
  BENEFICIARY_HERD_COLS.forEach((name) => {
    const val = Number(herd[name]);
    if (Boolean(val)) {
      total += val;
    }
  });

  return total;
};

export const getBeneficiaryHerdTotal = (
  beneficiary: IReportBeneficiary | IVisitBeneficiary
) => {
  if (!beneficiary) {
    return 0;
  }

  if (beneficiary.children?.length) {
    return (beneficiary as IReportBeneficiary).children.reduce(
      (total, child) => {
        return total + getHerdTotal(child.herd);
      },
      0
    );
  }

  return getHerdTotal(beneficiary.herd);
};

export const fixReportBeneficiary = (item: IReportBeneficiary) => {
  if (!isPlainObject(item.herd)) {
    item.herd = null;
  }
};

export const getVictimIndex = (
  victim: IReportVictim,
  victims: IReportVictim[]
): number => {
  return victims.findIndex((item) => {
    return item["@id"] === victim?.["@id"];
  });
};

export const getVictimFirstVisit = (victim: IReportVictim): IVisit => {
  if (!victim?.srcObservations) {
    return;
  } else if (!victim?.srcObservations.length && victim.firstVisit) {
    return victim.firstVisit as IVisit;
  }
  const srcObservations = [...victim.srcObservations];
  srcObservations.sort((a, b) => {
    const visitA = a.beneficiary.visit;
    const visitB = b.beneficiary.visit;
    if (visitA.visitedAt > visitB.visitedAt) {
      return 1;
    }
    if (visitA.visitedAt < visitB.visitedAt) {
      return -1;
    }
    return 0;
  });

  return srcObservations?.[0]?.beneficiary?.visit;
};

export const getVictimTotalAmount = (victim: IReportVictim) => {
  if (!victim.isApproved) {
    return 0;
  }
  let result = (victim.veterinaryCosts || 0) + (victim.euthanasiaCosts || 0);
  if (OBS_COMPENSABLE_HEALTHES.includes(victim.health)) {
    result += victim.amount || victim.amountScale || 0;
  }
  return result;
};

export const getVictimGroupName = (victim: IReportVictim) => {
  const category: string =
    typeof victim.category === "string" ? victim.category : victim.category?.id;
  if (!category) {
    return "other";
  }
  return (
    ANIMAL_GROUPS.find((group) => category.match(group.pattern))?.name ||
    "other"
  );
};

export const getVisitIndex = (visit: IVisit, visits: IVisit[]): number => {
  return visits.findIndex((item) => {
    return item["@id"] === visit?.["@id"];
  });
};

export const isFieldChecked = (
  obj: any,
  fieldPath: string,
  valueToCompare: any
): boolean => {
  const fieldValue: any = get(obj, fieldPath);
  if (fieldValue === null || typeof fieldValue === "undefined") {
    return false;
  }
  if (typeof valueToCompare === "undefined") {
    return fieldValue;
  }
  if (Array.isArray(fieldValue)) {
    return !fieldValue.length
      ? undefined
      : (fieldValue as any[]).includes(valueToCompare);
  }
  return fieldValue === valueToCompare;
};

export const isPredatorChecked = (
  obj: IVisit["data"],
  predator: "wolf_lynx" | "bear",
  sign: (typeof REPORT_PREDATOR_SIGN)[number]
): boolean => {
  if (
    !obj?.predatorPresenceSigns ||
    !Object.keys(obj?.predatorPresenceSigns).length
  ) {
    return;
  }
  return Boolean(obj?.predatorPresenceSigns?.[predator]?.includes(sign));
};
