import {
  AppBar,
  Button,
  CssBaseline,
  IconButton,
  Popover,
  Toolbar,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import { SnackbarProvider } from "notistack";
import { useState, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Provider as AppContextProvider } from "./providers/AppContext";
import "bootstrap/dist/css/bootstrap-grid.css";
import "bootstrap/dist/css/bootstrap-utilities.css";
import "./App.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import SignInPage from "./pages/SigninPage";
import { useAppContext } from "./providers/AppContext";
import VerifyCodePage from "./pages/VerifyCodePage";
//import ReportsPage from "./pages/ReportsPage";
//import ReportPage from "./pages/ReportPage";
import BreederLetterPage from "./pages/BreederLetterPage";
import DismissLetterPage from "./pages/DismissLetterPage";
import InstructionLetterPage from "./pages/InstructionLetterPage";
import CompensationLetterPage from "./pages/CompensationLetterPage";
import InformationRecourseLetterPage from "./pages/InformationRecourseLetterPage";
import RefuseAfterRecourseLetterPage from "./pages/RefuseAfterRecourseLetterPage";
//import VictimPage from "./pages/VictimPage";
import ReportCreationPage from "./pages/ReportCreationPage";
//import AdminPage from "./pages/AdminPage";
import ReportBeneficiaryPage from "./pages/ReportBeneficiaryPage";
import ReportBeneficiaryCreationPage from "./pages/ReportBeneficiaryCreationPage";
import { RouterButton } from "./components/shared/RouterButton";
import { useEffect } from "react";
import { useUser } from "./lib/hooks/useUser";
import { Suspense } from "react";
import { IUser } from "./interfaces";
import UserFormDialog from "./components/dialog/UserFormDialog";
import VisitBeneficiaryPage from "./pages/VisitBeneficiaryPage";

const AdminPage = lazy(() => import("./pages/AdminPage"));
const ReportsPage = lazy(() => import("./pages/ReportsPage"));
const ReportPage = lazy(() => import("./pages/ReportPage"));
const VictimPage = lazy(() => import("./pages/VictimPage"));

function HeaderBar() {
  const [userPopoverAnchorEl, setUserPopoverAnchorEl] = useState(null);
  const isUserPopoverOpen = Boolean(userPopoverAnchorEl);
  const { user, setUser, isAdminSomewhere } = useAppContext();
  const [userDialogData, setUserDialogData] = useState<IUser>();

  const { refetchUserData } = useUser();
  useEffect(() => {
    refetchUserData();
  }, []);

  return (
    <AppBar position="fixed" className="header-main hidden-print">
      <Toolbar variant="dense">
        <div className="col-auto">
          <img src="/logo.svg" alt="logo" />
        </div>
        {Boolean(user) && (
          <>
            <div className="row gx-2 ms-auto me-3">
              <div className="col-auto">
                <RouterButton
                  color="inherit"
                  variant="outlined"
                  href="/reports"
                >
                  Dossiers
                </RouterButton>
              </div>
              <div className="col-auto">
                {isAdminSomewhere() && (
                  <RouterButton
                    color="inherit"
                    variant="outlined"
                    href="/administration"
                  >
                    Administration
                  </RouterButton>
                )}
              </div>
            </div>
            <div className="col-auto ps-2 col-btn-user">
              <IconButton
                onClick={(e) => {
                  setUserPopoverAnchorEl(e.currentTarget);
                }}
              >
                <PersonIcon fontSize="large" />
              </IconButton>
              <Popover
                open={isUserPopoverOpen}
                anchorEl={userPopoverAnchorEl}
                onClose={() => {
                  setUserPopoverAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="p-2">
                  <div className="mb-2">{user?.fullname}</div>
                  <div>
                    <Button
                      style={{ margin: "5px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setUserPopoverAnchorEl(null);
                        setUserDialogData(user);
                      }}
                    >
                      Mon profil
                    </Button>
                  </div>
                  <div>
                    <Button
                      style={{ margin: "5px" }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setUserPopoverAnchorEl(null);
                        setUser();
                        localStorage.clear();
                      }}
                    >
                      Déconnexion
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
            {Boolean(userDialogData) && (
              <UserFormDialog
                isOpen={true}
                user={userDialogData}
                onCancel={() => setUserDialogData(null)}
                onComplete={() => {
                  setUserDialogData(null);
                }}
              />
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#EE7F01",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4}>
          <AppContextProvider>
            <div className="d-flex h-100">
              <CssBaseline />
              <HeaderBar />
              <div className="h-100 flex-grow-1 d-flex flex-column content-main">
                <Suspense fallback={<div className="p-3">Chargement...</div>}>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/reports" />
                    </Route>
                    <Route path="/signin">
                      <SignInPage />
                    </Route>
                    <Route path="/verify/:code">
                      <VerifyCodePage />
                    </Route>
                    <Route path="/reports/:id">
                      <ReportPage />
                    </Route>
                    <Route path="/reports">
                      <ReportsPage />
                    </Route>
                    <Route path="/administration">
                      <AdminPage />
                    </Route>
                    <Route path="/reports-add">
                      <ReportCreationPage />
                    </Route>
                    <Route path="/breeder-letter/:id">
                      <BreederLetterPage />
                    </Route>
                    <Route path="/instruction-letter/:id">
                      <InstructionLetterPage />
                    </Route>
                    <Route path="/compensation-letter/:id">
                      <CompensationLetterPage />
                    </Route>
                    <Route path="/dismiss-letter/:id">
                      <DismissLetterPage />
                    </Route>
                    <Route path="/recourse-letter/:id">
                      <InformationRecourseLetterPage />
                    </Route>
                    <Route path="/refuse-after-recourse-letter/:id">
                      <RefuseAfterRecourseLetterPage />
                    </Route>
                    <Route path="/victims/:id">
                      <VictimPage />
                    </Route>
                    <Route path="/victims-add">
                      <VictimPage />
                    </Route>
                    <Route path="/visit_beneficiaries/:id">
                      <VisitBeneficiaryPage />
                    </Route>
                    <Route path="/report_beneficiaries/:id">
                      <ReportBeneficiaryPage />
                    </Route>
                    <Route path="/report_beneficiaries-add">
                      <ReportBeneficiaryCreationPage />
                    </Route>
                  </Switch>
                </Suspense>
              </div>
            </div>
          </AppContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
