import SaveIcon from "@material-ui/icons/Save";
import { Fab } from "@material-ui/core";

export default function FabSubmit() {
  return (
    <Fab className="fab-bottom-right" color="primary" type="submit">
      <SaveIcon />
    </Fab>
  );
}
