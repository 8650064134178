import { useParams } from "react-router-dom";
import useApi from "../lib/useApi";
import { useEffect, useState } from "react";
import { Button, FormControlLabel, TextField } from "@material-ui/core";
import {
  IAnimalCategory,
  IBeneficiary,
  ICity,
  IDepartment,
  IReport,
  IReportBeneficiary,
  IReportDecision,
} from "../interfaces";
import {
  getBeneficiaryIndex,
  getBeneficiaryLabel,
  getVictimIndex,
  getVictimTotalAmount,
} from "../lib/reportUtils";
import { printDateMediumFormat, currencyFormatter } from "../lib/utils";
import Checkbox from "@material-ui/core/Checkbox";
import { REPORT_DECISION_LIGHT_CARE } from "../constants";
import { useAppContext } from "../providers/AppContext";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export default function CompensationLetterPage() {
  const { user } = useAppContext();
  const { prvApi } = useApi();
  const { t } = useTranslation();
  const [data, setData] = useState<IReportBeneficiary>(null);
  const [beneficiaryNum, setBeneficiaryNum] = useState(0);
  let { id } = useParams<{
    id: string;
  }>();
  const beneficiary: IBeneficiary = data?.beneficiary;
  const report: IReport = data?.report as IReport;
  const reportDecision: IReportDecision =
    data?.reportDecision as IReportDecision;
  const city: ICity = report?.city as ICity;
  const department: IDepartment = city?.department as IDepartment;

  const [hideArrest, setHideArrest] = useState<boolean>(false);
  const [arrestValue, setArrestValue] = useState(
    "Vu l'arrêté préfectoral en vigueur désignant les communes classées en cercle 1 et 2,"
  );
  const [hideElements, setHideElements] = useState<boolean>(false);
  const [elementsValue, setElementsValue] = useState(
    "Vu les éléments complémentaires transmis par l'éleveur en date du"
  );
  const [mode, setMode] = useState<"display" | "copy">("display");

  useEffect(() => {
    (async () => {
      const { data } = await prvApi.get<IReportBeneficiary>(
        `/report_beneficiaries/${id}`
      );
      const { data: beneficiaries } = await prvApi.get(
        `/report_beneficiaries`,
        {
          params: {
            report: data.report.id,
          },
        }
      );
      setData(data);
      setBeneficiaryNum(getBeneficiaryIndex(data, beneficiaries) + 1);
    })();
  }, [id]);

  const copy = () => {
    setMode("copy");
  };

  useEffect(() => {
    if (mode === "copy") {
      const range = new Range();
      const selection = window.getSelection();
      selection.removeAllRanges();
      const div = document.getElementById("main");
      if (div) {
        range.setStart(div, 1);
        range.setEnd(div, 13);
        selection.addRange(range);
        document.execCommand("copy");
        setMode("display");
      }
    }
  }, [mode]);

  const print = () => {
    window.print();
  };

  if (!data || !report) {
    return <div />;
  }

  let victims = [...data.victims];
  if (!reportDecision?.isCompensationIndividualised) {
    data.children?.forEach((child) => {
      victims = [...victims, ...child.victims];
    });
  }

  return (
    <div className="mainStyle" id="main">
      <Button
        variant="contained"
        color="primary"
        onClick={copy}
        className="hidden-print"
      >
        Copier
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={print}
        className="hidden-print ms-2"
      >
        Imprimer
      </Button>
      <table className="tableStyle" width="100%">
        <tbody>
          <tr>
            <td align="left" colSpan={2} className="itemTable">
              <img src="/logo_marianne.png" alt="edition" />
              <br />
              {t(
                `department.metaData.prefect.label_${
                  department.metaData?.prefect?.gender || "male"
                }`,
                {
                  departmentArticle: department.metaData?.article,
                  departmentLabel: department.label,
                }
              )}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              <img className="blocStyle" src="/bloc.png" alt="edition" />
            </td>
          </tr>
        </tbody>
      </table>
      <table width="100%">
        <tbody>
          <tr>
            <td className="titleLetter" align="center">
              DÉCISION D'INDEMNISATION <br />
              {report.ref}-{beneficiaryNum} <br />
              pour les dommages aux troupeaux domestiques imputés au loup, à
              l'ours ou au lynx.
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ fontSize: "12px" }} width="100%">
        <tbody>
          <tr>
            <td className="itemTable">
              <br />
              N° dossier :
            </td>
            <td>
              {report.ref}
              <br />
            </td>
          </tr>
          <tr>
            <td className="itemTable">Éleveur concerné :</td>
            <td className="itemTable">
              {getBeneficiaryLabel(beneficiary)}
              <br />
              {beneficiary.address}
              <br />
              {beneficiary.zipCode} {beneficiary.city}
              <br />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className="itemTable" align="left">
              <br />
              Vu le régime d'aides d'État notifié n° SA 51768 (2018) et SA
              53439(2019) relatif aux aides destinées à remédier aux dommages
              causés par des animaux protégés en France,
              <br />
              Vu le décret n° 2019-722 du 9 juillet 2019 relatif à
              l'indemnisation des dommages causés aux troupeaux domestiques par
              le loup, l'ours et le lynx,
              <br />
              Vu l'arrêté ministériel du{" "}
              {printDateMediumFormat(
                report.animalCategoryScale?.publicationDate
              )}{" "}
              fixant les barèmes d'indemnisation des dommages dus aux grands
              prédateurs et leurs modalités d'application,
              {/* <br />
            	Vu la note d'instruction du ___________ relative à l'indemnisation des dommages causés par le loup, l'ours et le lynx aux troupeaux et animaux domestiques,  */}
              <br />
              Vu la convention entre le Ministère de la Transition Écologique et
              Solidaire (MTES) et l'Agence de Services et de Paiement (ASP), n°
              2201020181 du 01/07/2016 relative au paiement par l'ASP des
              dispositifs d'indemnisation des dommages causés aux troupeaux
              domestiques par les grands carnivores sauvages,
              <br />
              Vu les arrêtés de délégation et subdélégation de signature en
              vigueur,
              {mode !== "copy" && <br />}
              <TextField
                className="hidden-print"
                variant="outlined"
                size="small"
                style={{
                  width: 500,
                }}
                inputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
                value={arrestValue}
                onChange={(e) => setArrestValue(e.currentTarget.value)}
              />
              {mode === "copy" && !hideArrest && (
                <span>
                  {arrestValue} <br />
                </span>
              )}
              <span className={hideArrest ? "d-none" : "show-print"}>
                {arrestValue}
              </span>
              <span
                className={mode === "copy" ? "d-none" : "hidden-print ms-3"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={hideArrest}
                      onChange={(e) => {
                        setHideArrest(!hideArrest);
                      }}
                    />
                  }
                  label="cacher cette ligne"
                />
              </span>
              {mode !== "copy" && (
                <br className={hideArrest ? "hidden-print" : ""} />
              )}
              Vu le constat d'attaque n° {report.ref} du{" "}
              {printDateMediumFormat(report.visits[0]?.visitedAt, "XX/XX/XXXX")}
              ,
              {mode !== "copy" && (
                <br className={hideElements ? "hidden-print" : ""} />
              )}
              <TextField
                className="hidden-print"
                variant="outlined"
                size="small"
                style={{
                  width: 500,
                }}
                inputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
                value={elementsValue}
                onChange={(e) => setElementsValue(e.currentTarget.value)}
              />
              {mode === "copy" && !hideElements && <span>{elementsValue}</span>}
              <span className={hideElements ? "d-none" : "show-print"}>
                {elementsValue}
              </span>
              <span
                className={mode === "copy" ? "d-none" : "hidden-print ms-3"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={hideElements}
                      onChange={(e) => {
                        setHideElements(!hideElements);
                      }}
                    />
                  }
                  label="cacher cette ligne"
                />
              </span>
              {/* Vu le constat n° 2020 02 001 en date du 14/10/2020 <br />
	    				<br />Vu la Convention 2016 entre le ministère de l'environnement, de l'énergie et de la mer, et l'Agence de services et de paiement, relative au paiement des dispositifs d'indemnisation des dommages causés aux troupeaux domestiques par les grands carnivores sauvages - Loup ;<br />
						<br />Vu la circulaire du MEDD du 27 juillet 2011 relative à l'indemnisation des dommages causés par le loup aux troupeaux domestiques,
	    				<br />
	    				<br />Le Directeur Départemental des Territoires certifie qu'il peut être payé à l'éleveur ci-dessus nommé,
	    				au titre des dommages subis, la somme de 60.50 € se décomposant comme suit : */}
            </td>
          </tr>
          <tr>
            <td className="itemTable">
              <br />
              <b>Il est décidé :</b>
            </td>
          </tr>
          <tr>
            <td className="itemTable">Article 1 : Objet</td>
          </tr>
          <tr>
            <td className="itemTable" align="justify">
              Au vu du constat des dommages, de la fiche d'instruction et des
              pièces et conclusions du dossier technique, une indemnisation peut
              être versée à l'éleveur ci-dessus désigné. Cette indemnisation se
              décomposant ainsi pour un montant total proposé de{" "}
              {reportDecision.totalToPay} € :
            </td>
          </tr>
        </tbody>
      </table>

      <br />

      <div style={{ textAlign: "center" }}>
        <table
          style={{
            fontSize: "9px",
            width: "80%",
            border: "1px solid #EE7F01",
            margin: "auto",
            textAlign: "left",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                Date attaque
              </td>
              {!Boolean(data.parent) && (
                <td
                  style={{
                    borderBottom: "1px solid #EE7F01",
                    textAlign: "center",
                  }}
                  className="itemTable"
                >
                  Effectif du lot attaqué
                </td>
              )}
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                Coûts directs
              </td>
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                Forfait soins légers
              </td>
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                Forfait animaux disparus
              </td>
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                CPI
              </td>
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                Frais vétérinaires (dont euthanasie)
              </td>
              <td
                style={{
                  borderBottom: "1px solid #EE7F01",
                  textAlign: "center",
                }}
                className="itemTable"
              >
                Frais réparations
              </td>
              {/* <td
              style={{ borderBottom: "1px solid #EE7F01", textAlign: "center" }}
            >
              Autres montants
            </td> */}
              {/* <td style="border-bottom: 1px solid #EE7F01; text-align: center;">Pertes exceptionnelles</td> */}
            </tr>
            <tr>
              <td align="center" className="itemTable">
                {printDateMediumFormat(report.damageAt, "XX/XX/XXXX")}
              </td>
              {!Boolean(data.parent) && (
                <td align="center" className="itemTable">
                  {data.nbAnimals}/{(data.report as IReport).nbAnimals}
                </td>
              )}
              <td align="center" className="itemTable">
                {reportDecision.directLosses
                  ? reportDecision.directLosses
                  : 0.0}
              </td>
              <td align="center" className="itemTable">
                {reportDecision.isLightCare ? REPORT_DECISION_LIGHT_CARE : 0}
              </td>
              <td align="center" className="itemTable">
                {reportDecision.isFlatRateDisappear
                  ? reportDecision.flatRateDisappear
                  : 0.0}
              </td>
              <td align="center" className="itemTable">
                {reportDecision.cpi ? reportDecision.cpi : 0.0}
              </td>
              <td align="center" className="itemTable">
                {reportDecision.veterinaryCosts + reportDecision.euthanasiaCosts
                  ? reportDecision.veterinaryCosts +
                    reportDecision.euthanasiaCosts
                  : 0.0}
              </td>
              <td align="center" className="itemTable">
                {reportDecision.materialCosts || "0.00"}
              </td>
              {/* <td align="center">&nbsp;</td> */}
            </tr>
          </tbody>
        </table>
      </div>

      <br />
      <br />

      <div>
        <table className="tableDamages" width="100%">
          <tbody>
            <tr>
              <td
                className="tableDamages ps-2"
                style={{ width: 50, minWidth: 50 }}
              >
                <b>#</b>
              </td>
              <td className="tableDamages" align="center">
                <b>Détail des dommages</b>
              </td>
              <td className="tableDamages" align="center">
                <b className="d-block" style={{ minWidth: 110 }}>
                  N° d'identification
                </b>
              </td>
              <td className="tableDamages" align="center">
                <b className="d-block" style={{ minWidth: 150 }}>
                  Victime retenue pour l'indemnisation
                </b>
              </td>
              <td className="tableDamages" align="center">
                <b className="d-block" style={{ minWidth: 100 }}>
                  Montant attribué
                </b>
              </td>
            </tr>
            {victims?.map((victim) => {
              const category: IAnimalCategory =
                victim?.category as IAnimalCategory;
              return (
                <tr key={victim["@id"]}>
                  <td className="tableDamages ps-2" style={{ width: 50 }}>
                    {getVictimIndex(victim, data.report.victims) + 1}
                  </td>
                  <td className="tableDamages ps-2">
                    {category.id}{" "}
                    {typeof victim.health !== "undefined"
                      ? i18n.t(`victim.health.${victim.health}`)
                      : "État de l'animal non défini"}
                  </td>
                  <td className="tableDamages ps-2">
                    {victim.num || "Non retrouvé"}
                  </td>
                  <td className="tableDamages ps-2">
                    {victim.isApproved
                      ? "Victime retenue"
                      : "Victime non retenue"}
                  </td>
                  <td className="tableDamages ps-2">
                    {getVictimTotalAmount(victim)
                      ? currencyFormatter.format(getVictimTotalAmount(victim))
                      : "0"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <br />
        <p style={{ textAlign: "justify" }}>
          L'éleveur dispose d'un délai de 15 jours francs à compter de la
          présente décision pour formuler ses observations.
          <br />
          <br />
        </p>
        <p>
          Article 2 : Versement
          <br />
        </p>
        <p style={{ textAlign: "justify" }}>
          Au terme de ce délai de 15 jours, si l'éleveur ne s'est pas manifesté,
          la présente décision d'indemnisation sera transmise à la Direction
          Régionale de l'ASP Corse-PACA, en charge d'instruire la demande de
          paiement.
          <br />
          Le montant de l'indemnisation décidé et le paiement seront effectués
          conformément à la procédure de contrôle administratif mise en place
          par Convention entre le MTES et l'ASP. Dans ce cadre, des pièces
          complémentaires peuvent être demandées à l'éleveur et le montant de
          l'indemnisation peut être révisé.
          <br />
          L'indemnisation accordée par le MTES est versée par l'ASP, 2 rue du
          Maupas, 87040 LIMOGES Cedex1, représentée par son Agent Comptable.
          <br />
        </p>
        <p>
          <br />
          Article 3 : Reversement
        </p>
        <p style={{ textAlign: "justify" }}>
          En cas de constat d'indemnisation indue, le préfet peut exiger le
          reversement total ou partiel des sommes versées. Le recouvrement est
          alors mis en œuvre par l'ASP en application de cette décision de
          déchéance. <br />
        </p>
        <p>
          <br />
          Article 4 : Litiges
        </p>
        <p style={{ textAlign: "justify" }}>
          La présente décision peut faire l'objet d'un recours gracieux auprès
          du Préfet ou d'un recours hiérarchique auprès du Ministre de la
          Transition Ecologique et Solidaire ou d'un recours contentieux devant
          le tribunal administratif, dans un délai de deux mois à compter de sa
          notification.
          <br />
        </p>
        <br />
      </div>

      {/* <br />
              <br />
              <br />
              <br /> */}

      <div className="signatureStyle" style={{ textAlign: "right" }}>
        Fait à {department.metaData?.ddaf.city}, le{" "}
        {printDateMediumFormat(new Date())}
        <br />
        <br />
        {t(
          `department.metaData.prefect.for_${
            department.metaData?.prefect?.gender || "male"
          }`,
          {
            departmentLabel: department.label,
          }
        )}
        <br />
        {department.metaData?.ddaf.title}
        <br />
        <br />
        <br />
        <br />
        {department.metaData?.ddaf.fullname}
        <br />
        <br />
      </div>
    </div>
  );
}
