import { Breadcrumbs, Fab, Link, Tab, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import { IBeneficiary, IReport, IReportBeneficiaryDto } from "../interfaces";
import BeneficiaryForm from "../components/form/BeneficiaryForm";
import AppAutocomplete from "../components/form-input/AppAutocomplete";
import { getBeneficiaryLabel } from "../lib/reportUtils";
import useApi from "../lib/useApi";
import ReportBeneficiaryForm from "../components/form/ReportBeneficiaryForm";
import AppTabs from "../components/shared/AppTabs";

export default function ReportBeneficiaryCreationPage() {
  const { prvApi, handleError } = useApi();
  const selectFormApi = useForm<{
    beneficiary: IBeneficiary;
  }>();
  const createFormApi = useForm<IBeneficiary>();
  const [curTabName, setCurTabName] = useState<"select" | "create">("select");
  const [report, setReport] = useState<IReport>(null);
  const params = new URLSearchParams(useLocation().search);
  const reportId = params.get("report");
  const history = useHistory();

  useEffect(() => {
    if (!reportId) {
      return;
    }
    (async () => {
      const { data } = await prvApi.get(`/reports/${reportId}`);
      setReport(data);
    })();
  }, [reportId]);

  useEffect(() => {
    if (curTabName === "create") {
      //createFormApi.reset({});
    }
  }, [curTabName]);

  const onAddClick = async () => {
    if (curTabName === "create") {
      createFormApi.handleSubmit(createBeneficiary)();
      return;
    }

    createFormApi.clearErrors();
    createFormApi.handleSubmit(linkBeneficiary)();
  };

  const createBeneficiary = async () => {
    try {
      await prvApi.post("/report_beneficiaries", {
        ...createFormApi.getValues(),
        report: report["@id"],
      });
      onSuccess();
    } catch (error) {
      handleError(error, createFormApi);
    }
  };

  const linkBeneficiary = async () => {
    selectFormApi.clearErrors();
    const { beneficiary } = selectFormApi.getValues();
    if (!beneficiary) {
      return (selectFormApi.setError as any)("beneficiary", {
        message: "Selectionner un bénéficiaire.",
      });
    }

    const reportBeneficiary: IReportBeneficiaryDto = {
      ...createFormApi.getValues(),
      report: report["@id"],
      beneficiary: beneficiary["@id"],
    };

    try {
      await prvApi.post("/report_beneficiaries", reportBeneficiary);
      onSuccess();
    } catch (error) {
      handleError(error, createFormApi);
    }

    /* const resp = await prvApi.post(
      "/report_beneficiaries",
      reportBeneficiary
    );
    if (resp.status === 201) {
      //TODO
    } */
  };

  const onSuccess = () => {
    history.push(`/reports/${report.id}/beneficiaries`);
  };

  if (!report) {
    return <div />;
  }

  return (
    <div className="container pb-5">
      <Breadcrumbs separator="›" className="my-4">
        <Link component={RouterLink} to={`/reports/${report.id}`}>
          Dossier {report.ref}
        </Link>
        <Link component={RouterLink} to={`/reports/${report.id}/beneficiaries`}>
          Bénéficiaires
        </Link>
        <Typography>Ajout d'un bénéficiaire</Typography>
      </Breadcrumbs>
      <ReportBeneficiaryForm formApi={createFormApi} />
      <AppTabs
        position="relative"
        tabsProps={{
          variant: "fullWidth",
          value: curTabName,
          onChange: ((e, value) => setCurTabName(value)) as any,
        }}
      >
        <Tab label="Sélectionner un bénéficiaire" value="select" />
        <Tab label="Créer un bénéficiaire" value="create" />
      </AppTabs>
      <div className="p-3 pt-4">
        {curTabName === "create" ? (
          <form>
            <BeneficiaryForm
              formApi={createFormApi}
              fieldPrefix="beneficiary"
            />
          </form>
        ) : (
          <div>
            <AppAutocomplete
              name="beneficiary"
              formApi={selectFormApi}
              getOptions={async (input) => {
                const { data } = await prvApi.get("/beneficiaries", {
                  params: {
                    orstartwith_quick_search: input,
                  },
                });
                return data.map((option) => {
                  return {
                    ...option,
                    label: getBeneficiaryLabel(option),
                  };
                });
              }}
              renderOption={(option) => {
                return (
                  <div className="row w-100">
                    <div className="col-2">{option.id}</div>
                    <div className="col-4">{option.label}</div>
                    <div className="col-3">{option.pacage}</div>
                    <div className="col-3">{option.siret}</div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
      <Fab className="fab-bottom-right" color="primary" onClick={onAddClick}>
        <SaveIcon />
      </Fab>
    </div>
  );
}
