import { FC } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { IBeneficiary, IReportBeneficiary } from "../../interfaces";
import AppCheckbox from "../form-input/AppCheckbox";
import AppSelectMultiBenChildren from "../form-input/AppSelectMultiBenChildren";
import AppSelectMultiBenParents from "../form-input/AppSelectMultiBenParents";
import AppTextField from "../form-input/AppTextField";
import VisitValues from "../visit-values/VisitValues";
const BeneficiaryForm: FC<{
  formApi: UseFormReturn;
  fieldPrefix?: string;
  reportBeneficiary?: IReportBeneficiary;
  isDisabled?: boolean;
  initData?: IBeneficiary;
}> = (props) => {
  const visits = props.reportBeneficiary?.report["visits"];
  const visitValuesSources = props.reportBeneficiary?.visitBeneficiaries;
  const visitValuesDatePath: string = "visit.visitedAt";
  const hasVisitValues: boolean = Boolean(visitValuesSources?.length);

  const fieldArrayBenParents = useFieldArray({
    control: props.formApi.control,
    name: "parents",
  });

  const fieldArrayBenChildren = useFieldArray({
    control: props.formApi.control,
    name: "children",
  });

  const getInputName = (name): string => {
    return props.fieldPrefix ? `${props.fieldPrefix}.${name}` : name;
  };

  const isDisabled: boolean = props.isDisabled;
  const isInputDisabled: boolean = hasVisitValues || isDisabled;

  const formData = props.formApi.getValues();
  const watchIsCorpo = props.formApi.watch("isCorporate");

  return (
    <>
      <div className="row">
        <div className="form-item col-6 mt-0">
          <AppCheckbox
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("isCorporate")}
            label="Groupement"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Groupement"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="isCorporate"
              name={getInputName("isCorporate")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="checkbox"
            />
          )}
        </div>
        <div className="form-item col-6 mt-0">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("corporateName")}
            label="Raison sociale"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Raison sociale"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="corporateName"
              name={getInputName("corporateName")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("lastName")}
            label="Nom"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Nom"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="lastName"
              name={getInputName("lastName")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("firstName")}
            label="Prénom"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Prénom"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="firstName"
              name={getInputName("firstName")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("siret")}
            label="n° Siret"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="n° Siret"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="siret"
              name={getInputName("siret")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("pacage")}
            label="n° Pacage"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="n° Pacage"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="pacage"
              name={getInputName("pacage")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("protectionContract")}
            label="n° de contrat de protection"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="n° contrat de protection"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="protectionContract"
              name={getInputName("protectionContract")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("email")}
            label="Email"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Email"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="email"
              name={getInputName("email")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppCheckbox
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("isAgreeForEmail")}
            label="Consent à échanger par email"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Consent à échanger par email"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="isAgreeForEmail"
              name={getInputName("isAgreeForEmail")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="checkbox"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("phone")}
            label="Téléphone"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Téléphone"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="phone"
              name={getInputName("phone")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("address")}
            label="Adresse"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Adresse"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="address"
              name={getInputName("address")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("zipCode")}
            label="Code postal"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Code postal"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="zipCode"
              name={getInputName("zipCode")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("city")}
            label="Ville"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Ville"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="city"
              name={getInputName("city")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-6">
          <AppTextField
            disabled={isInputDisabled}
            formApi={props.formApi}
            name={getInputName("hamlet")}
            label="Lieu-dit"
          />
          {hasVisitValues && (
            <VisitValues
              disabled={isDisabled}
              label="Lieu-dit"
              visits={visits}
              sources={visitValuesSources}
              visitPath="visit"
              path="hamlet"
              name={getInputName("hamlet")}
              datePath={visitValuesDatePath}
              formApi={props.formApi}
              editor="textfield"
            />
          )}
        </div>
        <div className="form-item col-12 mt-2">
          {(watchIsCorpo || Boolean(formData.children?.length)) &&
            !Boolean(formData.parents?.length) && (
              <AppSelectMultiBenChildren
                formApi={props.formApi}
                name={"children"}
                fieldArray={fieldArrayBenChildren}
              />
            )}
          {(!watchIsCorpo || Boolean(formData.parents?.length)) &&
            !Boolean(formData.children?.length) && (
              <AppSelectMultiBenParents
                formApi={props.formApi}
                name={"parents"}
                fieldArray={fieldArrayBenParents}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default BeneficiaryForm;
