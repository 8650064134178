import { Fragment, ReactNode } from "react";
import getConfig from "../config";
import isPlainObject from "lodash/isPlainObject";

export function printDate(
  input: string | Date,
  defaultValue: string = ""
): string {
  if (!input) {
    return defaultValue;
  }
  try {
    if (typeof input === "string") {
      input = new Date(input);
      if (isNaN(input as any)) {
        return defaultValue;
      }
    }
    return input.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  } catch (error) {}
  return defaultValue;
}

export function printDateTime(
  input: string | Date,
  defaultValue: string = ""
): string {
  if (!input) {
    return defaultValue;
  }
  try {
    if (typeof input === "string") {
      input = new Date(input);
      if (isNaN(input as any)) {
        return defaultValue;
      }
    }
    return input.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  } catch (error) {}
  return defaultValue;
}

export function printDateMediumFormat(
  input: string | Date,
  defaultValue: string = ""
): string {
  if (!input) {
    return defaultValue;
  }
  try {
    if (typeof input === "string") {
      input = new Date(input);
      if (isNaN(input as any)) {
        return defaultValue;
      }
    }
    return input.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } catch (error) {}
  return defaultValue;
}

export function printTime(
  input: string | Date,
  defaultValue: string = ""
): string {
  if (!input) {
    return defaultValue;
  }

  try {
    input = new Date(input);
    if (isNaN(input as any)) {
      return defaultValue;
    }
    return input.toLocaleDateString("fr-FR", {
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  } catch (error) {}
  return defaultValue;
}

export function nl2br(str): string {
  try {
    return str.split("\n").map((item, key) => {
      return (
        <Fragment key={key}>
          {item}
          <br />
        </Fragment>
      );
    });
  } catch (error) {}
  return "";
}

export function idToUri(name: string, value: string): string {
  const conf = getConfig();
  if (value.indexOf(`${conf.resourcesBaseUri}/${name}`) === 0) {
    return value;
  }
  return `${conf.resourcesBaseUri}/${name}/${value}`;
}

export function uriToId(value: string): string {
  return value.split("/").pop();
}

export function insertStr(str, index, stringToAdd): string {
  return (
    str.substring(0, index) + stringToAdd + str.substring(index, str.length)
  );
}

export function objToId(obj: any, propName: string): any {
  return !isPlainObject(obj) ? obj : obj[propName];
}

export function getQuestionLabel(label: string): ReactNode {
  if (label?.indexOf(". ") < 0) {
    return label;
  }
  const split: string[] = label?.split(". ");
  const num: string = split?.[0];
  const hasNum: boolean = Number(num) > 0;
  if (hasNum) {
    label = label.replace(`${num}. `, "");
  }

  return (
    <span>
      {hasNum && <b>{num}. </b>}
      {label}
    </span>
  );
}

// Create currency formatter.
export const currencyFormatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
});
