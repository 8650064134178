import { FC } from "react";
import {
  AppBar,
  AppBarProps,
  createMuiTheme,
  Tabs,
  TabsProps,
  ThemeProvider,
} from "@material-ui/core";

const tabTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#F1F1F1",
      contrastText: "#EE7F01",
    },
    secondary: {
      main: "#FF6600",
    },
  },
});

const AppTabs: FC<
  {
    tabsProps: TabsProps;
  } & AppBarProps
> = (props) => {
  const appBarProps = { ...props };
  const tabsProps = props.tabsProps;
  delete appBarProps.tabsProps;

  return (
    <ThemeProvider theme={tabTheme}>
      <AppBar position="relative" {...appBarProps}>
        <Tabs textColor="secondary" {...tabsProps}>
          {props.children}
        </Tabs>
      </AppBar>
    </ThemeProvider>
  );
};

export default AppTabs;
