import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { FC, ReactNode, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import get from "lodash/get";
import { Autocomplete } from "@material-ui/lab";
import { useDebounce } from "use-debounce/lib";
import FormItemError from "./FormItemError";

type AppAutocompleteProps = {
  formApi;
  name: string;
  getOptions: (input: string) => Promise<any[]>;
  optionLabel?: string;
  optionValue?: string;
  renderOption?: (option: any) => ReactNode;
  freeSolo?: boolean;
  onChange?: (value) => void;
} & TextFieldProps;

const NO_OPTIONS_TEXT: string = "Saisir les 1ères lettres";

const AppAutocomplete: FC<AppAutocompleteProps> = (props) => {
  const { control } = props.formApi;
  let InputLabelProps = props.InputLabelProps || {};
  InputLabelProps = { ...InputLabelProps };
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 200);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState(NO_OPTIONS_TEXT);

  useEffect(() => {
    setNoOptionsText(inputValue.length ? "..." : NO_OPTIONS_TEXT);
  }, [inputValue]);

  useEffect(() => {
    let active = true;
    (async () => {
      setOptions([]);
      if (!debouncedInputValue) {
        return;
      }
      setIsLoading(true);
      const response = await props.getOptions(debouncedInputValue);
      setNoOptionsText("Pas de résultats");
      setIsLoading(false);
      if (active) {
        setOptions(response);
      }
    })();

    return () => {
      active = false;
    };
  }, [debouncedInputValue]);

  const getOptionVal = (option) => {
    if (!option) {
      return null;
    }
    let optionVal = get(option, props.optionValue || "@id");
    return typeof optionVal === "undefined" ? option : optionVal;
  };

  const getLabel = (option): string => {
    return !option
      ? ""
      : get(option, props.optionLabel || "label") ||
          (typeof option === "string" ? option : "");
  };

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        if (typeof value === "undefined" || value === null) {
          value = "";
        }
        return (
          <>
            <Autocomplete
              value={value}
              noOptionsText={noOptionsText}
              autoSelect={!Boolean(props.freeSolo)}
              autoComplete={true}
              freeSolo={props.freeSolo}
              getOptionSelected={(option: any, value) => {
                return getOptionVal(option) === getOptionVal(value);
              }}
              getOptionLabel={(option) => {
                return getLabel(option);
              }}
              filterOptions={(x) => x}
              disabled={props.disabled}
              onChange={(e, value) => {
                const val = !props.freeSolo ? value : getLabel(value);
                onChange(val);
                props.onChange?.(val);
              }}
              options={options}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                if (event && props.freeSolo) {
                  onChange(newInputValue);
                  props.onChange?.(newInputValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={props.disabled}
                  label={props.label}
                  variant={props.variant || "outlined"}
                  InputLabelProps={InputLabelProps}
                  InputProps={{
                    ...params.InputProps,
                    className: "mb-2",
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
              renderOption={props.renderOption}
            />
            <FormItemError name={props.name} formApi={props.formApi} />
          </>
        );
      }}
    />
  );
};
export default AppAutocomplete;
